export enum StreamStatus {
  CREATED = 'created',
  STARTED = 'started',
  STOPPED = 'stopped',
  DELETED = 'deleted',
}

export class Stream {
  id: string;

  eventId: string;

  manifest: string;

  rtmp: string;

  status: StreamStatus;

  conferenceId?: string;

  generalAssemblyId?: string;
}

export interface StreamCreateForm {
  eventId: string;
  conferenceId?: string;
  generalAssemblyId?: string;
}
