<template>
  <v-card class="content">
    <v-row>
      <v-col cols="5">
        <div dark class="customize">
          <div class="customize__header">
            <div class="customize-header__progress">
              <div class="progress__header">
                <div class="progress__title">{{ $t('kioskBuilder.progress') }}</div>
                <div class="progress__percent" :style="{ color: `${color}` }">{{ progress }}%</div>
                <div class="progress__logo">
                  <ProgressSVG :progress="progress" />
                </div>
              </div>
              <div
                class="progress__warning"
                v-html="
                  $t('kioskBuilder.dateWarning', {
                    date: $d(Date.parse(submitKioskDeadline), 'longDate'),
                    time: $d(Date.parse(submitKioskDeadline), 'shortTime'),
                  })
                "
              />
            </div>
            <div class="customize-header__tasks-title">{{ $t('kioskBuilder.tasks') }}</div>
          </div>

          <div class="customize__content">
            <v-stepper
              class="elevation-0"
              v-model="stepperStep"
              @change="changeStep"
              vertical
              rounded
              flat
            >
              <v-card>
                <v-stepper-step
                  :edit-icon="getIcon(hasKioskType, steps[0].isEditing)"
                  :class="{
                    'step--is-valid': hasKioskType,
                    'step--is-not-valid': !hasKioskType && !steps[0].isEditing,
                  }"
                  step="0"
                  editable
                  complete
                  @click="clickStep('0')"
                >
                  <div class="content__step">
                    <div>
                      <div class="step__header-title">
                        {{ $t('kioskBuilder.kioskChoice.title') }}
                      </div>
                      <div class="step__header-more">
                        {{ $t('kioskBuilder.kioskChoice.detail') }}
                      </div>
                    </div>

                    <div class="arrow">
                      <v-icon v-if="isClose('0')">mdi-chevron-up</v-icon>
                      <v-icon v-else>mdi-chevron-down</v-icon>
                    </div>
                  </div>
                </v-stepper-step>

                <v-stepper-content step="0">
                  <kiosk-type-step
                    class="stepper__content"
                    :current-kiosk-type="exhibitorForm.kioskOptions.kioskType"
                    :is-submitted="isSubmitted"
                    @set-kiosk-type="setKioskType"
                    :is-unity-loading="isUnityLoading"
                  />

                  <div class="stepper__action mb-4">
                    <v-btn
                      color="primary"
                      :disabled="isUnityLoadingOrHasChanged"
                      v-if="!isSubmitted"
                      @click="saveStep"
                    >
                      {{ $t('globals.confirm') }}
                    </v-btn>
                  </div>
                </v-stepper-content>
              </v-card>

              <v-card>
                <v-stepper-step
                  :edit-icon="getIcon(areColorsSet, steps[1].isEditing)"
                  :class="{
                    'step--is-valid': areColorsSet,
                    'step--is-not-valid': !areColorsSet && !steps[1].isEditing,
                  }"
                  step="1"
                  editable
                  complete
                  @click="clickStep('1')"
                >
                  <div class="content__step">
                    <div>
                      <div class="step__header-title">
                        {{ $t('kioskBuilder.colorChoice.title') }}
                      </div>
                      <div class="step__header-more">
                        {{ $t('kioskBuilder.colorChoice.detail') }}
                      </div>
                    </div>

                    <div class="arrow">
                      <v-icon v-if="isClose('1')">mdi-chevron-up</v-icon>
                      <v-icon v-else>mdi-chevron-down</v-icon>
                    </div>
                  </div>
                </v-stepper-step>

                <v-stepper-content step="1">
                  <kiosk-colors-step
                    class="stepper__content"
                    :kiosk-options="exhibitorForm.kioskOptions"
                    @set-first-color="onFirstColorSelect"
                    @set-second-color="onSecondColorSelect"
                    @set-third-color="onThirdColorSelect"
                    :is-unity-loading="isUnityLoading"
                  />

                  <div class="stepper__action mb-4">
                    <v-btn
                      color="primary"
                      :disabled="isUnityLoadingOrHasChanged"
                      v-if="!isSubmitted"
                      @click="saveStep"
                    >
                      {{ $t('globals.confirm') }}
                    </v-btn>
                  </div>
                </v-stepper-content>
              </v-card>

              <v-card>
                <v-stepper-step
                  :edit-icon="getIcon(arePanelsSet, steps[2].isEditing)"
                  :class="{
                    'step--is-valid': arePanelsSet,
                    'step--is-not-valid': !arePanelsSet && !steps[2].isEditing,
                  }"
                  step="2"
                  editable
                  complete
                  @click="clickStep('2')"
                >
                  <div class="content__step">
                    <div>
                      <div class="step__header-title">
                        {{ $t('kioskBuilder.panelChoice.title') }}
                      </div>
                      <div class="step__header-more">
                        {{ $t('kioskBuilder.panelChoice.detail') }}
                      </div>
                    </div>

                    <div class="arrow">
                      <v-icon v-if="isClose('2')">mdi-chevron-up</v-icon>
                      <v-icon v-else>mdi-chevron-down</v-icon>
                    </div>
                  </div>
                </v-stepper-step>
                <v-stepper-content step="2">
                  <kiosk-panels-step
                    class="stepper__content"
                    :kiosk-options="exhibitorForm.kioskOptions"
                    @upload-picture="uploadPicture"
                    :is-unity-loading="isUnityLoading"
                  />

                  <div class="stepper__action mb-4">
                    <v-btn
                      color="primary"
                      :disabled="isUnityLoadingOrHasChanged"
                      v-if="!isSubmitted"
                      @click="saveStep"
                    >
                      {{ $t('globals.confirm') }}
                    </v-btn>
                  </div>
                </v-stepper-content>
              </v-card>

              <v-card>
                <v-stepper-step
                  :edit-icon="getIcon(areMoreChoicesSet, steps[3].isEditing)"
                  :class="{
                    'step--is-valid': areMoreChoicesSet,
                    'step--is-not-valid': !areMoreChoicesSet && !steps[3].isEditing,
                  }"
                  step="3"
                  editable
                  complete
                  @click="clickStep('3')"
                >
                  <div class="content__step">
                    <div>
                      <div class="step__header-title">
                        {{ $t('kioskBuilder.moreChoice.title') }}
                      </div>
                      <div class="step__header-more">
                        {{ $t('kioskBuilder.moreChoice.title') }}
                      </div>
                    </div>

                    <div class="arrow">
                      <v-icon v-if="isClose('3')">mdi-chevron-up</v-icon>
                      <v-icon v-else>mdi-chevron-down</v-icon>
                    </div>
                  </div>
                </v-stepper-step>
                <v-stepper-content step="3">
                  <kiosk-more-step
                    class="stepper__content"
                    :kiosk-options="exhibitorForm.kioskOptions"
                    @on-surface-select="onSurfaceSelect"
                    @on-furniture-select="onFurnitureSelect"
                    :is-unity-loading="isUnityLoading"
                  />

                  <div class="stepper__action mb-4">
                    <v-btn
                      color="primary"
                      :disabled="isUnityLoadingOrHasChanged || isSending"
                      :loading="isSending"
                      @click="saveStep"
                    >
                      {{ $t('globals.confirm') }}
                    </v-btn>
                  </div>
                </v-stepper-content>
              </v-card>
            </v-stepper>
          </div>

          <v-card-actions class="content__actions">
            <v-btn color="error" outlined @click="initForm">
              {{ $t('globals.init') }}
            </v-btn>

            <v-btn
              color="primary"
              outlined
              @click="saveScreenshot"
              :loading="exhibitorIsSaving || exhibitorIsSavingScreenshot"
            >
              {{ $t('globals.save') }}
            </v-btn>

            <v-btn
              color="primary"
              @click="submit"
              :disabled="!canSubmit"
              :loading="exhibitorIsSaving"
            >
              {{ $t('globals.submit') }}
            </v-btn>
          </v-card-actions>
        </div>
      </v-col>

      <v-col cols="7">
        <div class="webgl-content">
          <div v-show="!isUnityLoading" id="unity-container">
            <canvas ref="unityCanvas" id="unity-canvas" />
          </div>
          <loading v-show="isUnityLoading" />
        </div>
      </v-col>
    </v-row>

    <generic-dialog
      :dialog-model="isWarningDialogOpen"
      icon="mdi-alert-circle-outline"
      :content="
        $t('kioskBuilder.dateWarning', {
          date: $d(Date.parse(submitKioskDeadline), 'longDate'),
          time: $d(Date.parse(submitKioskDeadline), 'shortTime'),
        })
      "
      :validate-action-label="$t('globals.continue')"
      :secondary-action-label="$t('globals.back')"
      @validate-action="confirmClose"
      @secondary-action="back"
    />

    <generic-dialog
      :dialog-model="submitDialog"
      :title="$t('kioskBuilder.submitWarning.title')"
      :content="$t('kioskBuilder.submitWarning.content')"
      :validate-action-label="$t('globals.confirm')"
      :secondary-action-label="$t('globals.back')"
      @validate-action="confirmSubmit"
      @secondary-action="submitDialog = false"
    />
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import cloneDeep from 'lodash.clonedeep';
import { DateTime } from 'luxon';

import { Exhibitor } from '@/models';

import { unityEventListenersMixin } from '@/mixins/unity.eventListeners.mixin';

import KioskUtil from '@/helpers/kiosk/kiosk.helper';
import UnityUtil from '@/helpers/unity/unity.helper';
import DataUtil from '@/helpers/data/data.helper';
import StringUtils from '@/helpers/string/string.helper';
import ProgressUtil from '@/helpers/progress/progress.helper';

import Loading from '@/components/loading/Loading.vue';
import KioskTypeStep from '@/components/kiosk-builder/kiosk-type-step/KioskTypeStep.vue';
import KioskColorsStep from '@/components/kiosk-builder/kiosk-colors-step/KioskColorsStep.vue';
import KioskPanelsStep from '@/components/kiosk-builder/kiosk-panels-step/KioskPanelsStep.vue';
import KioskMoreStep from '@/components/kiosk-builder/kiosk-more-step/KioskMoreStep.vue';
import GenericDialog from '@/components/ui/dialog/GenericDialog.vue';
import ProgressSVG from '@/components/progressSvg/ProgressSVG.vue';

import {
  SHARED_CONFIG_MODULE,
  GET_CONFIG,
  CLEAR_CONFIG,
} from '@/stores/shared/actions/config/config.actions';
import {
  SHARED_FILE_MODULE,
  UPLOAD_FILE,
} from '@/stores/shared/actions/file-upload/file-upload.actions';
import {
  CLEAR_EXHIBITOR,
  GET_EXHIBITOR,
} from '@/stores/agnostic/actions/exhibitor/exhibitor.actions';
import {
  ADMIN_EXHIBITOR_MODULE,
  SAVE_EXHIBITOR,
} from '@/stores/umanize-admin/actions/exhibitor/exhibitor.actions';
import { ADMIN_EVENT_MODULE } from '@/stores/umanize-admin/actions/event/admin-event.actions';
import { APP_EXHIBITOR_MODULE } from '@/stores/umanize-app/actions/exhibitor/app-exhibitor.actions';
import { APP_EVENT_MODULE } from '@/stores/umanize-app/actions/event/app-event.actions';

export default {
  name: 'KioskStandConfiguration',
  components: {
    ProgressSVG,
    GenericDialog,
    KioskMoreStep,
    KioskPanelsStep,
    KioskColorsStep,
    KioskTypeStep,
    Loading,
  },
  mixins: [unityEventListenersMixin],
  data: () => ({
    warningDialog: true,
    submitDialog: false,
    isUnityLoading: true,
    unityInstance: undefined,
    unityKioskBuilderConfigName: 'UNITY_KIOSK_BUILDER_URL',
    configs: null,
    loaderUrl: null,
    configUrl: null,
    colors: {
      0: 'red',
      17: 'orangered',
      33: 'darkorange',
      50: 'orange',
      67: 'yellow',
      83: 'greenyellow',
      100: 'green',
    },
    defaultExhibitor: new Exhibitor(),
    exhibitorForm: new Exhibitor(),
    stepperStep: -1,
    lastStep: -1,
    steps: [
      {
        isValid: false,
        icon: 'mdi-close',
        isEditing: false,
      },
      {
        isValid: false,
        icon: 'mdi-close',
        isEditing: false,
      },
      {
        isValid: false,
        icon: 'mdi-close',
        isEditing: false,
      },
      {
        isValid: false,
        icon: 'mdi-close',
      },
    ],
  }),
  computed: {
    ...mapGetters(APP_EXHIBITOR_MODULE, ['exhibitorSelf']),
    ...mapGetters(
      window.location.href.includes('/admin') ? ADMIN_EXHIBITOR_MODULE : APP_EXHIBITOR_MODULE,
      ['exhibitor'],
    ),
    ...mapGetters(window.location.href.includes('/admin') ? ADMIN_EVENT_MODULE : APP_EVENT_MODULE, [
      'event',
    ]),
    ...mapGetters(ADMIN_EXHIBITOR_MODULE, ['exhibitorIsSaving', 'exhibitorIsSavingScreenshot']),
    ...mapGetters(SHARED_FILE_MODULE, ['file', 'isSending']),
    ...mapGetters(SHARED_CONFIG_MODULE, ['config']),
    hasKioskType() {
      return KioskUtil.isKioskTypeSet(this.exhibitor);
    },
    areColorsSet() {
      return KioskUtil.areColorsSet(this.exhibitor);
    },
    areMoreChoicesSet() {
      return KioskUtil.areMoreChoicesSet(this.exhibitor);
    },
    arePanelsSet() {
      return KioskUtil.arePanelsSet(this.exhibitor);
    },
    areCoordinatesSet() {
      return KioskUtil.isAtLeastOneContactSet(this.exhibitor);
    },
    hasChanged() {
      return KioskUtil.hasChanged(this.exhibitor, this.exhibitorForm);
    },
    progress() {
      return +(this.exhibitor && KioskUtil.getProgress(this.exhibitor)) || 0;
    },
    percentColor() {
      return `percent__color-${this.progress}`;
    },
    color() {
      return ProgressUtil.getProgressColor(this.progress, 240);
    },
    submitKioskDeadline() {
      return this.event?.options?.eventKioskOptions?.submitKioskDeadline || this.event.startDate;
    },
    isWarningDialogOpen() {
      return this.warningDialog && !localStorage.getItem('kioskWarningDialog');
    },
    canSubmit() {
      return KioskUtil.canSubmit(this.exhibitorForm) && !this.isSubmitted;
    },
    isSubmitted() {
      return this.exhibitorForm?.kioskOptions?.isSubmitted;
    },
    isUnityLoadingOrHasChanged() {
      return !this.hasChanged || this.isUnityLoading;
    },
  },
  methods: {
    ...mapActions(
      window.location.href.includes('/admin') ? ADMIN_EXHIBITOR_MODULE : APP_EXHIBITOR_MODULE,
      [GET_EXHIBITOR],
    ),
    ...mapActions(APP_EXHIBITOR_MODULE, [CLEAR_EXHIBITOR]),
    ...mapActions(ADMIN_EXHIBITOR_MODULE, [SAVE_EXHIBITOR]),
    ...mapActions(SHARED_FILE_MODULE, [UPLOAD_FILE]),
    ...mapActions(SHARED_CONFIG_MODULE, [GET_CONFIG, CLEAR_CONFIG]),
    unityProgress(unityInstance, progress) {
      UnityUtil.onUnityProgress(unityInstance, progress, this.initUnity);
    },
    loadUnity() {
      UnityUtil.setUnityScript(this.loaderUrl, this.createInstance);
    },
    createInstance() {
      if (this.configs?.codeUrl) {
        this.configs = UnityUtil.formattedConfig(this.configUrl, this.configs);

        // eslint-disable-next-line no-undef
        createUnityInstance(this.$refs.unityCanvas, this.configs)
          .then((unityInstance) => {
            this.unityInstance = unityInstance;
            UnityUtil.onUnitySuccess(unityInstance, this.initUnity);
          })
          .catch((error) => console.error(error));
      } else {
        // eslint-disable-next-line no-undef
        this.unityInstance = UnityLoader.instantiate('unity-container', this.configUrl, {
          onProgress: this.unityProgress,
        });
      }
    },
    onFurnitureSelect(furnitureType) {
      if (furnitureType) {
        this.exhibitorForm.kioskOptions = {
          ...this.exhibitorForm.kioskOptions,
          furnitureColor: furnitureType,
        };
        this.unityInstance.SendMessage('KioskBuilder', 'FurnitureColor', furnitureType);
      }
    },
    onSurfaceSelect(surfaceType) {
      if (surfaceType) {
        this.exhibitorForm.kioskOptions = {
          ...this.exhibitorForm.kioskOptions,
          surfaceColor: surfaceType,
        };
        this.unityInstance.SendMessage('KioskBuilder', 'SurfaceColor', surfaceType);
      }
    },
    onFirstColorSelect(color) {
      if (color) {
        this.exhibitorForm.kioskOptions = {
          ...this.exhibitorForm.kioskOptions,
          zoneFirstColor: color,
        };
        this?.unityInstance?.SendMessage('KioskBuilder', 'WallColors1', color);
      }
    },
    onSecondColorSelect(color) {
      if (color) {
        this.exhibitorForm.kioskOptions = {
          ...this.exhibitorForm.kioskOptions,
          zoneSecondColor: color,
        };
        this?.unityInstance?.SendMessage('KioskBuilder', 'WallColors2', color);
      }
    },
    onThirdColorSelect(color) {
      if (color) {
        this.exhibitorForm.kioskOptions = {
          ...this.exhibitorForm.kioskOptions,
          zoneThirdColor: color,
        };
        this?.unityInstance?.SendMessage('KioskBuilder', 'WallColors3', color);
      }
    },
    getIcon(isValid, isEditing) {
      return KioskUtil.getIcon(isValid, isEditing);
    },
    isCurrentKioskType(kiosk) {
      return this.exhibitorForm.kioskOptions.kioskType === kiosk;
    },
    setKioskType(kiosk) {
      this.exhibitorForm = {
        ...this.exhibitorForm,
        kioskOptions: {
          ...this.exhibitorForm.kioskOptions,
          kioskType: kiosk,
        },
      };
      this.initUnity(this.unityInstance);
    },
    isClose(step) {
      return this.lastStep === step && this.stepperStep === step;
    },
    clickStep(step) {
      // For the timing with the v-model that triggers the changeStep
      setTimeout(() => {
        if (this.lastStep === this.stepperStep) {
          this.steps[step].isEditing = !this.steps[step].isEditing;
          this.stepperStep = this.stepperStep === -1 ? step : -1;
          this.lastStep = -1;
        } else {
          this.lastStep = step;
        }
      });
    },
    changeStep() {
      this.steps = this.steps.map((step) => ({
        ...step,
        isEditing: false,
        icon: step.isValid ? 'mdi-check' : 'mdi-close',
      }));

      if (this.stepperStep >= 0 && !this.steps[this.stepperStep].isValid) {
        this.steps[this.stepperStep].icon = 'mdi-circle-outline';
        this.steps[this.stepperStep].isEditing = true;
      }
    },
    saveStep() {
      switch (this.stepperStep) {
        case '0':
          this.stepperStep = '1';
          break;
        case '1':
          this.stepperStep = '2';
          break;
        case '2':
          this.stepperStep = '3';
          break;
        case '3':
          this.stepperStep = '4';
          break;
        default:
          this.stepperStep = -1;
      }

      this.lastStep = this.stepperStep;

      this.changeStep();

      this.save();
    },
    async uploadPicture({ picture, pictureDialogType }) {
      await this[UPLOAD_FILE](picture[0]);
      this.exhibitorForm.kioskOptions[pictureDialogType] = this.file.url;

      if (this.file.url) {
        KioskUtil.sendToUnity(
          this.unityInstance,
          StringUtils.capitalizeString(pictureDialogType),
          this.file.url,
        );
      }
    },
    updateDocuments(documents) {
      this.exhibitorForm = {
        ...this.exhibitorForm,
        documents: [...documents],
      };
    },
    goBackToKiosk() {
      this.$router.back();
    },
    async save() {
      const { eventId } = this.$route.params;

      await this[SAVE_EXHIBITOR]({
        eventId,
        exhibitor: this.exhibitorForm,
        isSavingScreenshot: true,
      });
      await this.initializeExhibitor();
      this.initForm();
    },
    async initializeExhibitor() {
      const { eventId, exhibitorId } = this.$route.params;

      await this[GET_EXHIBITOR]({
        eventId,
        exhibitorId: exhibitorId || this.exhibitorSelf?.exhibitorId,
      });
    },
    initForm() {
      this.exhibitorForm = cloneDeep({ ...this.defaultExhibitor, ...this.exhibitor });
    },
    initUnity(unityInstance) {
      KioskUtil.initUnityKiosk(unityInstance, this.exhibitorForm);

      this.isUnityLoading = false;
    },
    async saveScreenshot() {
      await this.save();
      this.unityInstance.SendMessage('MainCamera', 'ScreenshotToJS');
    },
    back() {
      this.$router.replace({
        name: 'MyKiosk',
        params: this.$route.params,
      });
    },
    confirmClose() {
      localStorage.setItem('kioskWarningDialog', DateTime.local().toISO());
      this.warningDialog = false;
    },
    submit() {
      this.submitDialog = true;
    },
    async confirmSubmit() {
      this.exhibitorForm = {
        ...this.exhibitorForm,
        kioskOptions: {
          ...this.exhibitorForm.kioskOptions,
          isSubmitted: true,
        },
      };

      await this.saveScreenshot();
      this.submitDialog = false;
    },
  },
  async mounted() {
    await this[GET_CONFIG]({ name: this.unityKioskBuilderConfigName });
    await this.initializeExhibitor();
    this.initForm();

    this.configUrl = this.config?.value;
    this.loaderUrl = `${this.configUrl.substring(
      0,
      this.configUrl.lastIndexOf('/'),
    )}/UnityLoader.js`;
    this.configs = await DataUtil.getJsonData(this.configUrl);

    await this.loadUnity();
  },
  beforeDestroy() {
    this[CLEAR_CONFIG]();
    this[CLEAR_EXHIBITOR]();
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/core/variables';

@include stepper-layout();

#unityVideo > div {
  height: 100%;
  padding: 0 !important;
  background-color: #000;
}

#unity-container,
#unity-canvas {
  width: 100%;

  &--scroll-disabled {
    overflow: hidden;
  }
}

.webgl-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.content {
  background-color: var(--v-primaryFont-base);
  color: white;
  padding: 0 30px;
  height: calc(100vh - 6rem);

  margin: 25px 50px;

  &__step {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__actions {
    padding: 0;

    & > * {
      flex: 1;
    }
  }
}

.customize {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 85vh;

  &__header {
    display: flex;
    flex-direction: column;
  }

  &__content {
    height: calc(100vh - 26rem);
    overflow: auto;
    margin-bottom: 1rem;
  }
}

.customize-header {
  &__title {
    font-size: $x-large-font-size;
    font-weight: $bold;
    margin-bottom: 2rem;
  }

  &__progress {
    margin-bottom: 2rem;
  }

  &__tasks-title {
    font-size: $x-large-font-size;
    font-weight: $bold;
    margin-bottom: 1rem;
  }
}

.progress {
  &__header {
    display: flex;
    align-items: center;
  }

  &__title {
    font-size: $xx-large-font-size;
  }

  &__warning {
    font-size: $x-small-font-size;
    color: #fdf12c;
  }

  &__percent {
    font-size: $xx-large-font-size;
    font-weight: $bold;
    margin: 0 0.5rem;
    color: var(--v-error-base);
  }
}

.step {
  &__header-title {
    font-weight: $bold;
    margin-bottom: 0.5rem;
  }

  &__header-more {
    font-size: $small-font-size;
  }
}

.stepper {
  &__content {
    padding: 1rem;
    margin-bottom: 1rem;
    border-radius: 1rem;

    img {
      max-height: 200px;
      max-width: 80%;
      border: 1px solid var(--v-gray-base);
    }
  }

  &__action {
    display: flex;
    justify-content: flex-end;
  }
}

.stepper-content {
  &__colors {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

::v-deep .v-stepper {
  .v-stepper {
    &__label {
      width: 100%;
    }

    &__step {
      padding: 24px !important;

      .arrow .v-icon {
        color: black !important;
        font-size: 2.6rem !important;
      }
    }

    &__content {
      padding: 0 20px !important;
      margin: 0 0 10px !important;
    }
  }
}
</style>
