<template>
  <v-form v-model="valid" @submit.prevent="onSave" ref="form" class="p-2">
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-select
            :items="tickets"
            item-value="id"
            item-text="name"
            v-model="selectedTicket"
            :rules="ticketRequired"
            :label="$t('admin.users.selectTicketInput')"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            class="form-row__item-left required"
            v-model="registerForm.firstName"
            :label="$t('auth.register.firstName')"
            :rules="firstnameRules"
            required
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            class="form-row__item-right required"
            v-model="registerForm.lastName"
            :label="$t('auth.register.lastName')"
            :rules="lastnameRules"
            required
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            class="required"
            v-model="registerForm.email"
            :label="$t('auth.register.email')"
            :rules="emailRules"
            required
          />
        </v-col>
      </v-row>
    </v-container>
    <v-card-actions class="actions">
      <v-btn color="primary" outlined @click="onBack">
        {{ $t('globals.back') }}
      </v-btn>
      <v-btn color="primary" outlined @click="onCancel">
        {{ $t('globals.cancel') }}
      </v-btn>
      <v-btn color="primary" type="submit">
        {{ $t('globals.save') }}
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
import VALIDATORS from '@/helpers/forms/validators.helper';
import FileUtil from '@/helpers/file/file.helper';
import validators from '@/helpers/validators/validators';

export default {
  components: {},
  name: 'UploadUser',
  props: ['onBack', 'onCancel', 'tickets', 'eventUsers'],
  data: () => ({
    selectedTicket: '',
    registerForm: {
      firstName: '',
      lastName: '',
      email: '',
    },
    valid: false,
    firstnameRules: VALIDATORS.REQUIRED.FIRSTNAME,
    lastnameRules: VALIDATORS.REQUIRED.LASTNAME,
    emailRules: [VALIDATORS.EMAIL.REQUIRED, VALIDATORS.EMAIL.FORMAT],
    ticketRequired: [validators.required()],
  }),
  methods: {
    onSave() {
      if (!this.$refs.form.validate()) return;

      const data = FileUtil.convertUsersToCsvString([this.registerForm]);
      const file = new File([data], 'file', { type: 'text/plain' });

      this.$emit('importFile', {
        file,
        ticketId: this.selectedTicket,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../styles/core/variables';
.p-2 {
  padding: 1rem;
}
.actions {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
</style>
