<template>
  <v-sheet light>
    <v-col cols="12" class="d-flex flex-column justify-center">
      <div class="d-flex">
        <div class="flex">
          <div>{{ $t('kioskBuilder.edit.kiosk-builder.surfaceColor') }}</div>

          <v-radio-group
            v-model="options.surfaceColor"
            @change="onSurfaceSelect"
            :disabled="isUnityLoadingOrCantUpdate"
            data-test-id="surface"
          >
            <v-radio
              v-for="item in surfaceTextures"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </v-radio-group>
        </div>

        <div class="flex">
          <div>{{ $t('kioskBuilder.edit.kiosk-builder.furnitureColor') }}</div>
          <v-radio-group
            v-model="options.furnitureColor"
            @change="onFurnitureSelect"
            :disabled="isUnityLoadingOrCantUpdate"
            data-test-id="furniture"
          >
            <v-radio
              v-for="item in furnitureColors"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </v-radio-group>
        </div>
      </div>
    </v-col>
  </v-sheet>
</template>

<script>
export default {
  name: 'KioskMoreStep',
  props: {
    kioskOptions: {
      type: Object,
      required: true,
    },
    isUnityLoading: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({}),
  computed: {
    surfaceTextures() {
      return [
        {
          label: this.$t('kioskBuilder.edit.kiosk-builder.textures.wood'),
          value: 'wood',
        },
        {
          label: this.$t('kioskBuilder.edit.kiosk-builder.textures.marble'),
          value: 'marble',
        },
      ];
    },
    furnitureColors() {
      return [
        {
          label: this.$t('kioskBuilder.edit.kiosk-builder.colors.black'),
          value: 'black',
        },
        {
          label: this.$t('kioskBuilder.edit.kiosk-builder.colors.white'),
          value: 'white',
        },
      ];
    },
    canUpdate() {
      return !this.kioskOptions?.isSubmitted;
    },
    isUnityLoadingOrCantUpdate() {
      return !this.canUpdate || this.isUnityLoading;
    },
    options: {
      get() {
        return this.kioskOptions;
      },
    },
  },
  methods: {
    onSurfaceSelect(surface) {
      this.$emit('on-surface-select', surface);
    },
    onFurnitureSelect(furniture) {
      this.$emit('on-furniture-select', furniture);
    },
  },
};
</script>
