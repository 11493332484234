<template>
  <v-container>
    <v-form v-model="valid" v-if="hasUser" :disabled="!canUpdateProfile">
      <v-row class="align-center">
        <user-avatar :avatar="user.avatar" :size="100" />
        <avatar-cropper @save-avatar="handleAvatarSave" data-test-id="save" />
      </v-row>

      <v-row class="justify-end">
        <v-btn
          v-if="asAdmin"
          color="primary"
          outlined
          @click="$emit('cancel')"
          data-test-id="cancel"
        >
          {{ $t('globals.cancel') }}
        </v-btn>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import VALIDATORS from '@/helpers/forms/validators.helper';
import UserAvatar from '@/components/user-avatar/UserAvatar.vue';
import AvatarCropper from '@/components/avatar-cropper/AvatarCropper.vue';

export default {
  name: 'AvatarForm',
  props: {
    user: {
      type: Object,
      required: true,
    },
    canUpdateProfile: {
      type: Boolean,
      default: false,
    },
    asAdmin: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    UserAvatar,
    AvatarCropper,
  },
  data: () => ({
    avatarFile: null,
    avatarRules: VALIDATORS.AVATAR,
    valid: false,
  }),
  computed: {
    hasUser() {
      return !!this.user?.id;
    },
  },
  methods: {
    handleAvatarSave(file) {
      this.avatarFile = file;
      this.uploadFile();
    },
    uploadFile() {
      if (this.avatarFile) {
        this.$emit('upload-avatar', this.avatarFile);
      }
    },
  },
};
</script>
