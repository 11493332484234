<template>
  <v-overlay :value="true">
    <loading :size="size" />
  </v-overlay>
</template>

<script>
import Loading from '@/components/loading/Loading.vue';

export default {
  name: 'OverlayLoading',
  components: {
    Loading,
  },
  props: {
    size: {
      type: Number,
      default: 32,
    },
  },
};
</script>
