<template>
  <v-dialog content-class="v-dialog--overlay" :value="dialogModel" persistent width="900px">
    <v-card>
      <v-card-title class="headline">
        <v-icon v-if="icon" color="error" size="60">mdi-alert-circle-outline</v-icon>
        <div v-if="title" class="headline__title">{{ title }}</div>
      </v-card-title>

      <v-card-text class="dialog-content">
        <div v-html="content" />
      </v-card-text>

      <v-card-actions class="dialog-actions">
        <v-btn v-if="secondaryActionLabel" color="primary" outlined @click="secondaryAction">
          {{ secondaryActionLabel }}
        </v-btn>
        <v-btn color="primary" @click="validateAction">
          {{ validateActionLabel }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'GenericDialog',
  props: {
    dialogModel: {
      type: Boolean,
      required: true,
      default: false,
    },
    icon: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
    content: {
      type: String,
      required: true,
    },
    validateActionLabel: {
      type: String,
      required: true,
    },
    secondaryActionLabel: {
      type: String,
      required: false,
    },
  },
  methods: {
    validateAction() {
      this.$emit('validate-action');
    },
    secondaryAction() {
      this.$emit('secondary-action');
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/styles/core/variables';

.headline {
  display: flex;
  justify-content: center;

  &__title {
    font-size: $large-font-size;
    font-weight: $bold;
  }
}

.dialog-content {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: $large-font-size;
  height: 6rem;
}

.dialog-actions {
  display: flex;
  justify-content: center;
}
</style>
