<template>
  <v-sheet light class="kiosk-types">
    <v-btn
      elevation="0"
      text
      v-for="kiosk in kioskTypes"
      @click="setKioskType(kiosk)"
      :key="kiosk"
      :disabled="isUnityLoadingOrCantUpdate"
    >
      <img
        class="kiosk-types__picture"
        :class="{ 'picture--active': isCurrentKioskType(kiosk) }"
        height="80px"
        :src="getKioskPicture(kiosk)"
      />
    </v-btn>
  </v-sheet>
</template>

<script>
export default {
  name: 'KioskTypeStep',
  props: {
    currentKioskType: {
      type: Number,
      required: true,
      default: -1,
    },
    isSubmitted: {
      type: Boolean,
      required: true,
      default: false,
    },
    isUnityLoading: {
      type: Boolean,
      required: true,
      default: true,
    },
  },
  data: () => ({
    kioskTypes: [1, 2, 3, 4],
  }),
  computed: {
    canUpdate() {
      return !this.isSubmitted;
    },
    isUnityLoadingOrCantUpdate() {
      return !this.canUpdate || this.isUnityLoading;
    },
  },
  methods: {
    getKioskPicture(kiosk) {
      const images = require.context('../../../assets/', true, /\.png$/);

      return images(`./images/kiosk/kiosk_${kiosk}.png`);
    },
    setKioskType(kiosk) {
      this.$emit('set-kiosk-type', kiosk);
    },
    isCurrentKioskType(kiosk) {
      return this.currentKioskType === kiosk;
    },
  },
};
</script>

<style lang="scss" scoped>
.kiosk-types {
  display: flex;
  flex-wrap: wrap;

  &__picture {
    margin: 0.5rem;
    cursor: pointer;
  }
}

.picture {
  &--active {
    border: 3px solid var(--v-primary-base) !important;
  }
}
</style>
