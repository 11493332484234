<template>
  <div id="editStreamAdmin" @submit.prevent @submit="save">
    <v-form v-model="valid" :disabled="!canUpdateStreams">
      <v-row>
        <v-col cols="6">
          <v-select
            v-model="streamType"
            :items="streamTypeItems"
            item-text="label"
            item-value="value"
            :label="$t('admin.streams.editModal.streamType.title')"
            hide-details="auto"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <v-select
            v-model="streamForm.conferenceId"
            v-if="isConference"
            :items="filteredList(conferences)"
            item-text="name"
            item-value="id"
            :label="$t('admin.streams.editModal.streamType.list.conferences')"
            hide-details="auto"
          />

          <v-select
            v-model="streamForm.generalAssemblyId"
            v-if="isGeneralAssembly"
            :items="filteredList(generalAssemblies)"
            item-text="name"
            item-value="id"
            :label="$t('admin.streams.editModal.streamType.list.general-assemblies')"
            hide-details="auto"
          />
        </v-col>
      </v-row>

      <v-card-actions class="actions">
        <v-btn outlined color="primary" @click="goBackToList" :disabled="streamIsSaving">
          {{ $t('globals.cancel') }}
        </v-btn>

        <v-btn
          color="primary"
          type="submit"
          :loading="streamIsSaving"
          :disabled="!valid || !canUpdateStreams"
        >
          {{ $t('globals.save') }}
        </v-btn>
      </v-card-actions>
    </v-form>
  </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';
import { mapActions, mapGetters } from 'vuex';

import PermissionsUtil from '@/helpers/permissions/permissions.helper';

import { Stream, StreamStatus } from '@/models/stream/stream.model';
import { StreamCreateForm } from '@/models/stream/stream-create-form.model';

import { ADMIN_EVENT_MODULE } from '@/stores/umanize-admin/actions/event/admin-event.actions';
import { ADMIN_CONFERENCE_MODULE } from '@/stores/umanize-admin/actions/conference/admin-conference.actions';
import { ADMIN_ASSEMBLY_MODULE } from '@/stores/umanize-admin/actions/general-assembly/general-assembly.actions';
import { CREATE_STREAM, STREAM_MODULE } from '@/stores/umanize-admin/actions/stream/stream.actions';
import { APP_USER_MODULE } from '@/stores/umanize-app/actions/user/app-user.actions';
import { GET_ALL_ASSEMBLIES } from '@/stores/agnostic/actions/general-assembly/general-assembly.actions';
import { GET_CONFERENCES_FOR_EVENT } from '@/stores/agnostic/actions/conference/agnostic-conference.actions';
import { GET_STREAM } from '@/stores/agnostic/actions/stream/agnostic-stream.actions';
import { VideoType } from '@/models/general-assembly/video-type.model';

export default {
  name: 'EditStreamAdmin',
  props: {
    eventId: {
      type: String,
    },
    streamId: {
      type: String,
    },
  },
  data: () => ({
    defaultStream: new Stream(),
    streamForm: new StreamCreateForm(),
    streamType: '',
    tab: 'streamForm',
    valid: false,
  }),
  computed: {
    ...mapGetters(ADMIN_EVENT_MODULE, ['event']),
    ...mapGetters(STREAM_MODULE, ['streams', 'stream', 'streamIsSaving']),
    ...mapGetters(ADMIN_CONFERENCE_MODULE, ['conferences', 'conferencesAreLoading']),
    ...mapGetters(APP_USER_MODULE, ['loggedInUserRoles']),
    ...mapGetters(ADMIN_ASSEMBLY_MODULE, ['generalAssemblies']),
    streamTypeItems() {
      return [
        {
          label: this.$t('admin.streams.editModal.streamType.conference'),
          value: 'conference',
        },
        {
          label: this.$t('admin.streams.editModal.streamType.general-assembly'),
          value: 'generalAssembly',
        },
      ];
    },
    canUpdateStreams() {
      return (
        !this.streamId &&
        PermissionsUtil.isAuthorized(
          ['permission.streaming.canUpdate'],
          this.loggedInUserRoles,
          this.eventId,
        )
      );
    },
    isConference() {
      return this.streamType === 'conference' || this.streamForm.conferenceId;
    },
    isGeneralAssembly() {
      return this.streamType === 'generalAssembly' || this.streamForm.generalAssemblyId;
    },
  },
  methods: {
    ...mapActions(ADMIN_CONFERENCE_MODULE, [GET_CONFERENCES_FOR_EVENT]),
    ...mapActions(ADMIN_ASSEMBLY_MODULE, [GET_ALL_ASSEMBLIES]),
    ...mapActions(STREAM_MODULE, [GET_STREAM, CREATE_STREAM]),
    async initializeStream(eventId, streamId) {
      await this[GET_STREAM]({
        eventId,
        streamId,
      });
      this.streamType = this.stream.conferenceId ? 'conference' : 'generalAssembly';
    },
    initForm() {
      this.streamForm = {
        ...this.defaultStream,
        ...cloneDeep(this.stream),
        eventId: this.$route.params.eventId,
      };
    },
    goBackToList() {
      this.$emit('closeDialog');
    },
    filteredList(items) {
      return this.streamId
        ? items
        : items.filter((item) => item.videoType === VideoType.UMANIZE && !this.isAlreadyUsed(item));
    },
    isAlreadyUsed(item) {
      return this.streams.some(
        (stream) =>
          (stream.conferenceId || stream.generalAssemblyId) === item.id &&
          stream.status !== StreamStatus.DELETED,
      );
    },
    async save() {
      await this[CREATE_STREAM](this.streamForm);
      this.streamType = '';
      await this.goBackToList();
    },
  },
  async mounted() {
    await this[GET_CONFERENCES_FOR_EVENT](this.eventId);
    await this[GET_ALL_ASSEMBLIES](this.eventId);
    if (this.streamId) {
      await this.initializeStream(this.eventId, this.streamId);
    }
    this.initForm();
  },
};
</script>

<style lang="scss" scoped>
@import '../../../../styles/core/variables';
@import '../../../../styles/core/mixins';

@include admin-layout();

.editStreamAdmin {
  &__label {
    font-weight: $semi-bold;
  }

  &__restricted {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > * {
      &:first-child {
        margin-bottom: 0 !important;
      }
    }
  }
}

.subtitle {
  margin-bottom: 10px;
}

::v-deep .v-text-field {
  padding-top: 0;

  .v-label {
    font-weight: $semi-bold;
  }
}
</style>
