<template>
  <div class="profile-ticket-form" v-if="!profileError">
    <overlay-loading :size="64" v-if="!profileIsLoaded" />

    <v-alert type="info" color="#2D2D2D" v-if="profileIsLoaded && !profileCompleted && !asAdmin">
      {{ $t('admin.tickets.profileItems.noCompletedProfile') }}
    </v-alert>

    <v-form
      ref="form"
      v-if="profileIsLoaded && profile && ticketFormLocal"
      v-model="valid"
      @submit.prevent
      @submit="updateTicketProfile()"
    >
      <div class="profile-ticket-form__form">
        <v-text-field
          v-model="profileForm.firstName"
          placeholder="-"
          :persistent-placeholder="true"
          :label="getLabel($t('auth.profile.firstName'), true)"
          :rules="rules.firstName"
          required
        />

        <v-text-field
          v-model="profileForm.lastName"
          placeholder="-"
          :persistent-placeholder="true"
          :label="getLabel($t('auth.profile.lastName'), true)"
          :rules="rules.lastName"
          required
        />

        <div class="profile-ticket-form__about">
          <v-label class="pb-2">
            {{ $t('auth.profile.about') }}
          </v-label>

          <vue-editor-image :content="profileForm.about" @set-content="setAbout"></vue-editor-image>
        </div>

        <div
          v-for="(item, item_index) in ticketFormLocal.profileItems"
          :key="`profile__items-${item_index}`"
        >
          <v-text-field
            :key="item.id"
            v-if="item.type === ProfileItemTypes.text"
            v-model="profileForm.profileItems[item.id].Value"
            :label="getLabel(translate(item.name.fr, item.name.en), item.required)"
            :title.prop="getLabel(translate(item.name.fr, item.name.en), item.required)"
            :required="!asAdmin ? item.required : false"
            :rules="!asAdmin && item.required ? rules.field : undefined"
            :persistent-placeholder="true"
            placeholder="-"
            hide-details="auto"
          />

          <v-text-field
            :key="item.id"
            v-if="item.type === ProfileItemTypes.number"
            :label="getLabel(translate(item.name.fr, item.name.en), item.required)"
            :title.prop="getLabel(translate(item.name.fr, item.name.en), item.required)"
            v-model="profileForm.profileItems[item.id].Value"
            :persistent-placeholder="true"
            aria-valuemax="100"
            class="pt-0"
            hide-details="auto"
            placeholder="-"
            single-line
            type="number"
            :rules="!asAdmin && item.required ? rules.field : undefined"
            :required="!asAdmin ? item.required : false"
          />

          <v-textarea
            :key="item.id"
            v-if="item.type === ProfileItemTypes.textarea"
            class="full"
            :rows="3"
            v-model="profileForm.profileItems[item.id].Value"
            :label="getLabel(translate(item.name.fr, item.name.en), item.required)"
            :title.prop="getLabel(translate(item.name.fr, item.name.en), item.required)"
            hide-details="auto"
            :persistent-placeholder="true"
            placeholder="-"
            :rules="!asAdmin && item.required ? rules.field : undefined"
            :required="!asAdmin ? item.required : false"
          />

          <v-text-field
            :key="item.id"
            v-if="item.type === ProfileItemTypes.email"
            v-model="profileForm.profileItems[item.id].Value"
            :label="getLabel(translate(item.name.fr, item.name.en), item.required)"
            :title.prop="getLabel(translate(item.name.fr, item.name.en), item.required)"
            :rules="!asAdmin && item.required ? rules.email.user : rules.email.admin"
            :required="!asAdmin ? item.required : false"
          />

          <vue-tel-input-vuetify
            :key="item.id"
            v-if="item.type === ProfileItemTypes.phone"
            class="phone-field"
            :placeholder="$t('auth.profile.phoneNumber')"
            v-model="profileForm.profileItems[item.id].Value"
            :label="getLabel(translate(item.name.fr, item.name.en), item.required)"
            :title.prop="getLabel(translate(item.name.fr, item.name.en), item.required)"
            :maxLen="40"
            mode="international"
            :rules="!asAdmin && item.required ? rules.phone.user : rules.phone.admin"
            :required="!asAdmin ? item.required : false"
            :disabledFetchingCountry="true"
            :defaultCountry="defaultCountry"
            :preferredCountries="preferredCountries"
          />

          <v-menu
            :key="item.id"
            v-if="item.type === ProfileItemTypes.date"
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                class="form-row__item-left light-icon"
                :value="profileForm.profileItems[item.id].Value | date('long')"
                :label="getLabel(translate(item.name.fr, item.name.en), item.required)"
                :title.prop="getLabel(translate(item.name.fr, item.name.en), item.required)"
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                :required="item.required"
              />
            </template>

            <v-date-picker
              ref="picker"
              v-model="profileForm.profileItems[item.id].Value"
              no-title
              scrollable
              @input="menu = false"
            />
          </v-menu>

          <v-select
            :key="item.id"
            v-if="item.type === ProfileItemTypes.listbox"
            :label="getLabel(translate(item.name.fr, item.name.en), item.required)"
            :title.prop="getLabel(translate(item.name.fr, item.name.en), item.required)"
            :items="item.values"
            item-value="id"
            v-model="profileForm.profileItems[item.id].itemId"
            :item-text="(i) => translate(i.value.fr, i.value.en)"
            :multiple="false"
            :required="asAdmin ? false : item.required"
            :rules="!asAdmin && item.required ? rules.select : undefined"
          />

          <v-select
            :key="item.id"
            v-if="item.type === ProfileItemTypes.listboxMultiple"
            :label="getLabel(translate(item.name.fr, item.name.en), item.required)"
            :title.prop="getLabel(translate(item.name.fr, item.name.en), item.required)"
            :items="item.values"
            item-value="id"
            :item-text="(i) => translate(i.value.fr, i.value.en)"
            :rules="!asAdmin && item.required ? rules.select : undefined"
            v-model="profileForm.profileItems[item.id].itemIds"
            :multiple="true"
          />

          <v-radio-group
            :key="item.id"
            v-if="item.type === ProfileItemTypes.radio"
            :label="getLabel(translate(item.name.fr, item.name.en), item.required)"
            :title.prop="getLabel(translate(item.name.fr, item.name.en), item.required)"
            class="radio__item"
            row
            hide-detail
            v-model="profileForm.profileItems[item.id].itemId"
            required
            :rules="!asAdmin && item.required ? rules.select : undefined"
          >
            <br />
            <v-radio
              v-for="v in item.values"
              :key="`radio__items-${v.id}`"
              :label="translate(v.value.fr, v.value.en)"
              :value="v.id"
            />
          </v-radio-group>

          <div
            :class="checkInlineCheckbox(item) ? 'checkboxs checkboxs--no-label' : 'checkboxs'"
            v-if="item.type === ProfileItemTypes.checkbox"
          >
            <div class="mb-1">
              {{ getLabel(translate(item.name.fr, item.name.en), item.required) }}
            </div>

            <v-col
              class="checkbox__col ma-0 pa-0"
              v-for="(value, value_index) in item.values"
              :key="`checkbox__col-${item.id}-${value_index}`"
            >
              <div class="checkbox d-flex ma-0">
                <v-checkbox
                  hide-details
                  class="ma-0 pa-0"
                  :key="`checkbox__items-${item.id}-${value_index}`"
                  :value="value.id"
                  v-model="profileForm.profileItems[item.id].itemIds"
                  :rules="
                    !asAdmin && item.required
                      ? rules.checkbox(profileForm.profileItems[item.id].itemIds)
                      : undefined
                  "
                />
                <div class="ma-0 pa-0" v-html="translate(value.value.fr, value.value.en)" />
              </div>
            </v-col>
          </div>
        </div>
      </div>

      <v-card-actions :class="{ 'justify-end': asAdmin, 'justify-center': !asAdmin }">
        <v-btn v-if="isDialog" class="mx-2" color="primary" outlined @click="$emit('cancel')">
          {{ $t('globals.cancel') }}
        </v-btn>

        <v-btn :class="isDialog ? 'mx-2' : null" color="primary" type="submit" :disabled="!valid">
          {{ $t('auth.profile.update') }}
        </v-btn>
      </v-card-actions>
    </v-form>
  </div>

  <div v-else>
    <div class="error--text">
      {{ profileError.message }}
    </div>

    <v-card-actions :class="{ 'justify-end': asAdmin, 'justify-center': !asAdmin }">
      <v-btn v-if="isDialog" class="mx-2" color="primary" outlined @click="$emit('cancel')">
        {{ $t('globals.cancel') }}
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { UserTicketProfile, ProfileItemType } from '@/models';

import OverlayLoading from '@/components/loading/OverlayLoading.vue';
import VueEditorImage from '@/components/vue-editor-image/VueEditorImage.vue';

import VALIDATORS from '@/helpers/forms/validators.helper';
import validators from '@/helpers/validators/validators';

export default {
  name: 'ProfileTicketForm',
  components: {
    OverlayLoading,
    VueEditorImage,
  },
  props: {
    ticketForm: {
      type: Object,
    },
    profile: {
      type: Object,
      default: null,
    },
    profileIsLoaded: {
      type: Boolean,
      required: true,
    },
    profileIsLoading: {
      type: Boolean,
      required: true,
    },
    profileError: {
      type: Object,
    },
    isDialog: {
      type: Boolean,
      default: false,
    },
    asAdmin: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    autocomplete: null,
    profileForm: new UserTicketProfile(),
    menu: false,
    date: null,
    valid: false,
    rules: {
      phone: {
        admin: [VALIDATORS.PHONE.FORMAT],
        user: [validators.required(), VALIDATORS.PHONE.FORMAT],
      },
      firstName: VALIDATORS.REQUIRED.FIRSTNAME,
      field: [validators.required()],
      lastName: VALIDATORS.REQUIRED.LASTNAME,
      email: {
        admin: [VALIDATORS.EMAIL.FORMAT],
        user: [VALIDATORS.EMAIL.REQUIRED, VALIDATORS.EMAIL.FORMAT],
      },
      select: [validators.required()],
      checkbox: validators.checkbox,
    },
    ProfileItemTypes: ProfileItemType,
    defaultCountry: 'CA',
    preferredCountries: ['CA'],
  }),
  computed: {
    profileCompleted() {
      return this.profile?.completed;
    },
    ticketFormLocal: {
      get() {
        return this.ticketForm;
      },
      set(value) {
        this.$emit('ticketForm', value);
      },
    },
  },
  methods: {
    getLabel(label, required) {
      return required ? `${label} *` : label;
    },
    setAbout(about) {
      this.profileForm.about = about;
    },
    initForm() {
      if (this.profile) {
        this.profileForm = {
          ...this.profileForm,
          ...this.profile,
        };

        const { profileItems } = this.profile;

        this.profileForm.profileItems =
          this.ticketFormLocal.profileItems?.map(({ id }, index) => ({
            profileItemId: id,
            Value: profileItems[index]?.Value ?? '',
            itemIds: profileItems[index]?.itemIds ?? [],
            itemId: profileItems[index]?.itemId ?? null,
          })) || [];
      }
    },
    checkInlineCheckbox(item) {
      return item.values?.length === 1 && this.translate(item.name.fr, item.name.en).trim() === '';
    },
    updateTicketProfile() {
      let isValid = this.valid;

      if (this.asAdmin) {
        const { profileItems } = this.profileForm;

        isValid = this.ticketFormLocal.profileItems.every((item, index) => {
          if (!item.required) {
            return true;
          }

          if (
            [this.ProfileItemTypes.checkbox, this.ProfileItemTypes.listboxMultiple].includes(
              item.type,
            )
          ) {
            return profileItems[index]?.itemIds.length > 0;
          }

          if ([this.ProfileItemTypes.radio, this.ProfileItemTypes.listbox].includes(item.type)) {
            return profileItems[index]?.itemId === 0 || !!profileItems[index]?.itemId;
          }

          if (this.ProfileItemTypes.number === item.type) {
            return profileItems[index]?.Value === 0 || !!profileItems[index]?.Value;
          }

          return !!profileItems[index]?.Value;
        });
      }

      this.$emit('update-profile', this.profileForm, isValid);
    },
    translate(fr, en) {
      if (this.$root.$i18n.locale.toUpperCase().includes('FR')) {
        return fr;
      }
      return en;
    },
  },
  created() {
    this.initForm();
    this.$emit('get-profile');
  },
  watch: {
    menu(val) {
      return (
        val &&
        setTimeout(() => {
          this.$refs.picker.activePicker = 'YEAR';
        })
      );
    },
    profile() {
      this.initForm();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../styles/core/variables';

::v-deep .v-list-item__title {
  white-space: pre-wrap !important;
}

.profile-ticket-form {
  &__form {
    display: grid !important;
    grid-template-columns: repeat(auto-fill, 45%);
    grid-gap: 24px;
  }

  ::v-deep .v-select__selections {
    max-width: 50vw;
  }

  &__about {
    width: 100%;
    margin-right: 10px;
    min-height: auto;
    grid-column-start: 1;
    grid-column-end: 3;

    word-break: break-word;
  }

  ::v-deep .v-label {
    white-space: pre-wrap;
    width: 100%;
    position: relative !important;
  }

  ::v-deep .v-select__slot .v-label {
    white-space: pre-wrap;
    position: absolute !important;
    width: 100%;
  }

  ::v-deep .v-text-field > .v-input__control > .v-input__slot > .v-text-field__slot {
    display: block !important;
  }
}

.radio__item {
  flex-direction: column !important;
  align-content: space-between;
}

.checkboxs {
  .checkbox {
    padding-left: 5px;
  }

  &--no-label {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: flex-start;
    align-content: flex-start;

    .checkbox__col {
      flex-grow: unset;
      flex-basis: unset;
      max-width: max-content;
    }

    .checkbox {
      padding-left: 0px;
    }
  }
}
</style>
