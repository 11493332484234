var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-items"},[_c('div',{staticClass:"content-items__actions mt-4"},[_c('v-btn',{staticClass:"actions__create",attrs:{"data-test-id":"add","large":"","depressed":"","color":"primary","disabled":!_vm.canUpdate,"to":("/events/" + (_vm.event.id) + "/admin/content-libraries/" + (_vm.contentCategory.id) + "/edit/content-items/add")}},[_vm._v(" "+_vm._s(_vm.$t('globals.add'))+" "),_c('v-icon',[_vm._v("mdi-plus")])],1),_c('AppSearch',{staticClass:"actions__search",on:{"change":_vm.onSearchChange}})],1),_c('base-list',{staticClass:"mt-4",attrs:{"className":"img-first","headers":_vm.headers,"search":_vm.search,"items":_vm.contentItems,"loading":_vm.itemsAreLoading,"footerProps":{
      itemsPerPageText: _vm.$t('globals.datatable.itemPerPage'),
      itemsPerPageAllText: _vm.$t('globals.datatable.itemPerPageAll'),
      pageText: _vm.$t('globals.datatable.of'),
    },"noDataText":_vm.$t('globals.datatable.noData'),"noResultsText":_vm.$t('globals.datatable.noResult')},scopedSlots:_vm._u([{key:"item.coverImage",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"container-img"},[(item.coverImage)?_c('v-img',{staticClass:"container-img__img",attrs:{"src":item.coverImage}}):_c('p',{staticClass:"container-img__placeholder"},[_vm._v(" "+_vm._s(_vm.$t('event.edit.noCover'))+" ")])],1)]}},{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_c('p',{staticClass:"truncate"},[_vm._v(_vm._s(item.name))])]}},{key:"item.description",fn:function(ref){
    var item = ref.item;
return [_c('p',{staticClass:"truncate"},[_vm._v(_vm._s(item.description))])]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('admin-table-actions',{attrs:{"user":_vm.user,"can-edit":_vm.canUpdate,"can-delete":_vm.canDelete},on:{"edit-item":function($event){return _vm.editContentItem(item)},"delete-item":function($event){return _vm.deleteContentItem(item)}}})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }