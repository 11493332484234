export class CustomUI {
  id: string;

  eventId: string;

  event3dBtnText?: string;

  event2dBtnText?: string;

  conferenceBtnText?: string;

  generalAssembliesBtnText?: string;

  discussionGroupsBtnText?: string;

  contentLibraryBtnText?: string;

  btnColor?: string;

  btnTextColor?: string;
}
