<template>
  <v-container class="tags">
    <h1 class="tags__title">
      {{ $t('admin.tags.title') }}
    </h1>

    <v-container class="tags__content">
      <v-row class="content__row" v-if="canViewEventTags">
        <v-col cols="12">
          <p class="content__category">{{ $t('admin.tags.event.title') }}</p>
        </v-col>

        <v-col cols="12">
          <v-chip class="tag" v-for="tag in eventTags" :key="tag.id"> {{ tag.name }}</v-chip>
        </v-col>

        <v-col cols="6" v-if="false">
          <v-text-field
            :placeholder="$t('admin.tags.placeholder')"
            v-model="eventTag"
          ></v-text-field>
          <v-btn color="primary" @click="addEventTag"> {{ $t('globals.add') }}</v-btn>
        </v-col>
      </v-row>

      <v-divider v-if="canViewEventTags" />

      <v-row class="content__row">
        <v-col cols="12">
          <p class="content__category">{{ $t('admin.tags.exhibitors.title') }}</p>
        </v-col>

        <v-col cols="12">
          <v-chip class="tag" v-for="tag in exhibitorTags" :key="tag.id"> {{ tag.name }}</v-chip>
        </v-col>

        <v-col cols="6" v-if="canCreateTags">
          <v-text-field
            :placeholder="$t('admin.tags.placeholder')"
            v-model="exhibitorTag"
          ></v-text-field>
          <v-btn color="primary" @click="addExhibitorTag"> {{ $t('globals.add') }}</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import PermissionsUtil from '@/helpers/permissions/permissions.helper';

import { APP_USER_MODULE } from '@/stores/umanize-app/actions/user/app-user.actions';
import {
  ADD_TAG,
  ADMIN_TAG_MODULE,
  GET_TAGS,
} from '@/stores/umanize-admin/actions/tag/admin-tag.actions';
import { ADMIN_EVENT_MODULE } from '@/stores/umanize-admin/actions/event/admin-event.actions';

export default {
  name: 'TagAdmin',
  data: () => ({
    exhibitorTag: null,
    eventTag: null,
  }),
  computed: {
    ...mapGetters(ADMIN_TAG_MODULE, ['tagsByType', 'tagsAreLoading']),
    ...mapGetters(APP_USER_MODULE, ['currentLoggedInUser', 'loggedInUserRoles']),
    ...mapGetters(ADMIN_EVENT_MODULE, ['event']),
    canViewEventTags() {
      return PermissionsUtil.isAuthorized(
        ['permission.events.canCreate'],
        this.loggedInUserRoles,
        this.event.id,
      );
    },
    canCreateTags() {
      return PermissionsUtil.isAuthorized(
        ['permission.events.canUpdatePartial'],
        this.loggedInUserRoles,
        this.event.id,
      );
    },
    canViewTags() {
      return PermissionsUtil.isAuthorized(
        ['permission.events.canView'],
        this.loggedInUserRoles,
        this.event.id,
      );
    },
    exhibitorTags() {
      return this.tagsByType('exhibitor');
    },
    eventTags() {
      return this.tagsByType('event');
    },
  },
  methods: {
    ...mapActions(ADMIN_TAG_MODULE, [GET_TAGS, ADD_TAG]),
    async addExhibitorTag() {
      await this[ADD_TAG]({
        eventId: this.$route.params.eventId,
        tag: {
          name: this.exhibitorTag.toLowerCase(),
          type: 'exhibitor',
        },
      });
      this.exhibitorTag = null;
      await this.getTags();
    },
    async addEventTag() {
      await this[ADD_TAG]({
        eventId: this.$route.params.eventId,
        tag: {
          name: this.eventTag.toLowerCase(),
          type: 'event',
        },
      });
      this.eventTag = null;
      await this.getTags();
    },
    async getTags() {
      await this[GET_TAGS]({ eventId: this.$route.params.eventId });
    },
  },
  async mounted() {
    await this.getTags();
  },
};
</script>

<style lang="scss" scoped>
@import '../../../styles/core/variables';
@import '../../../styles/core/mixins';

@include admin-layout();

.tags {
  min-height: calc(100vh - 6rem);
  background-color: var(--v-adminBackground-base);
  padding: 50px;

  &__title {
    font-size: $x-large-font-size;
    font-weight: $regular;
    margin-bottom: 10px;
  }

  &__content {
    @include admin-card;

    margin: 0;
    padding: 20px 40px;
  }
}

.content {
  &__category {
    margin: 0;
    font-weight: $semi-bold;
  }

  &__row {
    margin: 10px 0;
  }
}

.tag {
  margin-right: 5px;
  margin-bottom: 5px;
}
</style>
