<template>
  <v-container id="content-libraries">
    <h2 class="content-libraries__title">
      {{ event.name }} - {{ $t('contentLibraries.list.title') }}
    </h2>

    <div class="content-libraries__actions">
      <v-btn
        class="actions__create"
        large
        depressed
        color="primary"
        :disabled="!canCreate"
        :to="`/events/${event.id}/admin/content-libraries/add`"
      >
        {{ $t('contentLibraries.list.add') }}
        <v-icon right>mdi-plus</v-icon>
      </v-btn>

      <AppSearch class="actions__search" @change="onSearchChange" />
    </div>

    <base-list
      class="mt-4"
      className="img-first"
      backgroundColor="var(--v-adminBackground-base)"
      :headers="headers"
      :items="contentCategories"
      :search="search"
      :loading="categoriesAreLoading"
      :footerProps="{
        itemsPerPageText: $t('globals.datatable.itemPerPage'),
        itemsPerPageAllText: $t('globals.datatable.itemPerPageAll'),
        pageText: $t('globals.datatable.of'),
      }"
      :noDataText="$t('globals.datatable.noData')"
      :noResultsText="$t('globals.datatable.noResult')"
    >
      <template v-slot:[`item.coverImage`]="{ item }">
        <div class="container-img">
          <img :src="item.coverImage" class="container-img__img" />
        </div>
      </template>

      <template v-slot:[`item.name`]="{ item }">
        <p class="truncate">{{ item.name }}</p>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <admin-table-actions
          :user="currentLoggedInUser"
          @edit-item="editContentLibrary(item)"
          :can-edit="canUpdate"
          @delete-item="confirmDeleteContentLibrary(item)"
          :can-delete="canDelete"
          :is-disabled="hasContent(item)"
          :disabled-tooltip="$t('contentLibraries.delete.hasContent')"
        />
        <dialog-confirmation
          :v-if="categoriesToDelete"
          :visible="showConfirmation"
          :title="$t('contentLibraries.delete.deleteConfirmationDialog.title')"
          :content="$t('contentLibraries.delete.deleteConfirmationDialog.content')"
          @cancel="confirmationDialogCancel"
          @confirm="confirmationDialogConfirm"
        />
      </template>
    </base-list>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import {
  GET_CONTENT_CATEGORIES,
  GET_CONTENT_ITEMS,
} from '@/stores/agnostic/actions/content/agnostic-content.actions';
import { ADMIN_EVENT_MODULE } from '@/stores/umanize-admin/actions/event/admin-event.actions';
import {
  ADMIN_CONTENT_MODULE,
  DELETE_CONTENT_CATEGORY,
} from '@/stores/umanize-admin/actions/content/admin-content.actions';
import { APP_USER_MODULE } from '@/stores/umanize-app/actions/user/app-user.actions';

import PermissionsUtil from '@/helpers/permissions/permissions.helper';
import AdminTableActions from '@/components/admin/admin-table-actions/AdminTableActions.vue';
import BaseList from '@/components/base-list/BaseList.vue';
import AppSearch from '@/components/app/app-search/AppSearch.vue';
import DialogConfirmation from '../../DialogConfirmation.vue';

export default {
  name: 'ContentLibrariesAdmin',
  components: {
    AdminTableActions,
    DialogConfirmation,
    BaseList,
    AppSearch,
  },
  data: () => ({
    search: '',
    categoriesToDelete: null,
    showConfirmation: false,
    categoriesWithItems: [],
  }),
  computed: {
    ...mapGetters(ADMIN_CONTENT_MODULE, [
      'contentCategories',
      'categoriesAreLoading',
      'contentItems',
    ]),
    ...mapGetters(ADMIN_EVENT_MODULE, ['event']),
    ...mapGetters(APP_USER_MODULE, ['currentLoggedInUser', 'loggedInUserRoles']),
    headers() {
      return [
        {
          value: 'coverImage',
          width: 200,
        },
        {
          text: this.$t('contentLibraries.list.columns.name'),
          value: 'name',
          sortable: false,
        },
        {
          value: 'actions',
          width: 100,
        },
      ];
    },
    canUpdate() {
      return PermissionsUtil.isAuthorized(
        ['permission.contentLibraries.canUpdate'],
        this.loggedInUserRoles,
        this.$route.params.eventId,
      );
    },
    canCreate() {
      return PermissionsUtil.isAuthorized(
        ['permission.contentLibraries.canCreate'],
        this.loggedInUserRoles,
        this.$route.params.eventId,
      );
    },
    canDelete() {
      return PermissionsUtil.isAuthorized(
        ['permission.contentLibraries.canDelete'],
        this.loggedInUserRoles,
        this.$route.params.eventId,
      );
    },
  },
  methods: {
    ...mapActions(ADMIN_CONTENT_MODULE, [
      GET_CONTENT_CATEGORIES,
      GET_CONTENT_ITEMS,
      DELETE_CONTENT_CATEGORY,
    ]),
    onSearchChange(search) {
      this.search = search;
    },
    editContentLibrary(item) {
      const { eventId } = this.$route.params;
      this.$router.push({
        name: 'EditContentLibraryAdmin',
        params: {
          eventId,
          contentLibraryId: item.id,
        },
      });
    },
    confirmDeleteContentLibrary(item) {
      this.categoriesToDelete = item;
      this.showConfirmation = true;
    },
    confirmationDialogCancel() {
      this.categoriesToDelete = null;
      this.showConfirmation = false;
    },
    async confirmationDialogConfirm() {
      await this.deleteContentLibrary(this.categoriesToDelete);
      this.categoriesToDelete = null;
      this.showConfirmation = false;
    },
    async deleteContentLibrary(category) {
      await this[DELETE_CONTENT_CATEGORY]({
        eventId: this.$route.params.eventId,
        categoryId: category.id,
      });
    },
    async retrieveContent(category) {
      const { eventId } = this.$route.params;
      await this[GET_CONTENT_ITEMS]({ eventId, categoryId: category.id });
      if (this.contentItems.length > 0) {
        this.categoriesWithItems.push(category.id);
      }
    },
    hasContent(category) {
      return this.categoriesWithItems.some((item) => item === category.id);
    },
  },
  async mounted() {
    const { eventId } = this.$route.params;
    await this[GET_CONTENT_CATEGORIES](eventId);
    this.contentCategories.forEach((category) => this.retrieveContent(category));
  },
};
</script>

<style lang="scss" scoped>
@import '../../../styles/core/variables';
@import '../../../styles/core/mixins';

@include admin-layout();

#content-libraries {
  padding: 50px;
  min-height: calc(100vh - 6rem);
}

.content-libraries {
  &__title {
    margin-bottom: 10px;
  }

  &__actions {
    display: flex;
    align-items: center;

    & > *:not(:last-child) {
      margin-right: 10px;
    }
  }
}

.truncate {
  @include ellipsis();
}

.container-img {
  &__img {
    width: 100%;
    height: 100%;
  }
}

@include breakpoint(medium) {
  .container-img {
    width: 200px;
    height: 100px;
    border-radius: 10px 0 0 10px;
    border-right: 1px solid var(--v-gray-base);

    &__img {
      border-radius: 10px 0 0 10px;
    }
  }
}
</style>
