<template>
  <span class="price" v-text="price" />
</template>

<script>
import { getUserLocale } from 'get-user-locale';
import DataUtil from '@/helpers/data/data.helper';

export default {
  name: 'BasePrice',
  props: {
    amount: {
      type: Number,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
    locale: {
      type: String,
      required: false,
    },
    prefix: {
      type: String,
      default: '',
    },
  },
  computed: {
    price() {
      const usedLocale = this.locale ? this.locale : getUserLocale(); // Returns the user's preferred locales

      return this.prefix + DataUtil.formattedPrice(this.amount, this.currency, usedLocale);
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../styles/core/variables';

.price {
  color: inherit;
  font-family: $base-font-family;
}
</style>
