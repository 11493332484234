export class GroupChat {
  userIds: string[];

  adminIds: string[];

  name: string;

  constructor() {
    this.userIds = [];
    this.adminIds = [];
    this.name = '';
  }
}
