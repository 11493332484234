var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"data-test-id":"users-list","headers":_vm.headers,"items":_vm.users,"footer-props":{
      itemsPerPageText: _vm.$t('globals.datatable.itemPerPage'),
      itemsPerPageAllText: _vm.$t('globals.datatable.itemPerPageAll'),
    },"no-data-text":_vm.$t('globals.datatable.noData'),"no-results-text":_vm.$t('globals.datatable.noResult')},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.text)+" ")]}},{key:"item.isAdmin",fn:function(ref){
    var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"value":item.isAdmin,"data-test-id":"user-checkbox"},on:{"click":function($event){return _vm.onChangeAdmins(item)}}})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }