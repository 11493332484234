var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"group-chat"}},[_c('h2',{staticClass:"group-chat__title",attrs:{"data-test-id":"title"}},[_vm._v(" "+_vm._s(_vm.$t('admin.groupChat.title'))+" ")]),_c('div',{staticClass:"group-chat__actions"},[_c('v-btn',{staticClass:"actions__create",attrs:{"data-test-id":"btn-create","large":"","color":"primary","disabled":!_vm.canCreateGroupChat},on:{"click":function($event){return _vm.openDialog('add')}}},[_vm._v(" "+_vm._s(_vm.$t('globals.add'))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-plus")])],1)],1),_c('base-list',{staticClass:"mt-4",attrs:{"backgroundColor":"var(--v-adminBackground-base)","headers":_vm.headers,"headerProps":{ sortIcon: null },"items":_vm.groupChats(_vm.event.id),"itemsPerPage":10,"loading":!_vm.isLoaded,"footerProps":{
      itemsPerPageText: _vm.$t('globals.datatable.itemPerPage'),
      itemsPerPageAllText: _vm.$t('globals.datatable.itemPerPageAll'),
      pageText: _vm.$t('globals.datatable.of'),
    },"noDataText":_vm.$t('globals.datatable.noData'),"noResultsText":_vm.$t('globals.datatable.noResult')},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('admin-table-actions',{attrs:{"data-test-id":"btn-edit","user":_vm.currentLoggedInUser,"can-edit":_vm.canUpdateGroupChat},on:{"edit-item":function($event){return _vm.editGroupChatUsers(item)}}})]}}],null,true)}),(_vm.dialog.add)?_c('v-dialog',{attrs:{"content-class":"v-dialog--overlay","width":"800","data-test-id":"modal-add"},on:{"click:outside":function($event){return _vm.closeDialog('add')}},model:{value:(_vm.dialog.add),callback:function ($$v) {_vm.$set(_vm.dialog, "add", $$v)},expression:"dialog.add"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.event.name)+" - "+_vm._s(_vm.$t('admin.groupChat.editModal.title.create'))+" ")]),_c('v-card-text',[_c('add-group-chat-admin',{attrs:{"users":_vm.eventUsers,"tickets":_vm.tickets,"selected-chat":_vm.selectedGroupChat,"can-create":_vm.canCreateGroupChat},on:{"add":_vm.addGroupChat,"cancel":function($event){return _vm.closeDialog('add')}}})],1)],1)],1):_vm._e(),(_vm.dialog.edit)?_c('v-dialog',{attrs:{"width":"800","data-test-id":"modal-edit"},on:{"click:outside":function($event){return _vm.closeDialog('edit')}},model:{value:(_vm.dialog.edit),callback:function ($$v) {_vm.$set(_vm.dialog, "edit", $$v)},expression:"dialog.edit"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.event.name)+" - "+_vm._s(_vm.$t('admin.groupChat.editModal.title.edit'))+" ")]),_c('v-card-text',[_c('add-group-chat-admin',{attrs:{"users":_vm.eventUsers,"tickets":_vm.tickets,"selected-chat":_vm.selectedGroupChat,"can-update":_vm.canUpdateGroupChat,"is-type-add":false},on:{"edit":_vm.editGroupChat,"cancel":function($event){return _vm.closeDialog('edit')}}})],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }