<template>
  <v-container class="edit-discussion-groups">
    <h1 class="edit-discussion-groups__title">
      {{ event.name }} - {{ $t('admin.discussionGroups.editModal.title') }}
    </h1>

    <v-container class="edit-discussion-groups__content">
      <v-tabs v-if="discussionGroupForm.id" v-model="tab">
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab href="#discussionGroup">{{ $t('admin.discussionGroups.title') }}</v-tab>
        <v-tab href="#partners">{{ $t('admin.partners.title') }}</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item value="discussionGroup">
          <v-form v-model="valid" :disabled="!canUpdatePartial" ref="form">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="discussionGroupForm.name"
                  :label="$t('admin.discussionGroups.editModal.name')"
                  :rules="nameRules"
                  hide-details="auto"
                  :persistent-placeholder="true"
                  placeholder="-"
                />
              </v-col>

              <v-col cols="12" sm="6">
                <DateTimePicker
                  :disabled="!canUpdatePartial"
                  :label="$t('admin.discussionGroups.editModal.startTime')"
                  v-model="discussionGroupForm.startTime"
                  :textFieldProps="{ rules: startTimeRules }"
                >
                  <template slot="actions" slot-scope="{ parent }">
                    <v-btn color="grey darken-1" text @click="parent.display = false">
                      {{ $t('globals.cancel') }}
                    </v-btn>

                    <v-btn color="green darken-1" text @click="parent.okHandler">
                      {{ $t('globals.ok') }}
                    </v-btn>
                  </template>

                  <template slot="dateIcon">
                    <v-icon>mdi-calendar</v-icon>
                  </template>

                  <template slot="timeIcon">
                    <v-icon>mdi-clock</v-icon>
                  </template>
                </DateTimePicker>
              </v-col>

              <v-col cols="12" sm="6">
                <DateTimePicker
                  :disabled="!canUpdatePartial"
                  :label="$t('admin.discussionGroups.editModal.endTime')"
                  v-model="discussionGroupForm.endTime"
                  :textFieldProps="{ rules: endTimeRules }"
                >
                  <template slot="actions" slot-scope="{ parent }">
                    <v-btn color="grey darken-1" text @click="parent.display = false">
                      {{ $t('globals.cancel') }}
                    </v-btn>

                    <v-btn color="green darken-1" text @click="parent.okHandler">
                      {{ $t('globals.ok') }}
                    </v-btn>
                  </template>

                  <template slot="dateIcon">
                    <v-icon>mdi-calendar</v-icon>
                  </template>

                  <template slot="timeIcon">
                    <v-icon>mdi-clock</v-icon>
                  </template>
                </DateTimePicker>
              </v-col>

              <v-col cols="12">
                <v-textarea
                  :label="$t('admin.discussionGroups.editModal.description')"
                  :rows="2"
                  auto-grow
                  v-model="discussionGroupForm.description"
                  hide-details="auto"
                  :persistent-placeholder="true"
                  placeholder="-"
                />
              </v-col>

              <v-col cols="12">
                <v-text-field
                  v-model="discussionGroupForm.wherebyUrl"
                  :label="$t('admin.discussionGroups.editModal.wherebyUrl')"
                  disabled
                  hide-details="auto"
                  :persistent-placeholder="true"
                  placeholder="-"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="discussionGroupForm.wherebyHostUrl"
                  :label="$t('admin.discussionGroups.editModal.wherebyUrlHostUrl')"
                  disabled
                  hide-details="auto"
                  :persistent-placeholder="true"
                  placeholder="-"
                />
              </v-col>

              <v-col cols="12" sm="4">
                <v-checkbox
                  :disabled="!canUpdatePartial"
                  v-model="discussionGroupForm.hasWherebyChat"
                  :label="$t('admin.discussionGroups.editModal.hasWherebyChat')"
                  hide-details="auto"
                  :persistent-placeholder="true"
                  placeholder="-"
                />
              </v-col>

              <v-col cols="12" sm="8">
                <v-text-field
                  v-if="discussionGroupForm.id"
                  :disabled="discussionGroupForm.hasWherebyChat || !canUpdate"
                  :label="$t('admin.discussionGroups.editModal.chatRoom')"
                  v-model="discussionGroupForm.chatRoom"
                  hide-details="auto"
                  :persistent-placeholder="true"
                  placeholder="-"
                />
              </v-col>

              <v-col cols="12" sm="12">
                <v-label>{{ $t('discussion-group.edit.cover-images.label') }}</v-label>

                <base-input-multiple
                  :placeholder="$t('discussion-group.edit.cover-images.placeholder')"
                  :values="discussionGroupForm.coverImages"
                  :has-internal-add="false"
                  @on-change="onMultipleChange('coverImages', $event)"
                  @add="openPictureDialog('coverImages')"
                />
              </v-col>
            </v-row>
          </v-form>

          <v-card-actions class="actions">
            <v-btn color="primary" outlined @click="goBackToList">
              {{ $t('globals.cancel') }}
            </v-btn>

            <v-btn
              color="primary"
              :loading="discussionGroupIsSaving"
              :disabled="discussionGroupIsSaving"
              @click="save"
            >
              {{ $t('globals.save') }}
            </v-btn>
          </v-card-actions>
        </v-tab-item>

        <v-tab-item value="partners">
          <v-card-text>
            <partner-table
              :search="search"
              :can-edit="canUpdatePartners"
              :can-add="canCreatePartners"
              :can-delete="canDeletePartners"
            />
          </v-card-text>
        </v-tab-item>
      </v-tabs-items>

      <upload-pictures
        :dialog="pictureDialog"
        :height="pictureDialogHeight"
        :width="pictureDialogWidth"
        @save="uploadPictures"
        @close="closePictureDialog"
      />
    </v-container>
  </v-container>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';
import { mapActions, mapGetters } from 'vuex';
import { DateTime } from 'luxon';

import { DiscussionGroup } from '@/models';
import PermissionsUtil from '@/helpers/permissions/permissions.helper';
import validators from '@/helpers/validators/validators';
import VALIDATORS from '@/helpers/forms/validators.helper';

import BaseInputMultiple from '@/components/base-input-multiple/BaseInputMultiple.vue';
import UploadPictures from '@/components/admin/upload-pictures/UploadPictures.vue';
import PartnerTable from '@/views/admin/partners/partner-table/PartnerTable.vue';

import {
  SHARED_FILE_MODULE,
  UPLOAD_FILE,
} from '@/stores/shared/actions/file-upload/file-upload.actions';
import {
  ADMIN_DISCUSSION_GROUP_MODULE,
  CLEAR_DISCUSSION_GROUP,
  SAVE_DISCUSSION_GROUP,
} from '@/stores/umanize-admin/actions/discussion-group/admin-discussion-group.actions';
import { ADMIN_EVENT_MODULE } from '@/stores/umanize-admin/actions/event/admin-event.actions';
import { GET_DISCUSSION_GROUP } from '@/stores/agnostic/actions/discussion-group/agnostic-discussion-group.actions';
import { APP_USER_MODULE } from '@/stores/umanize-app/actions/user/app-user.actions';
import DateTimePicker from '@/components/datetime-picker/DateTimePicker.vue';

const NAME_SIZE_LIMIT = 100;
const DESCRIPTION_SIZE_LIMIT = 120;

export default {
  name: 'EditDiscussionGroupAdmin',
  components: {
    DateTimePicker,
    BaseInputMultiple,
    UploadPictures,
    PartnerTable,
  },
  data: () => ({
    valid: false,
    discussionGroupForm: new DiscussionGroup(),
    defaultDiscussionGroup: new DiscussionGroup(),
    tab: 'discussionGroup',
    search: '',
    startTimeRules: VALIDATORS.REQUIRED.DATE,
    pictureDialog: false,
    pictureDialogHeight: 0,
    pictureDialogWidth: 0,
    pictureDialogType: null,
    pictureDialogConfig: {
      coverImages: {
        height: 480,
        width: 768,
      },
    },
  }),
  computed: {
    ...mapGetters(ADMIN_DISCUSSION_GROUP_MODULE, ['discussionGroup', 'discussionGroupIsSaving']),
    ...mapGetters(APP_USER_MODULE, ['loggedInUserRoles']),
    ...mapGetters(SHARED_FILE_MODULE, ['file']),
    ...mapGetters(ADMIN_EVENT_MODULE, ['event']),
    nameRules() {
      return [validators.required(this.$t('discussion-group.edit.form.name'))];
    },
    eventId() {
      return this.$route?.params?.eventId;
    },
    endTimeRules() {
      if (this.discussionGroupForm.endTime && this.discussionGroupForm.startTime) {
        return [
          ...VALIDATORS.REQUIRED.DATE,
          VALIDATORS.DATE.GREATER_THAN(
            DateTime.fromJSDate(this.discussionGroupForm.startTime),
            this.$t('admin.discussionGroups.editModal.startTime'),
          ),
        ];
      }
      return VALIDATORS.REQUIRED.DATE;
    },
    canUpdate() {
      return PermissionsUtil.isAuthorized(
        ['permission.discussionGroups.canUpdate'],
        this.loggedInUserRoles,
        this.eventId,
      );
    },
    canUpdatePartial() {
      return (
        this.canUpdate ||
        PermissionsUtil.isAuthorized(
          ['permission.discussionGroups.canUpdatePartial'],
          this.loggedInUserRoles,
          this.eventId,
        )
      );
    },
    canUpdatePartners() {
      return PermissionsUtil.isAuthorized(
        ['permission.partners.canUpdate'],
        this.loggedInUserRoles,
        this.eventId,
      );
    },
    canCreatePartners() {
      return PermissionsUtil.isAuthorized(
        ['permission.partners.canCreate'],
        this.loggedInUserRoles,
        this.eventId,
      );
    },
    canDeletePartners() {
      return PermissionsUtil.isAuthorized(
        ['permission.partners.canDelete'],
        this.loggedInUserRoles,
        this.eventId,
      );
    },
  },
  methods: {
    ...mapActions(ADMIN_DISCUSSION_GROUP_MODULE, [
      GET_DISCUSSION_GROUP,
      SAVE_DISCUSSION_GROUP,
      CLEAR_DISCUSSION_GROUP,
    ]),
    ...mapActions(SHARED_FILE_MODULE, [UPLOAD_FILE]),
    initDate(date) {
      return date ? DateTime.fromISO(date).toJSDate() : DateTime.local().toJSDate();
    },
    initForm() {
      this.discussionGroupForm = {
        ...this.defaultDiscussionGroup,
        ...cloneDeep(this.discussionGroup),
        startTime: this.initDate(this.discussionGroup?.startTime),
        endTime: this.initDate(this.discussionGroup?.endTime),
        eventId: this.discussionGroup?.eventId || this.eventId,
      };
    },
    discussionGroupDescription(description) {
      return description.length > DESCRIPTION_SIZE_LIMIT
        ? `${description.slice(0, DESCRIPTION_SIZE_LIMIT)} ...`
        : description;
    },
    discussionGroupName(name) {
      return name.length > NAME_SIZE_LIMIT ? `${name.slice(0, NAME_SIZE_LIMIT)} ...` : name;
    },
    goBackToList() {
      this[CLEAR_DISCUSSION_GROUP]();
      this.$router.back();
    },
    onMultipleChange(key, values) {
      this.conferenceForm[key] = values;
    },
    openPictureDialog(pictureType) {
      this.pictureDialogHeight = this.pictureDialogConfig[pictureType].height;
      this.pictureDialogWidth = this.pictureDialogConfig[pictureType].width;
      this.pictureDialog = true;
      this.pictureDialogType = pictureType;
    },
    closePictureDialog() {
      this.dialogPictureType = null;
      this.pictureDialog = false;
    },
    async uploadPictures(pictures) {
      // eslint-disable-next-line no-restricted-syntax
      for await (const picture of pictures) {
        await this[UPLOAD_FILE](picture);
        this.discussionGroupForm.coverImages = [
          ...(this.discussionGroupForm.coverImages || []),
          this.file.url,
        ];
      }
      this.closePictureDialog();
    },
    async save() {
      if (this.$refs.form && !this.$refs.form.validate()) return;

      await this[SAVE_DISCUSSION_GROUP]({
        eventId: this.eventId,
        discussionGroup: {
          ...this.discussionGroupForm,
          startTime: DateTime.fromJSDate(this.discussionGroupForm.startTime),
          endTime: DateTime.fromJSDate(this.discussionGroupForm.endTime),
        },
      });

      this.goBackToList();
    },
  },
  async mounted() {
    const { discussionGroupId } = this.$route.params;
    if (this.eventId && discussionGroupId) {
      await Promise.all([
        this[GET_DISCUSSION_GROUP]({
          eventId: this.eventId,
          discussionGroupId,
        }),
      ]);
    }
    this.initForm();
  },
  destroyed() {
    this[CLEAR_DISCUSSION_GROUP]();
  },
};
</script>

<style lang="scss" scoped>
@import '../../../../styles/core/variables';
@import '../../../../styles/core/mixins';

@include admin-layout();

.edit-discussion-groups {
  padding: 50px;
  min-height: calc(100vh - 6rem);

  &__title {
    font-size: $x-large-font-size;
    font-weight: $regular;
    margin-bottom: 10px;
  }

  &__content {
    @include admin-card;

    margin: 0;
    padding: 20px 40px;
  }
}

.icon {
  color: var(--v-primary-base);

  &__restricted {
    color: var(--v-error-base);
  }
}
</style>
