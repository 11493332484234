import { GeneralAssembly, Ticket } from '@/models';
import { TicketGAOptions } from '@/models/ticketing/ticket-general-assembly-options';

type TicketGAOptionsWithName = TicketGAOptions & {
  name: string;
};
export const toTicketGAOptionsWithName = (
  ticket: Ticket,
  assemblies: GeneralAssembly[],
): TicketGAOptionsWithName[] => {
  if (!assemblies) {
    return [];
  }

  return assemblies.map((it) => {
    const option = (ticket?.options?.generalAssemblies || []).find((ga) => ga.id === it.id);
    return {
      id: it.id,
      name: it.name,
      canAccess: option ? option.canAccess : false,
      canVote: option ? option.canVote : false,
    };
  });
};
