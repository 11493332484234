<template>
  <v-container class="edit-matching">
    <h2 class="edit-matching__title">{{ event.name }} - {{ pageTitle }}</h2>

    <v-container class="edit-matching__content">
      <v-form v-model="isValid" :disabled="!canUpdate">
        <v-row>
          <v-col cols="12" sm="6">
            <v-textarea
              v-model="questionForm.questions.exhibitor"
              :label="$t('admin.matching.editPage.exhibitorQuestion')"
              :rules="exhibitorQuestion"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-textarea
              v-model="questionForm.questions.visitor"
              :label="$t('admin.matching.editPage.visitorQuestion')"
              :rules="visitorQuestion"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model.number="questionForm.priority"
              :label="$t('admin.matching.editPage.headers.priority')"
              type="number"
              :persistent-placeholder="true"
              placeholder="0"
            />
          </v-col>
        </v-row>

        <v-card-actions class="actions">
          <v-btn color="primary" outlined @click.native="goToMatchingAdmin">
            {{ $t('globals.cancel') }}
          </v-btn>

          <v-btn color="primary" @click.native="save" :disabled="!canUpdate || !isValid">
            {{ $t('globals.save') }}
          </v-btn>
        </v-card-actions>
      </v-form>

      <v-row v-if="questionForm.id">
        <v-col cols="12" sm="12">
          <base-list
            backgroundColor="var(--v-adminBackground-base)"
            :headers="headers"
            :headerProps="{ sortIcon: null }"
            :items="questionForm.answers"
            :itemsPerPage="10"
            :footerProps="{
              itemsPerPageText: $t('globals.datatable.itemPerPage'),
              itemsPerPageAllText: $t('globals.datatable.itemPerPageAll'),
              pageText: $t('globals.datatable.of'),
            }"
            :noDataText="$t('globals.datatable.noData')"
            :noResultsText="$t('globals.datatable.noResult')"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title class="answers-list__title">
                  {{ $t('admin.matching.editPage.possibleAnswers') }}
                </v-toolbar-title>
              </v-toolbar>

              <div class="answers-list__actions">
                <v-btn
                  color="primary"
                  large
                  depressed
                  class="actions__create"
                  :disabled="!canUpdate"
                  @click="openAnswer()"
                >
                  {{ $t('admin.matching.editPage.addAnswer') }}
                  <v-icon right>mdi-plus</v-icon>
                </v-btn>
              </div>

              <v-dialog v-model="dialog" max-width="500px" @click:outside="closeAnswer()">
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ modalTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-form v-model="answerIsValid" :disabled="!canUpdate">
                      <v-row>
                        <v-col cols="12" sm="12">
                          <v-text-field
                            v-model="editedAnswer.answer"
                            :label="$t('admin.matching.editPage.headers.answer')"
                            :rules="answerRules"
                            hide-details="auto"
                            :persistent-placeholder="true"
                            placeholder="-"
                          />
                        </v-col>

                        <v-col cols="12" sm="12">
                          <v-text-field
                            v-model.number="editedAnswer.priority"
                            :label="$t('admin.matching.editPage.headers.priority')"
                            type="number"
                            hide-details="auto"
                            :persistent-placeholder="true"
                            placeholder="0"
                          />
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>

                  <v-card-actions class="actions">
                    <v-btn color="primary" outlined @click="closeAnswer">
                      {{ $t('globals.cancel') }}
                    </v-btn>

                    <v-btn
                      color="primary"
                      @click="saveAnswer"
                      :disabled="!canUpdate || !answerIsValid"
                    >
                      {{ $t('globals.save') }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <admin-table-actions
                @edit-item="editQuestionAnswer(item)"
                :can-edit="canUpdate"
                @delete-item="deleteQuestionAnswer(item)"
                :can-delete="canDeletePartial"
                :is-disabled="!canDelete(item)"
                :disabled-tooltip="$t('admin.matching.answer.delete.hasUserAnswers')"
              />
            </template>
          </base-list>
        </v-col>
      </v-row>
    </v-container>

    <dialog-confirmation
      :visible="showConfirmation"
      :title="$t('admin.matching.answer.delete.deleteConfirmationDialog.title')"
      :content="$t('admin.matching.answer.delete.deleteConfirmationDialog.content')"
      @cancel="confirmationDialogCancel"
      @confirm="confirmationDialogConfirm"
    />
  </v-container>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';
import { mapActions, mapGetters } from 'vuex';

import { MatchingQuestion } from '@/models';
import PermissionsUtil from '@/helpers/permissions/permissions.helper';
import AdminTableActions from '@/components/admin/admin-table-actions/AdminTableActions.vue';
import BaseList from '@/components/base-list/BaseList.vue';

import {
  ADMIN_MATCHING_MODULE,
  CLEAR_MATCHING_QUESTION,
  DELETE_MATCHING_ANSWER,
  SAVE_MATCHING_ANSWER,
  SAVE_MATCHING_QUESTION,
} from '@/stores/umanize-admin/actions/matching/admin-matching.actions';
import { ADMIN_EVENT_MODULE } from '@/stores/umanize-admin/actions/event/admin-event.actions';
import { GET_MATCHING_QUESTION } from '@/stores/agnostic/actions/matching/matching.actions';
import { APP_USER_MODULE } from '@/stores/umanize-app/actions/user/app-user.actions';
import DialogConfirmation from '@/views/DialogConfirmation.vue';
import validatorsHelper from '@/helpers/forms/validators.helper';

export default {
  name: 'EditMatchingAdmin',
  components: {
    DialogConfirmation,
    AdminTableActions,
    BaseList,
  },
  data: () => ({
    isValid: false,
    answerIsValid: false,
    showConfirmation: false,
    answerToDelete: null,
    defaultQuestion: new MatchingQuestion({}),
    questionForm: new MatchingQuestion({}),
    dialog: false,
    editedIndex: -1,
    defaultAnswer: {
      answer: '',
    },
    editedAnswer: {
      answer: '',
    },
  }),
  computed: {
    ...mapGetters(ADMIN_MATCHING_MODULE, ['matchingQuestion', 'questionIsLoading']),
    ...mapGetters(ADMIN_EVENT_MODULE, ['event']),
    ...mapGetters(APP_USER_MODULE, ['loggedInUserRoles']),
    headers() {
      return [
        {
          text: this.$t('admin.matching.editPage.headers.answer'),
          value: 'answer',
        },
        {
          text: this.$t('admin.matching.editPage.headers.priority'),
          value: 'priority',
        },
        {
          value: 'actions',
          width: '100px',
        },
      ];
    },
    answerRules() {
      return [
        validatorsHelper.REQUIRED.default(this.$t('admin.matching.editPage.errors.answerRequired')),
      ];
    },
    visitorQuestion() {
      return [
        validatorsHelper.REQUIRED.default(
          this.$t('admin.matching.editPage.errors.visitorQuestionRequired'),
        ),
      ];
    },
    exhibitorQuestion() {
      return [
        validatorsHelper.REQUIRED.default(
          this.$t('admin.matching.editPage.errors.exhibitorQuestionRequired'),
        ),
      ];
    },
    eventId() {
      return this.$route.params.eventId;
    },
    pageTitle() {
      return this.$t(`admin.matching.title.${this.$route.meta.modalType || 'create'}`);
    },
    modalTitle() {
      return this.editedAnswer.answer
        ? this.$t('admin.matching.editPage.editAnswer')
        : this.$t('admin.matching.editPage.addAnswer');
    },
    canUpdate() {
      return PermissionsUtil.isAuthorized(
        ['permission.smartMatchings.canUpdate'],
        this.loggedInUserRoles,
        this.eventId,
      );
    },
    canDeletePartial() {
      return PermissionsUtil.isAuthorized(
        ['permission.smartMatchings.canDeletePartial'],
        this.loggedInUserRoles,
        this.eventId,
      );
    },
  },
  methods: {
    ...mapActions(ADMIN_MATCHING_MODULE, [
      GET_MATCHING_QUESTION,
      SAVE_MATCHING_QUESTION,
      CLEAR_MATCHING_QUESTION,
      SAVE_MATCHING_ANSWER,
      DELETE_MATCHING_ANSWER,
    ]),
    initFormFromQuestion() {
      if (this.matchingQuestion) {
        this.questionForm = {
          ...this.defaultQuestion,
          ...cloneDeep(this.matchingQuestion),
          eventId: this.eventId,
        };

        this.initAnswer(this.defaultAnswer);
      }
    },
    initAnswer(item) {
      this.editedAnswer = {
        ...this.defaultAnswer,
        questionId: this.$route.params.questionId,
        eventId: this.eventId,
        ...item,
      };
    },
    editQuestionAnswer(item) {
      this.editedIndex = this.questionForm.answers.findIndex((element) => element.id === item.id);
      this.initAnswer(item);
      this.openAnswer();
    },
    deleteQuestionAnswer(item) {
      this.showConfirmation = true;
      this.answerToDelete = { ...item };
    },
    canDelete(item) {
      return (
        PermissionsUtil.isAuthorized(
          ['permission.smartMatchings.canDelete'],
          this.loggedInUserRoles,
          this.eventId,
        ) || !item.hasUserAnswers
      );
    },
    async saveAnswer() {
      await this[SAVE_MATCHING_ANSWER](this.editedAnswer);
      await this.initialize();
      this.dialog = false;
    },
    openAnswer() {
      this.dialog = true;
    },
    closeAnswer() {
      this.dialog = false;
      this.initAnswer();
    },
    goToEditMatchingAdmin() {
      this.$router.replace({
        name: 'EditMatchingAdmin',
        params: {
          ...this.$route.params,
          questionId: this.matchingQuestion.id,
        },
      });
    },
    async goToMatchingAdmin() {
      await this[CLEAR_MATCHING_QUESTION]();
      this.$router.replace({
        name: 'MatchingAdmin',
        params: {
          ...this.$route.params,
        },
      });
    },
    async save() {
      await this[SAVE_MATCHING_QUESTION]({ ...this.questionForm, eventId: this.eventId });

      if (!this.questionForm.id) {
        this.goToEditMatchingAdmin();
      } else {
        await this.goToMatchingAdmin();
      }
    },
    async initialize() {
      const { questionId } = this.$route.params;
      if (questionId) {
        await this[GET_MATCHING_QUESTION]({
          eventId: this.eventId,
          questionId,
        });
      }

      this.initFormFromQuestion();
    },
    confirmationDialogCancel() {
      this.showConfirmation = false;
      this.answerToDelete = null;
    },
    async confirmationDialogConfirm() {
      await this[DELETE_MATCHING_ANSWER](this.answerToDelete);
      await this.initialize();
      this.answerToDelete = null;
      this.showConfirmation = false;
    },
  },
  mounted() {
    this.initialize();
  },
  watch: {
    $route() {
      this.initFormFromQuestion();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../../styles/core/variables';
@import '../../../../styles/core/mixins';

@include admin-layout();

.edit-matching {
  padding: 50px;
  min-height: calc(100vh - 6rem);

  &__title {
    font-size: $x-large-font-size;
    font-weight: $regular;
    margin-bottom: 10px;
  }

  &__content {
    @include admin-card;

    margin: 0;
    padding: 20px 40px;
  }
}
</style>
