var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"content-libraries"}},[_c('h2',{staticClass:"content-libraries__title"},[_vm._v(" "+_vm._s(_vm.event.name)+" - "+_vm._s(_vm.$t('contentLibraries.list.title'))+" ")]),_c('div',{staticClass:"content-libraries__actions"},[_c('v-btn',{staticClass:"actions__create",attrs:{"large":"","depressed":"","color":"primary","disabled":!_vm.canCreate,"to":("/events/" + (_vm.event.id) + "/admin/content-libraries/add")}},[_vm._v(" "+_vm._s(_vm.$t('contentLibraries.list.add'))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-plus")])],1),_c('AppSearch',{staticClass:"actions__search",on:{"change":_vm.onSearchChange}})],1),_c('base-list',{staticClass:"mt-4",attrs:{"className":"img-first","backgroundColor":"var(--v-adminBackground-base)","headers":_vm.headers,"items":_vm.contentCategories,"search":_vm.search,"loading":_vm.categoriesAreLoading,"footerProps":{
      itemsPerPageText: _vm.$t('globals.datatable.itemPerPage'),
      itemsPerPageAllText: _vm.$t('globals.datatable.itemPerPageAll'),
      pageText: _vm.$t('globals.datatable.of'),
    },"noDataText":_vm.$t('globals.datatable.noData'),"noResultsText":_vm.$t('globals.datatable.noResult')},scopedSlots:_vm._u([{key:"item.coverImage",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"container-img"},[_c('img',{staticClass:"container-img__img",attrs:{"src":item.coverImage}})])]}},{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_c('p',{staticClass:"truncate"},[_vm._v(_vm._s(item.name))])]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('admin-table-actions',{attrs:{"user":_vm.currentLoggedInUser,"can-edit":_vm.canUpdate,"can-delete":_vm.canDelete,"is-disabled":_vm.hasContent(item),"disabled-tooltip":_vm.$t('contentLibraries.delete.hasContent')},on:{"edit-item":function($event){return _vm.editContentLibrary(item)},"delete-item":function($event){return _vm.confirmDeleteContentLibrary(item)}}}),_c('dialog-confirmation',{attrs:{"v-if":_vm.categoriesToDelete,"visible":_vm.showConfirmation,"title":_vm.$t('contentLibraries.delete.deleteConfirmationDialog.title'),"content":_vm.$t('contentLibraries.delete.deleteConfirmationDialog.content')},on:{"cancel":_vm.confirmationDialogCancel,"confirm":_vm.confirmationDialogConfirm}})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }