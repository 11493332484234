var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-list',{staticClass:"mt-4",attrs:{"className":"img-first","backgroundColor":"var(--v-adminBackground-base)","headers":_vm.headers,"items":_vm.conferences,"search":_vm.search,"loading":_vm.conferencesAreLoading,"footerProps":{
      itemsPerPageText: _vm.$t('globals.datatable.itemPerPage'),
      itemsPerPageAllText: _vm.$t('globals.datatable.itemPerPageAll'),
      pageText: _vm.$t('globals.datatable.of'),
    },"noDataText":_vm.$t('globals.datatable.noData'),"noResultsText":_vm.$t('globals.datatable.noResult')},scopedSlots:_vm._u([{key:"item.coverImage",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"container-img"},[(item.coverImages)?_c('img',{staticClass:"container-img__img",attrs:{"src":item.coverImages[0]}}):_c('p',{staticClass:"container-img__placeholder"},[_vm._v(" "+_vm._s(_vm.$t('event.edit.noCover'))+" ")])])]}},{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.conferenceName(item.name))+" ")]}},{key:"item.description",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.conferenceDescription(item.description))+" ")]}},{key:"item.startTime",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$d(Date.parse(item.startTime), 'long'))+" ")]}},{key:"item.endTime",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$d(Date.parse(item.endTime), 'long'))+" ")]}},{key:"item.public",fn:function(ref){
    var item = ref.item;
return [(!item.public)?_c('v-icon',{staticClass:"icon icon__restricted"},[_vm._v(" mdi-lock-outline ")]):_c('v-icon',{staticClass:"icon"},[_vm._v("mdi-lock-open-outline")])]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('admin-table-actions',{attrs:{"user":_vm.user,"is-disabled":_vm.hasPartners(item),"disabled-tooltip":_vm.$t('conference.delete.hasContent'),"can-edit":_vm.canEdit,"can-delete":_vm.canDelete},on:{"edit-item":function($event){return _vm.editConference(item)},"delete-item":function($event){return _vm.deleteConference(item)}}})]}}],null,true),model:{value:(_vm.dataSelectedConferences),callback:function ($$v) {_vm.dataSelectedConferences=$$v},expression:"dataSelectedConferences"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }