<template>
  <div class="event-list">
    <h2 class="event-list__title" data-test-id="title">{{ $t('event.list.title') }}</h2>

    <div class="event-list__actions">
      <v-btn
        class="actions__create"
        large
        depressed
        color="primary"
        @click="addEvent()"
        :disabled="!canCreate"
        data-test-id="add-btn"
      >
        {{ $t('globals.add') }}
        <v-icon>mdi-plus</v-icon>
      </v-btn>

      <AppSearch class="actions__search" @change="onSearchChange" />
    </div>

    <v-tabs v-model="eventListActiveTab" class="mt-4" background-color="secondaryBackground">
      <v-tab @click="handleActiveTab('activeEvent')" href="#activeEvent">
        {{ $t('event.list.tabs.active') }}
      </v-tab>

      <v-tab @click="handleActiveTab('pastEvent')" href="#pastEvent" data-test-id="past-event">
        {{ $t('event.list.tabs.past') }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="eventListActiveTab">
      <v-tab-item value="activeEvent">
        <base-list
          class="mt-4"
          className="img-first"
          backgroundColor="var(--v-adminBackground-base)"
          :items="activeEvents"
          :loading="myEventsAreLoading"
          :headerProps="{ sortIcon: null }"
          :headers="headers"
          :search="search"
          :footerProps="{
            itemsPerPageText: $t('globals.datatable.itemPerPage'),
            itemsPerPageAllText: $t('globals.datatable.itemPerPageAll'),
            pageText: $t('globals.datatable.of'),
          }"
          :noDataText="$t('globals.datatable.noData')"
          :noResultsText="$t('globals.datatable.noResult')"
          :isSortDisabled="false"
          :itemPerPage="5"
        >
          <template v-slot:[`item.coverImage`]="{ item }">
            <div class="container-img">
              <img :src="item.coverImages[0]" class="container-img__img" v-if="item.coverImages" />
              <p class="container-img__placeholder" v-else>
                {{ $t('event.edit.noCover') }}
              </p>
            </div>
          </template>

          <template v-slot:[`item.name`]="{ item }">
            {{ item.name }}
          </template>

          <template v-slot:[`item.startDate`]="{ item }">
            {{ $d(Date.parse(item.startDate), 'long') }}
          </template>

          <template v-slot:[`item.endDate`]="{ item }">
            {{ $d(Date.parse(item.endDate), 'long') }}
          </template>

          <template v-slot:[`item.public`]="{ item }">
            <v-icon class="icon icon__restricted" v-if="!item.public"> mdi-lock-outline </v-icon>
            <v-icon class="icon" v-else>mdi-lock-open-outline</v-icon>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <admin-table-actions
              :user="loggedInUser"
              :can-edit="true"
              :has-delete="false"
              @edit-item="editEvent(item)"
            />
          </template>
        </base-list>
      </v-tab-item>

      <v-tab-item value="pastEvent">
        <base-list
          class="mt-4"
          className="img-first"
          backgroundColor="var(--v-adminBackground-base)"
          :items="pastEvents"
          :loading="myEventsAreLoading"
          :headerProps="{ sortIcon: null }"
          :headers="headers"
          :search="search"
          :footerProps="{
            itemsPerPageText: $t('globals.datatable.itemPerPage'),
            itemsPerPageAllText: $t('globals.datatable.itemPerPageAll'),
            pageText: $t('globals.datatable.of'),
          }"
          :noDataText="$t('globals.datatable.noData')"
          :noResultsText="$t('globals.datatable.noResult')"
          :isSortDisabled="false"
          :itemPerPage="5"
        >
          <template v-slot:[`item.coverImage`]="{ item }">
            <div class="container-img">
              <img :src="item.coverImages[0]" class="container-img__img" v-if="item.coverImages" />
              <p class="container-img__placeholder" v-else>
                {{ $t('event.edit.noCover') }}
              </p>
            </div>
          </template>

          <template v-slot:[`item.name`]="{ item }">
            {{ item.name }}
          </template>

          <template v-slot:[`item.startDate`]="{ item }">
            {{ $d(Date.parse(item.startDate), 'long') }}
          </template>

          <template v-slot:[`item.endDate`]="{ item }">
            {{ $d(Date.parse(item.endDate), 'long') }}
          </template>

          <template v-slot:[`item.public`]="{ item }">
            <v-icon class="icon icon__restricted" v-if="!item.public"> mdi-lock-outline </v-icon>
            <v-icon class="icon" v-else>mdi-lock-open-outline</v-icon>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <admin-table-actions
              :user="loggedInUser"
              :can-edit="true"
              :has-delete="false"
              @edit-item="editEvent(item)"
            />
          </template>
        </base-list>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import AdminTableActions from '@/components/admin/admin-table-actions/AdminTableActions.vue';
import BaseList from '@/components/base-list/BaseList.vue';
import AppSearch from '@/components/app/app-search/AppSearch.vue';

import { FETCH_MY_EVENTS } from '@/stores/agnostic/actions/event/agnostic-event.actions';
import { ADMIN_EVENT_MODULE } from '@/stores/umanize-admin/actions/event/admin-event.actions';
import { APP_USER_MODULE } from '@/stores/umanize-app/actions/user/app-user.actions';

import PermissionsUtil from '@/helpers/permissions/permissions.helper';
import DateUtil from '@/helpers/date/date.helper';

export default {
  name: 'EventListAdmin',
  components: {
    AdminTableActions,
    BaseList,
    AppSearch,
  },
  data: () => ({
    search: '',
    eventListActiveTab: 'activeEvent',
  }),
  computed: {
    ...mapGetters(ADMIN_EVENT_MODULE, ['myAdminEvents', 'myEventsAreLoading']),
    ...mapGetters(APP_USER_MODULE, ['loggedInUser', 'loggedInUserRoles']),
    headers() {
      return [
        {
          value: 'coverImage',
          width: '200px',
        },
        {
          text: this.$t('event.edit.name'),
          value: 'name',
        },
        {
          text: this.$t('event.edit.startDate'),
          value: 'startDate',
          width: '180px',
        },
        {
          text: this.$t('event.edit.endDate'),
          value: 'endDate',
          width: '180px',
        },
        {
          text: this.$t('event.edit.public'),
          value: 'public',
          align: 'center',
          width: '100px',
        },
        {
          value: 'actions',
          align: 'center',
          width: '100px',
        },
      ];
    },
    canCreate() {
      return PermissionsUtil.isAuthorized(['permission.events.canCreate'], this.loggedInUserRoles);
    },
    pastEvents() {
      return this.myAdminEvents.filter((event) => DateUtil.isBefore(event.endDate));
    },
    activeEvents() {
      return this.myAdminEvents.filter((event) => DateUtil.isNowOrAfter(event.endDate));
    },
  },
  methods: {
    ...mapActions(ADMIN_EVENT_MODULE, [FETCH_MY_EVENTS]),
    onSearchChange(search) {
      this.search = search;
    },
    handleActiveTab(tab) {
      this.$router.replace({ hash: `#${tab}` });
    },
    editEvent(item) {
      this.$router.push({
        name: 'EventDetailsAdmin',
        params: {
          eventId: item.id,
        },
      });
    },
    addEvent() {
      this.$router.push({
        name: 'AddEventAdmin',
      });
    },
    loadEvents() {
      this[FETCH_MY_EVENTS]();
    },
  },
  mounted() {
    this.loadEvents();
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles/core/variables';
@import '../../styles/core/mixins';

@include admin-layout();

.event-list {
  &__title {
    margin: 30px 0 10px;
  }

  &__actions {
    display: flex;
    align-items: center;

    & > *:not(:last-child) {
      margin-right: 10px;
    }
  }
}

.icon {
  color: var(--v-primary-base);

  &__restricted {
    color: var(--v-error-base);
  }
}

.container-img {
  display: flex;
  justify-content: center;
  align-items: center;

  &__img {
    width: 100%;
    height: 100%;
  }

  &__placeholder {
    max-width: 100px;
    text-align: center;
    margin: 0;
  }
}

.theme--light.v-tabs-items {
  background-color: var(--v-secondaryBackground-base) !important;
}

@include breakpoint(medium) {
  .container-img {
    width: 200px;
    height: 100px;
    border-radius: 10px 0 0 10px;
    border-right: 1px solid var(--v-gray-base);

    &__img {
      border-radius: 10px 0 0 10px;
    }
  }
}
</style>
