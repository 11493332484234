<template>
  <v-card elevation="0" class="password">
    <v-form
      v-if="hasUser"
      class="mt-8"
      ref="passwordForm"
      v-model="valid"
      :disabled="!canUpdatePassword"
      @submit.prevent
      @submit="updatePassword"
    >
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="passwordForm.password"
              :label="$t('auth.profile.password')"
              :rules="passwordRules"
              type="password"
              required
              autocomplete
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              v-model="passwordForm.passwordAgain"
              :label="$t('auth.profile.passwordAgain')"
              type="password"
              autocomplete
              required
              clearable
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions :class="{ 'justify-end': asAdmin, 'justify-center': !asAdmin }">
        <v-btn
          v-if="asAdmin"
          class="mx-2"
          color="primary"
          outlined
          @click="$emit('cancel')"
          data-test-id="cancel"
        >
          {{ $t('globals.cancel') }}
        </v-btn>

        <v-btn color="primary" type="submit" :disabled="isLoading || !valid || !samePassword">
          {{ $t('auth.profile.send') }}
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import VALIDATORS from '@/helpers/forms/validators.helper';

export default {
  name: 'PasswordForm',
  props: {
    user: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    canUpdatePassword: {
      type: Boolean,
      default: false,
    },
    asAdmin: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    passwordForm: {
      password: '',
      passwordAgain: '',
    },
    passwordRules: VALIDATORS.PASSWORD,
    valid: false,
  }),
  computed: {
    samePassword() {
      const { password, passwordAgain } = this.passwordForm;

      return password && password === passwordAgain;
    },
    hasUser() {
      return !!this.user?.id;
    },
  },
  methods: {
    updatePassword() {
      this.$emit('update-password', this.passwordForm);
      this.$refs.passwordForm.reset();
    },
  },
};
</script>

<style lang="scss" scoped>
.password {
  width: 100%;
}

.v-card {
  &__text {
    padding: 0 8px 0 0;
  }
}
</style>
