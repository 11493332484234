<template>
  <v-sheet light>
    <div class="stepper-content__colors">
      <div>
        <v-label>{{ $t('kioskBuilder.edit.kiosk-builder.zoneFirstColor') }}</v-label>
        <v-color-picker
          v-model="options.zoneFirstColor"
          dot-size="10"
          canvas-height="50"
          hide-mode-switch
          mode="hexa"
          :disabled="isUnityLoadingOrCantUpdate"
          @update:color="setFirstColor"
          data-test-id="first-color"
        />
      </div>

      <div>
        <v-label>{{ $t('kioskBuilder.edit.kiosk-builder.zoneSecondColor') }}</v-label>
        <v-color-picker
          v-model="options.zoneSecondColor"
          dot-size="10"
          canvas-height="50"
          hide-mode-switch
          mode="hexa"
          :disabled="isUnityLoadingOrCantUpdate"
          @update:color="setSecondColor"
          data-test-id="second-color"
        />
      </div>

      <div>
        <v-label>{{ $t('kioskBuilder.edit.kiosk-builder.zoneThirdColor') }}</v-label>
        <v-color-picker
          v-model="options.zoneThirdColor"
          dot-size="10"
          canvas-height="50"
          hide-mode-switch
          mode="hexa"
          :disabled="isUnityLoadingOrCantUpdate"
          @update:color="setThirdColor"
          data-test-id="third-color"
        />
      </div>
    </div>
  </v-sheet>
</template>

<script>
export default {
  name: 'KioskColorsStep',
  props: {
    kioskOptions: {
      type: Object,
      required: true,
    },
    isUnityLoading: {
      type: Boolean,
      required: true,
      default: true,
    },
  },
  computed: {
    canUpdate() {
      return !this.kioskOptions?.isSubmitted;
    },
    isUnityLoadingOrCantUpdate() {
      return !this.canUpdate || this.isUnityLoading;
    },
    options: {
      get() {
        return this.kioskOptions;
      },
    },
  },
  methods: {
    setFirstColor(color) {
      this.$emit('set-first-color', color.hex);
    },
    setSecondColor(color) {
      this.$emit('set-second-color', color.hex);
    },
    setThirdColor(color) {
      this.$emit('set-third-color', color.hex);
    },
  },
};
</script>
