var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-list',{staticClass:"mt-4",attrs:{"backgroundColor":"var(--v-adminBackground-base)","showSelect":_vm.showSelect,"headers":_vm.headers,"items":_vm.discussionGroups,"search":_vm.search,"loading":_vm.discussionGroupsAreLoading,"itemsPerPage":10,"footerProps":{
      itemsPerPageText: _vm.$t('globals.datatable.itemPerPage'),
      itemsPerPageAllText: _vm.$t('globals.datatable.itemPerPageAll'),
      pageText: _vm.$t('globals.datatable.of'),
    },"noDataText":_vm.$t('globals.datatable.noData'),"noResultsText":_vm.$t('globals.datatable.noResult')},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.discussionGroupName(item.name))+" ")]}},{key:"item.description",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.discussionGroupDescription(item.description))+" ")]}},{key:"item.startTime",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$d(Date.parse(item.startTime), 'long'))+" ")]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('admin-table-actions',{attrs:{"user":_vm.user,"can-edit":_vm.canEdit,"can-delete":_vm.canDelete,"is-disabled":_vm.hasPartners(item.id),"disabled-tooltip":_vm.$t('admin.discussionGroups.delete.hasContent')},on:{"edit-item":function($event){return _vm.editDiscussionGroup(item)},"delete-item":function($event){return _vm.deleteDiscussionGroup(item)}}})]}}],null,true),model:{value:(_vm.dataSelectedDiscussionGroups),callback:function ($$v) {_vm.dataSelectedDiscussionGroups=$$v},expression:"dataSelectedDiscussionGroups"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }