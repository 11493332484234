<template>
  <v-container class="roles">
    <h2 class="roles__title">{{ $t('admin.roles.listTitle') }}</h2>

    <div class="roles__actions">
      <v-btn
        class="actions__create"
        color="primary"
        @click="addRole()"
        :disabled="!canCreate"
        large
        depressed
      >
        {{ $t('globals.add') }}
        <v-icon right>mdi-plus</v-icon>
      </v-btn>

      <AppSearch class="actions__search" @change="onSearchChange" />
    </div>

    <base-list
      class="mt-4"
      backgroundColor="var(--v-adminBackground-base)"
      :headers="headers"
      :items="roles"
      :search="search"
      :loading="rolesAreLoading"
      :footerProps="{
        itemsPerPageText: $t('globals.datatable.itemPerPage'),
        itemsPerPageAllText: $t('globals.datatable.itemPerPageAll'),
        pageText: $t('globals.datatable.of'),
      }"
      :noDataText="$t('globals.datatable.noData')"
      :noResultsText="$t('globals.datatable.noResult')"
    >
      <template v-slot:[`item.name`]="{ item }">
        {{ item.name }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <admin-table-actions
          user="currentLoggedInUser"
          :canEdit="canUpdateRoles"
          :has-delete="false"
          @edit-item="editRoleItem(item)"
        />
      </template>
    </base-list>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import AdminTableActions from '@/components/admin/admin-table-actions/AdminTableActions.vue';
import BaseList from '@/components/base-list/BaseList.vue';
import AppSearch from '@/components/app/app-search/AppSearch.vue';
import PermissionsUtil from '@/helpers/permissions/permissions.helper';

import { GET_ROLES, ROLES_MODULE } from '@/stores/umanize-admin/actions/roles/roles.actions';
import { APP_USER_MODULE } from '@/stores/umanize-app/actions/user/app-user.actions';

export default {
  name: 'RolesAdmin',
  components: {
    AdminTableActions,
    BaseList,
    AppSearch,
  },
  data: () => ({
    search: '',
  }),
  computed: {
    ...mapGetters(ROLES_MODULE, ['roles', 'rolesAreLoading']),
    ...mapGetters(APP_USER_MODULE, ['currentLoggedInUser', 'loggedInUserRoles']),
    headers() {
      return [
        {
          text: this.$t('admin.roles.name'),
          value: 'name',
          sortable: false,
        },
        {
          value: 'actions',
          align: 'center',
          width: '75px',
        },
      ];
    },
    canUpdateRoles() {
      return PermissionsUtil.isAuthorized(['permission.roles.canUpdate'], this.loggedInUserRoles);
    },
    canCreate() {
      return PermissionsUtil.isAuthorized(['permission.roles.canCreate'], this.loggedInUserRoles);
    },
  },
  methods: {
    ...mapActions(ROLES_MODULE, [GET_ROLES]),
    onSearchChange(search) {
      this.search = search;
    },
    editRoleItem(item) {
      this.$router.push({
        name: 'EditRoleAdmin',
        params: {
          roleId: item.id,
        },
      });
    },
    addRole() {
      this.$router.push({
        name: 'AddRoleAdmin',
      });
    },
  },
  async mounted() {
    await this[GET_ROLES]();
  },
};
</script>

<style lang="scss" scoped>
@import '../../../styles/core/variables';
@import '../../../styles/core/mixins';

@include admin-layout();

.roles {
  &__title {
    margin: 30px 0 10px;
  }

  &__actions {
    display: flex;
    align-items: center;

    & > *:not(:last-child) {
      margin-right: 10px;
    }
  }
}

.icon {
  color: var(--v-primary-base);

  &__restricted {
    color: var(--v-error-base);
  }
}
</style>
