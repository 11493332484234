var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"tickets"}},[_c('h2',{staticClass:"tickets-list__title"},[_vm._v(" "+_vm._s(_vm.$t('admin.tickets.title', { eventName: _vm.event.name }))+" ")]),_c('div',{staticClass:"tickets-list__actions"},[_c('v-btn',{staticClass:"actions__create",attrs:{"large":"","color":"primary","disabled":!_vm.canCreateTickets},on:{"click":function($event){return _vm.addTicket()}}},[_vm._v(" "+_vm._s(_vm.$t('globals.add'))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-plus")])],1)],1),_c('base-list',{staticClass:"mt-4",attrs:{"backgroundColor":"var(--v-adminBackground-base)","headers":_vm.headers,"headerProps":{ sortIcon: null },"items":_vm.tickets,"itemsPerPage":10,"loading":!_vm.isLoaded,"footerProps":{
      itemsPerPageText: _vm.$t('globals.datatable.itemPerPage'),
      itemsPerPageAllText: _vm.$t('globals.datatable.itemPerPageAll'),
      pageText: _vm.$t('globals.datatable.of'),
    },"noDataText":_vm.$t('globals.datatable.noData'),"noResultsText":_vm.$t('globals.datatable.noResult')},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.restricted",fn:function(ref){
    var item = ref.item;
return [(item.restricted)?_c('v-icon',{staticClass:"icon icon__restricted"},[_vm._v(" mdi-lock-outline ")]):_c('v-icon',{staticClass:"icon"},[_vm._v("mdi-lock-open-outline")])]}},{key:"item.conferences",fn:function(ref){
    var item = ref.item;
return [(_vm.allConferencesAreAuthorized(item))?_c('v-icon',{staticClass:"icon"},[_vm._v(" mdi-account-voice ")]):(_vm.someConferencesAreAuthorize(item))?_c('v-icon',{staticClass:"icon icon__some"},[_vm._v(" mdi-account-voice ")]):_c('v-icon',{staticClass:"icon icon__none"},[_vm._v("mdi-account-voice")])]}},{key:"item.discussionGroups",fn:function(ref){
    var item = ref.item;
return [(_vm.allDiscussionGroupsAreAuthorized(item))?_c('v-icon',{staticClass:"icon"},[_vm._v(" mdi-bullhorn ")]):(_vm.someDiscussionGroupsAreAuthorize(item))?_c('v-icon',{staticClass:"icon icon__some"},[_vm._v(" mdi-bullhorn ")]):_c('v-icon',{staticClass:"icon icon__none"},[_vm._v("mdi-bullhorn")])]}},{key:"item.showroom",fn:function(ref){
    var item = ref.item;
return [(_vm.isShowroomAuthorized(item))?_c('v-icon',{staticClass:"icon"},[_vm._v(" mdi-briefcase-account ")]):_c('v-icon',{staticClass:"icon icon__restricted"},[_vm._v("mdi-briefcase-account")])]}},{key:"item.price",fn:function(ref){
    var item = ref.item;
return [_c('base-price',{attrs:{"amount":item.price,"currency":item.currency}})]}},{key:"item.type",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$t(("admin.tickets.type." + (item.type)))))])]}},{key:"item.roles",fn:function(ref){
    var item = ref.item;
return [_vm._l((_vm.computedRoles(item)),function(role,index){return _c('span',{key:role},[_vm._v(" "+_vm._s(_vm.$t(("admin.tickets.type." + role)))+" "),(index !== _vm.computedRoles(item).length - 1)?_c('span',[_vm._v(" / ")]):_vm._e()])}),(_vm.computedRoles(item).length === 0)?_c('span',[_vm._v(" - ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('admin-table-actions',{attrs:{"user":_vm.currentLoggedInUser,"hasDelete":false,"canEdit":_vm.canUpdateTicket},on:{"edit-item":function($event){return _vm.editTicket(item)}}})]}}],null,true)}),(_vm.dialog.ticket)?_c('v-dialog',{attrs:{"content-class":"v-dialog--overlay","width":"800"},on:{"click:outside":_vm.closeDialog},model:{value:(_vm.dialog.ticket),callback:function ($$v) {_vm.$set(_vm.dialog, "ticket", $$v)},expression:"dialog.ticket"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.event.name)+" - "+_vm._s(_vm.$t(("admin.tickets.editModal.title." + _vm.modalTitle)))+" ")]),_c('v-card-text',[_c('edit-ticket-admin',{attrs:{"type":_vm.modalTitle,"eventId":_vm.eventId,"ticketId":_vm.selectedTicketId},on:{"closeDialog":_vm.closeDialog}})],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }