var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"event-list"},[_c('h2',{staticClass:"event-list__title",attrs:{"data-test-id":"title"}},[_vm._v(_vm._s(_vm.$t('event.list.title')))]),_c('div',{staticClass:"event-list__actions"},[_c('v-btn',{staticClass:"actions__create",attrs:{"large":"","depressed":"","color":"primary","disabled":!_vm.canCreate,"data-test-id":"add-btn"},on:{"click":function($event){return _vm.addEvent()}}},[_vm._v(" "+_vm._s(_vm.$t('globals.add'))+" "),_c('v-icon',[_vm._v("mdi-plus")])],1),_c('AppSearch',{staticClass:"actions__search",on:{"change":_vm.onSearchChange}})],1),_c('v-tabs',{staticClass:"mt-4",attrs:{"background-color":"secondaryBackground"},model:{value:(_vm.eventListActiveTab),callback:function ($$v) {_vm.eventListActiveTab=$$v},expression:"eventListActiveTab"}},[_c('v-tab',{attrs:{"href":"#activeEvent"},on:{"click":function($event){return _vm.handleActiveTab('activeEvent')}}},[_vm._v(" "+_vm._s(_vm.$t('event.list.tabs.active'))+" ")]),_c('v-tab',{attrs:{"href":"#pastEvent","data-test-id":"past-event"},on:{"click":function($event){return _vm.handleActiveTab('pastEvent')}}},[_vm._v(" "+_vm._s(_vm.$t('event.list.tabs.past'))+" ")])],1),_c('v-tabs-items',{model:{value:(_vm.eventListActiveTab),callback:function ($$v) {_vm.eventListActiveTab=$$v},expression:"eventListActiveTab"}},[_c('v-tab-item',{attrs:{"value":"activeEvent"}},[_c('base-list',{staticClass:"mt-4",attrs:{"className":"img-first","backgroundColor":"var(--v-adminBackground-base)","items":_vm.activeEvents,"loading":_vm.myEventsAreLoading,"headerProps":{ sortIcon: null },"headers":_vm.headers,"search":_vm.search,"footerProps":{
          itemsPerPageText: _vm.$t('globals.datatable.itemPerPage'),
          itemsPerPageAllText: _vm.$t('globals.datatable.itemPerPageAll'),
          pageText: _vm.$t('globals.datatable.of'),
        },"noDataText":_vm.$t('globals.datatable.noData'),"noResultsText":_vm.$t('globals.datatable.noResult'),"isSortDisabled":false,"itemPerPage":5},scopedSlots:_vm._u([{key:"item.coverImage",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"container-img"},[(item.coverImages)?_c('img',{staticClass:"container-img__img",attrs:{"src":item.coverImages[0]}}):_c('p',{staticClass:"container-img__placeholder"},[_vm._v(" "+_vm._s(_vm.$t('event.edit.noCover'))+" ")])])]}},{key:"item.name",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.startDate",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$d(Date.parse(item.startDate), 'long'))+" ")]}},{key:"item.endDate",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$d(Date.parse(item.endDate), 'long'))+" ")]}},{key:"item.public",fn:function(ref){
        var item = ref.item;
return [(!item.public)?_c('v-icon',{staticClass:"icon icon__restricted"},[_vm._v(" mdi-lock-outline ")]):_c('v-icon',{staticClass:"icon"},[_vm._v("mdi-lock-open-outline")])]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('admin-table-actions',{attrs:{"user":_vm.loggedInUser,"can-edit":true,"has-delete":false},on:{"edit-item":function($event){return _vm.editEvent(item)}}})]}}],null,true)})],1),_c('v-tab-item',{attrs:{"value":"pastEvent"}},[_c('base-list',{staticClass:"mt-4",attrs:{"className":"img-first","backgroundColor":"var(--v-adminBackground-base)","items":_vm.pastEvents,"loading":_vm.myEventsAreLoading,"headerProps":{ sortIcon: null },"headers":_vm.headers,"search":_vm.search,"footerProps":{
          itemsPerPageText: _vm.$t('globals.datatable.itemPerPage'),
          itemsPerPageAllText: _vm.$t('globals.datatable.itemPerPageAll'),
          pageText: _vm.$t('globals.datatable.of'),
        },"noDataText":_vm.$t('globals.datatable.noData'),"noResultsText":_vm.$t('globals.datatable.noResult'),"isSortDisabled":false,"itemPerPage":5},scopedSlots:_vm._u([{key:"item.coverImage",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"container-img"},[(item.coverImages)?_c('img',{staticClass:"container-img__img",attrs:{"src":item.coverImages[0]}}):_c('p',{staticClass:"container-img__placeholder"},[_vm._v(" "+_vm._s(_vm.$t('event.edit.noCover'))+" ")])])]}},{key:"item.name",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.startDate",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$d(Date.parse(item.startDate), 'long'))+" ")]}},{key:"item.endDate",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$d(Date.parse(item.endDate), 'long'))+" ")]}},{key:"item.public",fn:function(ref){
        var item = ref.item;
return [(!item.public)?_c('v-icon',{staticClass:"icon icon__restricted"},[_vm._v(" mdi-lock-outline ")]):_c('v-icon',{staticClass:"icon"},[_vm._v("mdi-lock-open-outline")])]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('admin-table-actions',{attrs:{"user":_vm.loggedInUser,"can-edit":true,"has-delete":false},on:{"edit-item":function($event){return _vm.editEvent(item)}}})]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }