<template>
  <v-container class="edit-conference-room">
    <v-form :disabled="!canUpdate" v-model="valid">
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="conferenceRoomForm.name"
            :label="$t('conferenceRoom.editModal.name')"
            :rules="rules.name"
            :persistent-placeholder="true"
            placeholder="-"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <p class="background-img__title">{{ $t('conferenceRoom.editModal.backgroundImage') }}</p>

          <v-img
            class="img-container my-2"
            height="140"
            width="300"
            :src="conferenceRoomForm.backgroundImage"
          >
            <p v-if="!conferenceRoomForm.backgroundImage">
              {{ $t('event.edit.noBackground') }}
            </p>
          </v-img>

          <div class="background-img__actions">
            <v-btn v-if="canUpdate" color="primary" @click="openDialog('backgroundImage')">
              {{ $t('globals.upload') }}
              <v-icon right>mdi-upload</v-icon>
            </v-btn>
          </div>
        </v-col>

        <v-col cols="12" sm="6">
          <p class="background-img__title">{{ $t('conferenceRoom.editModal.coverImage') }}</p>

          <v-img
            class="img-container my-2"
            height="140"
            width="300"
            :src="conferenceRoomForm.coverImage"
          >
            <p v-if="!conferenceRoomForm.coverImage">
              {{ $t('event.edit.noCover') }}
            </p>
          </v-img>

          <div class="background-img__actions">
            <v-btn v-if="canUpdate" color="primary" @click="openDialog('coverImage')">
              {{ $t('globals.upload') }}
              <v-icon right>mdi-upload</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-form>

    <v-card-actions class="actions">
      <v-btn color="primary" outlined @click="goBackToList" data-test-id="btn-cancel">
        {{ $t('globals.cancel') }}
      </v-btn>

      <v-btn :disabled="!canUpdate || !valid" color="primary" @click="save" data-test-id="btn-save">
        {{ $t('globals.save') }}
      </v-btn>
    </v-card-actions>

    <upload-pictures
      :dialog="dialog"
      :is-multiple="false"
      :height="pictureDialogHeight"
      :width="pictureDialogWidth"
      @save="uploadPicture"
      @close="closeDialog"
    />
  </v-container>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';
import { mapActions, mapGetters } from 'vuex';

import { ConferenceRoom as ConferenceRoomModel } from '@/models/conference-room/conference-room.model';
import PermissionsUtil from '@/helpers/permissions/permissions.helper';
import VALIDATORS from '@/helpers/forms/validators.helper';

import UploadPictures from '@/components/admin/upload-pictures/UploadPictures.vue';

import {
  CLEAR_CONFERENCE_ROOM,
  GET_CONFERENCE_ROOM,
} from '@/stores/agnostic/actions/conference/agnostic-conference.actions';
import {
  SHARED_FILE_MODULE,
  UPLOAD_FILE,
} from '@/stores/shared/actions/file-upload/file-upload.actions';
import {
  ADMIN_CONFERENCE_MODULE,
  SAVE_CONFERENCE_ROOM,
} from '@/stores/umanize-admin/actions/conference/admin-conference.actions';
import { ADMIN_EVENT_MODULE } from '@/stores/umanize-admin/actions/event/admin-event.actions';
import { APP_USER_MODULE } from '@/stores/umanize-app/actions/user/app-user.actions';

export default {
  name: 'EditRoomAdmin',
  components: {
    UploadPictures,
  },
  props: {
    eventId: {
      type: String,
      required: true,
    },
    conferenceRoomId: {
      type: String,
    },
  },
  data: () => ({
    defaultConference: new ConferenceRoomModel(),
    conferenceRoomForm: new ConferenceRoomModel(),
    startTime: null,
    endTime: null,
    dialog: false,
    dialogType: null,
    valid: false,
    videoTypes: ['zoom', 'vimeo'],
    rules: {
      name: VALIDATORS.REQUIRED.NAME,
    },
    pictureDialogHeight: 0,
    pictureDialogWidth: 0,
    pictureDialogType: null,
    pictureDialogConfig: {
      coverImage: {
        height: 480,
        width: 768,
      },
      backgroundImage: {
        height: 1080,
        width: 1920,
      },
    },
  }),
  computed: {
    ...mapGetters(ADMIN_CONFERENCE_MODULE, ['conferenceRoom']),
    ...mapGetters(SHARED_FILE_MODULE, ['file']),
    ...mapGetters(ADMIN_EVENT_MODULE, ['event']),
    ...mapGetters(APP_USER_MODULE, ['loggedInUserRoles']),
    canUpdate() {
      return PermissionsUtil.isAuthorized(
        ['permission.conferences.canUpdate'],
        this.loggedInUserRoles,
        this.eventId,
      );
    },
  },
  methods: {
    ...mapActions(ADMIN_CONFERENCE_MODULE, [
      GET_CONFERENCE_ROOM,
      CLEAR_CONFERENCE_ROOM,
      SAVE_CONFERENCE_ROOM,
    ]),
    ...mapActions(SHARED_FILE_MODULE, [UPLOAD_FILE]),
    initForm() {
      this.conferenceRoomForm = {
        ...this.defaultConference,
        ...cloneDeep(this.conferenceRoom),
        eventId: this.eventId,
      };
    },
    goBackToList() {
      this.$emit('closeDialog');
    },
    async save() {
      await this[SAVE_CONFERENCE_ROOM](this.conferenceRoomForm);
      this.goBackToList();
    },
    openDialog(type) {
      this.pictureDialogHeight = this.pictureDialogConfig[type].height;
      this.pictureDialogWidth = this.pictureDialogConfig[type].width;
      this.dialogType = type;
      this.dialog = true;
    },
    closeDialog() {
      this.dialogType = null;
      this.dialog = false;
    },
    async uploadPicture(picture) {
      await this[UPLOAD_FILE](picture[0]);
      this.conferenceRoomForm[this.dialogType] = this.file.url;
      this.closeDialog();
    },
  },
  async mounted() {
    if (this.conferenceRoomId) {
      await this[GET_CONFERENCE_ROOM]({
        eventId: this.eventId,
        conferenceRoomId: this.conferenceRoomId,
      });
    }

    await this.initForm();
  },
  beforeDestroy() {
    this[CLEAR_CONFERENCE_ROOM]();
  },
};
</script>

<style lang="scss" scoped>
@import '../../../../styles/core/variables';
@import '../../../../styles/core/mixins';

@include admin-layout();

.edit-conference-room {
  &__title {
    font-size: $x-large-font-size;
    font-weight: $regular;
    margin-bottom: 10px;
  }

  &__content {
    @include admin-card;

    margin: 0;
    padding: 20px 40px;
  }
}

.img-container {
  border: 1px solid var(--v-gray-base);

  p {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 100%;
  }
}
</style>
