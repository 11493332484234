<template>
  <v-dialog
    content-class="v-dialog--overlay"
    v-model="isOpened"
    :max-width="width"
    @click:outside="close"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t('admin.users.edit') }}</span>
      </v-card-title>
      <div v-if="!uploadFrom">
        <div class="content">
          <v-btn x-large color="primary" @click="setUploadFrom(fromForm)">{{
            $t('admin.users.single')
          }}</v-btn>
          <v-btn x-large color="primary" @click="setUploadFrom(fromFile)">{{
            $t('admin.users.multiple')
          }}</v-btn>
        </div>
        <v-card-actions class="actions">
          <v-btn color="primary" outlined @click="close">
            {{ $t('globals.cancel') }}
          </v-btn>
        </v-card-actions>
      </div>
      <upload-user
        v-if="isFromForm"
        :tickets="tickets"
        :eventUsers="eventUsers"
        :onCancel="close"
        :onBack="back"
        @importFile="save"
      />
      <upload-users-from-file
        v-if="isFromFile"
        :tickets="tickets"
        :eventUsers="eventUsers"
        :onCancel="close"
        :onBack="back"
        @importFile="save"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import {
  ADMIN_USER_MODULE,
  UPLOAD_CSV_USERS,
} from '@/stores/umanize-admin/actions/user/admin-user.actions';
import UploadUser from './UploadUser.vue';
import UploadUsersFromFile from './UploadUsersFromFile.vue';

export default {
  components: { UploadUsersFromFile, UploadUser },
  name: 'UploadUsersForm',
  props: ['opened', 'tickets', 'eventUsers'],
  data: () => ({
    isOpened: false,
    uploadFrom: null,
    fromFile: 'fromFile',
    fromForm: 'fromForm',
  }),
  computed: {
    isFromForm() {
      return this.uploadFrom === this.fromForm;
    },
    isFromFile() {
      return this.uploadFrom === this.fromFile;
    },
    width() {
      return this.uploadFrom ? 1200 : 380;
    },
  },
  methods: {
    ...mapActions(ADMIN_USER_MODULE, [UPLOAD_CSV_USERS]),
    setUploadFrom(value) {
      this.uploadFrom = value;
    },
    back() {
      this.uploadFrom = null;
    },
    close() {
      this.uploadFrom = null;
      this.$emit('close');
    },
    saveEditedItem() {
      this.fileData.splice(this.editedItem.index, 1, this.editedItem);
      this.editDialog = false;
      this.checkForErrors();
    },
    async save({ file, ticketId }) {
      await this[UPLOAD_CSV_USERS]({
        eventId: this.$route.params.eventId,
        ticketId,
        file,
      });
      this.close();
    },
  },
  watch: {
    opened(value) {
      this.isOpened = value;
    },
  },
  mounted() {
    this.isOpened = this.opened;
  },
};
</script>

<style lang="scss" scoped>
@import '../../../styles/core/variables';

.content {
  display: flex;
  justify-content: space-evenly;
}
.actions {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &__button {
    margin: 0 0.5rem;
  }
}
</style>
