<template>
  <v-card width="100%" elevation="0">
    <v-form v-model="valid" @submit.prevent @submit="updateUser()" :disabled="!canUpdateProfile">
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="profileForm.firstName"
            placeholder="-"
            :persistent-placeholder="true"
            :label="$t('auth.profile.firstName')"
            :rules="firstnameRules"
            required
          />
        </v-col>

        <v-col cols="12" sm="6">
          <v-text-field
            v-model="profileForm.lastName"
            placeholder="-"
            :persistent-placeholder="true"
            :label="$t('auth.profile.lastName')"
            :rules="lastnameRules"
            required
          />
        </v-col>

        <v-col>
          <div class="profile-form__about">
            <v-label class="pb-2">
              {{ $t('auth.profile.about') }}
            </v-label>

            <vue-editor-image
              :content="profileForm.about"
              @set-content="setAbout"
            ></vue-editor-image>
          </div>
        </v-col>
      </v-row>

      <v-card-actions :class="{ 'justify-end': asAdmin, 'justify-center': !asAdmin }">
        <v-btn v-if="asAdmin" class="mx-2" color="primary" outlined @click="$emit('cancel')">
          {{ $t('globals.cancel') }}
        </v-btn>

        <v-btn color="primary" type="submit" :disabled="isLoading || !valid || !canUpdateProfile">
          {{ buttonName }}
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { DateTime } from 'luxon';

import { User } from '@/models';

import VALIDATORS from '@/helpers/forms/validators.helper';

import VueEditorImage from '@/components/vue-editor-image/VueEditorImage.vue';

import {
  SHARED_FILE_MODULE,
  UPLOAD_FILE,
} from '@/stores/shared/actions/file-upload/file-upload.actions';

export default {
  name: 'ProfileForm',
  components: {
    VueEditorImage,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    canUpdateProfile: {
      type: Boolean,
      default: false,
    },
    asAdmin: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    autocomplete: null,
    profileForm: new User(),
    firstnameRules: VALIDATORS.REQUIRED.FIRSTNAME,
    lastnameRules: VALIDATORS.REQUIRED.LASTNAME,
    emailRules: [VALIDATORS.EMAIL.REQUIRED, VALIDATORS.EMAIL.FORMAT],
    menu: false,
    dialog: false,
    date: null,
    maxDate: DateTime.local().startOf('day').toISO(),
    valid: false,
    rules: {
      phone: VALIDATORS.PHONE.FORMAT,
    },
  }),
  computed: {
    ...mapGetters(SHARED_FILE_MODULE, ['file']),
    hasUser() {
      return !!this.user?.id;
    },
    buttonName() {
      return this.hasUser ? this.$t('auth.profile.update') : this.$t('globals.save');
    },
  },
  methods: {
    ...mapActions(SHARED_FILE_MODULE, [UPLOAD_FILE]),
    initForm() {
      this.profileForm = {
        ...this.profileForm,
        ...this.user,
      };
    },
    setAbout(about) {
      this.profileForm.about = about;
    },
    updateUser() {
      this.$emit('update-user', {
        ...this.profileForm,
      });
    },
    closeDialog() {
      this.dialog = false;
    },
    openDialog() {
      this.dialog = true;
    },
  },
  mounted() {
    this.initForm();
  },
  watch: {
    menu(val) {
      return (
        val &&
        setTimeout(() => {
          this.$refs.picker.activePicker = 'YEAR';
        })
      );
    },
    user() {
      this.initForm();
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../../styles/core/variables';
.base-input-multiple {
  padding: $padding-medium;
}

.profile-form__about {
  width: 100%;
  margin-right: 10px;
  min-height: auto;

  word-break: break-word;
}
</style>
