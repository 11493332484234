var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"ga"},[_c('h2',{staticClass:"ga__title"},[_vm._v(_vm._s(_vm.currentEvent.name)+" - "+_vm._s(_vm.$t('admin.generalAssemblies.title')))]),_c('div',{staticClass:"ga__actions"},[_c('v-btn',{staticClass:"actions__create",attrs:{"large":"","depressed":"","color":"primary","disabled":!_vm.canCreate},on:{"click":function($event){return _vm.openDialog('edit')}}},[_vm._v(" "+_vm._s(_vm.$t('conference.list.add'))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-plus")])],1),_c('AppSearch',{staticClass:"actions__search",on:{"change":_vm.onSearchChange}})],1),_c('base-list',{attrs:{"backgroundColor":"var(--v-adminBackground-base)","loading":_vm.assembliesAreLoading,"headerProps":{ sortIcon: null },"headers":_vm.headers,"search":_vm.search,"items":_vm.generalAssemblies,"itemsPerPage":10,"footerProps":{
      itemsPerPageText: _vm.$t('globals.datatable.itemPerPage'),
      itemsPerPageAllText: _vm.$t('globals.datatable.itemPerPageAll'),
      pageText: _vm.$t('globals.datatable.of'),
    },"noDataText":_vm.$t('globals.datatable.noData'),"noResultsText":_vm.$t('globals.datatable.noResult')},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name))])]}},{key:"item.description",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.description))])]}},{key:"item.agenda",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.agenda))])]}},{key:"item.startTime",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$d(Date.parse(item.startTime), 'long')))])]}},{key:"item.endTime",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$d(Date.parse(item.endTime), 'long')))])]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('admin-table-actions',{attrs:{"user":_vm.currentLoggedInUser,"can-edit":_vm.canEdit,"can-delete":_vm.canDelete,"is-disabled":_vm.hasQuestions(item),"disabled-tooltip":_vm.$t('general-assembly.delete.hasContent')},on:{"delete-item":function($event){return _vm.openDialog('delete', item)},"edit-item":function($event){return _vm.openDialog('edit', item)}}})]}}],null,true)}),(_vm.dialog.edit)?_c('v-dialog',{attrs:{"content-class":"v-dialog--overlay","width":"800"},on:{"click:outside":function($event){return _vm.closeDialog('edit')}},model:{value:(_vm.dialog.edit),callback:function ($$v) {_vm.$set(_vm.dialog, "edit", $$v)},expression:"dialog.edit"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.currentEvent.name)+" - "+_vm._s(_vm.isEdit)+" ")]),_c('v-card-text',[_c('edit-general-assemblies-admin',{attrs:{"eventId":_vm.eventId,"assemblyId":_vm.selectedGeneralAssemblyId,"editing":!!this.selectedGeneralAssembly},on:{"closeDialog":function($event){return _vm.closeDialog('edit')},"save":_vm.loadGeneralAllAssemblies}})],1)],1)],1):_vm._e(),_c('dialog-confirmation',{attrs:{"v-if":_vm.dialog.delete,"visible":_vm.dialog.delete,"title":_vm.$t('general-assembly.delete.deleteConfirmationDialog.title'),"content":_vm.$t('general-assembly.delete.deleteConfirmationDialog.content')},on:{"cancel":function($event){return _vm.closeDialog('delete')},"confirm":_vm.confirmationDialogConfirm}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }