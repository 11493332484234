var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"exhibitors"}},[_c('h2',{staticClass:"exhibitors__title"},[_vm._v(_vm._s(_vm.event.name)+" - "+_vm._s(_vm.$t('admin.exhibitors.title')))]),_c('div',{staticClass:"exhibitors__actions"},[_c('v-btn',{staticClass:"actions__create",attrs:{"large":"","depressed":"","color":"primary","disabled":!_vm.canCreate},on:{"click":function($event){return _vm.addExhibitor()}}},[_vm._v(" "+_vm._s(_vm.$t('globals.add'))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-plus")])],1),_c('AppSearch',{staticClass:"actions__search",on:{"change":_vm.onSearchChange}})],1),_c('base-list',{staticClass:"mt-4",attrs:{"backgroundColor":"var(--v-adminBackground-base)","headers":_vm.headers,"items":_vm.exhibitorsWithCompletion,"search":_vm.search,"footer-props":{
      itemsPerPageText: _vm.$t('globals.datatable.itemPerPage'),
      itemsPerPageAllText: _vm.$t('globals.datatable.itemPerPageAll'),
    },"no-data-text":_vm.$t('globals.datatable.noData'),"no-results-text":_vm.$t('globals.datatable.noResult')},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.tags",fn:function(ref){
    var item = ref.item;
return [(item.kioskOptions.tags)?_c('div',_vm._l((item.kioskOptions.tags.split(',')),function(tag){return _c('v-chip',{key:tag,staticClass:"mr-2"},[_vm._v(" "+_vm._s(tag)+" ")])}),1):_vm._e()]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('admin-table-actions',{attrs:{"user":_vm.currentLoggedInUser,"is-disabled":_vm.hasRepresent(item),"can-edit":_vm.canUpdate,"can-delete":_vm.canDelete,"disabled-tooltip":_vm.$t('exhibitors.delete.hasContent')},on:{"edit-item":function($event){return _vm.editExhibitor(item)},"delete-item":function($event){return _vm.confirmDeleteExhibitor(item)}}})]}}],null,true)}),_c('dialog-confirmation',{attrs:{"v-if":_vm.exhibitorToDelete,"visible":_vm.showConfirmation,"title":_vm.$t('exhibitors.delete.deleteConfirmationDialog.title'),"content":_vm.$t('exhibitors.delete.deleteConfirmationDialog.content')},on:{"cancel":_vm.confirmationDialogCancel,"confirm":_vm.confirmationDialogConfirm}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }