<template>
  <kiosk-configuration
    configureStandUrl="ConfigureExhibitorAdmin"
    :nameSpaceExhibitor="getNameSpaceExhibitor"
    :nameSpaceEvent="getNameSpaceEvent"
    :canUpdateAdminInfo="true"
    :formTitle="formTitle"
    :isEditing="isEditing"
  />
</template>
<script>
import { ADMIN_EXHIBITOR_MODULE } from '@/stores/umanize-admin/actions/exhibitor/exhibitor.actions';
import { ADMIN_EVENT_MODULE } from '@/stores/umanize-admin/actions/event/admin-event.actions';

import KioskConfiguration from '@/components/kiosk-configuration/KioskConfiguration.vue';

export default {
  name: 'EditExhibitorAdmin',
  components: {
    KioskConfiguration,
  },
  data: () => ({}),
  computed: {
    getNameSpaceEvent() {
      return ADMIN_EVENT_MODULE;
    },
    getNameSpaceExhibitor() {
      return ADMIN_EXHIBITOR_MODULE;
    },
    isEditing() {
      return this.$route.path.includes('edit');
    },
    formTitle() {
      if (this.isEditing) {
        return this.$t('admin.exhibitors.edit.title');
      }

      return this.$t('admin.exhibitors.add.title');
    },
  },
};
</script>
