var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"roles"},[_c('h2',{staticClass:"roles__title"},[_vm._v(_vm._s(_vm.$t('admin.roles.listTitle')))]),_c('div',{staticClass:"roles__actions"},[_c('v-btn',{staticClass:"actions__create",attrs:{"color":"primary","disabled":!_vm.canCreate,"large":"","depressed":""},on:{"click":function($event){return _vm.addRole()}}},[_vm._v(" "+_vm._s(_vm.$t('globals.add'))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-plus")])],1),_c('AppSearch',{staticClass:"actions__search",on:{"change":_vm.onSearchChange}})],1),_c('base-list',{staticClass:"mt-4",attrs:{"backgroundColor":"var(--v-adminBackground-base)","headers":_vm.headers,"items":_vm.roles,"search":_vm.search,"loading":_vm.rolesAreLoading,"footerProps":{
      itemsPerPageText: _vm.$t('globals.datatable.itemPerPage'),
      itemsPerPageAllText: _vm.$t('globals.datatable.itemPerPageAll'),
      pageText: _vm.$t('globals.datatable.of'),
    },"noDataText":_vm.$t('globals.datatable.noData'),"noResultsText":_vm.$t('globals.datatable.noResult')},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('admin-table-actions',{attrs:{"user":"currentLoggedInUser","canEdit":_vm.canUpdateRoles,"has-delete":false},on:{"edit-item":function($event){return _vm.editRoleItem(item)}}})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }