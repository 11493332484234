<template>
  <v-container id="users">
    <h2 class="user-list__title">{{ $t('admin.users.title', { eventName: event.name }) }}</h2>

    <div class="user-list__actions">
      <v-btn
        class="actions__create"
        large
        depressed
        color="primary"
        @click="addUser()"
        v-if="false"
      >
        {{ $t('globals.add') }}
        <v-icon class="btn-icon">mdi-plus</v-icon>
      </v-btn>

      <AppSearch class="actions__search" @change="onSearchChange" />

      <v-btn
        color="primary"
        class="users__import"
        @click="openDialog('uploadUsers')"
        :disabled="!canImport"
        dense
        outlined
        depressed
      >
        {{ $t('admin.users.new') }}
        <v-icon class="btn-icon">mdi-tray-arrow-up</v-icon>
      </v-btn>
    </div>

    <base-list
      class="mt-4"
      className="img-first"
      backgroundColor="var(--v-adminBackground-base)"
      :loading="isLoadingOrUploading"
      :headerProps="{ sortIcon: null }"
      :headers="headers"
      :search="search"
      :items="eventUsers"
      :itemsPerPage="10"
      :footerProps="{
        itemsPerPageText: $t('globals.datatable.itemPerPage'),
        itemsPerPageAllText: $t('globals.datatable.itemPerPageAll'),
        pageText: $t('globals.datatable.of'),
      }"
      :noDataText="$t('globals.datatable.noData')"
      :noResultsText="$t('globals.datatable.noResult')"
    >
      <template v-slot:[`item.avatar`]="{ item }">
        <div class="container-img">
          <img v-if="item.avatar" :src="item.avatar" class="container-img__img" />
          <p v-else>{{ $t('admin.users.noAvatar') }}</p>
        </div>
      </template>

      <template v-slot:[`item.email`]="{ item }">
        {{ item.email }}
      </template>

      <template v-slot:[`item.ticket`]="{ item }">
        <span v-if="item.ticket && item.ticket.ticketId">
          {{ ticketName(item.ticket.ticketId) }}
        </span>
      </template>

      <template v-slot:[`item.activate`]="{ item }">
        <div class="users-actions">
          <v-btn
            icon
            color="secondary"
            :disabled="isSavingTicket || !canUpdateTickets"
            @click="toggleStatus(item)"
          >
            <v-icon
              size="40"
              color="primary"
              :title="$t('admin.users.list.deactivate')"
              v-if="item.ticket.active"
            >
              mdi-toggle-switch
            </v-icon>

            <v-icon size="40" :title="$t('admin.users.list.activate')" v-else>
              mdi-toggle-switch-off
            </v-icon>
          </v-btn>
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn v-if="canUpdateProfile" icon @click="openDialog('actions', item)">
          <v-icon :title="$t('admin.users.editAvatar.title')">mdi-cog-outline</v-icon>
        </v-btn>
      </template>
    </base-list>

    <upload-users-form
      :opened="dialog.uploadUsers"
      :tickets="tickets"
      :event-users="eventUsers"
      @close="closeDialog('uploadUsers')"
    />

    <v-dialog v-model="dialog.actions" width="800">
      <v-card>
        <v-card-title>{{ $t('admin.users.editModal.title') }}</v-card-title>

        <v-card-text>
          <v-btn
            v-if="canUpdateTickets"
            color="primary"
            depressed
            @click="openDialog('ticket', selectedUser)"
          >
            {{ $t('admin.users.updateTicket.title') }}
            <v-icon :title="$t('admin.users.updateTicket.title')" class="btn-icon"
              >mdi-ticket-confirmation</v-icon
            >
          </v-btn>

          <v-btn
            v-if="canUpdateProfile"
            color="primary"
            depressed
            @click="openDialog('avatar', selectedUser)"
          >
            {{ $t('admin.users.editAvatar.title') }}
            <v-icon :title="$t('admin.users.editAvatar.title')" class="btn-icon"
              >mdi-account-circle</v-icon
            >
          </v-btn>

          <v-btn color="primary" depressed @click="openDialog('profile', selectedUser)">
            {{ $t('admin.users.editProfile.title') }}
            <v-icon
              v-if="canUpdateProfile"
              :title="$t('admin.users.editProfile.title')"
              class="btn-icon"
            >
              mdi-account-edit
            </v-icon>
            <v-icon v-else :title="$t('admin.users.viewProfile.title')" class="btn-icon"
              >mdi-eye</v-icon
            >
          </v-btn>

          <v-btn
            v-if="canUpdatePassword"
            color="primary"
            depressed
            @click="openDialog('password', selectedUser)"
          >
            {{ $t('admin.users.editPassword.title') }}
            <v-icon :title="$t('admin.users.editPassword.title')" class="btn-icon"
              >mdi-form-textbox-password</v-icon
            >
          </v-btn>
        </v-card-text>

        <v-card-actions class="actions">
          <v-btn color="primary" outlined @click="closeDialog('actions')">
            {{ $t('globals.cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      content-class="v-dialog--overlay"
      v-model="dialog.profile"
      v-if="dialog.profile"
      width="800"
    >
      <v-card>
        <v-card-title>{{ $t('admin.users.editProfile.title') }}</v-card-title>

        <v-card-text class="form">
          <profile-form
            v-if="selectedUser && !hasTicketProfile"
            :isLoading="false"
            :user="selectedUser"
            :can-update-profile="true"
            @update-user="updateUser"
            class="form"
            :isDialog="true"
            @cancel="closeDialog('profile')"
            :asAdmin="true"
          />

          <profile-ticket-form
            v-if="hasTicketProfile"
            :ticketForm="ticketForm"
            :isDialog="true"
            :asAdmin="true"
            @update-profile="updateProfile"
            @get-profile="getProfileTicket"
            :profileIsLoaded="profileIsLoaded"
            :profileIsLoading="profileIsLoading"
            :profileError="profileError"
            :profile="profile"
            @cancel="closeDialog('profile')"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      content-class="v-dialog--overlay"
      v-model="dialog.password"
      v-if="dialog.password"
      width="800"
    >
      <v-card>
        <v-card-title>{{ $t('admin.users.editPassword.title') }}</v-card-title>

        <v-card-text>
          <password-form
            :user="selectedUser"
            :can-update-password="canUpdatePassword"
            :asAdmin="true"
            @update-password="updatePassword"
            @cancel="closeDialog('password')"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      content-class="v-dialog--overlay"
      v-model="dialog.ticket"
      v-if="dialog.ticket"
      width="800"
    >
      <v-card>
        <v-card-title>{{ $t('admin.users.updateTicket.title') }}</v-card-title>

        <v-card-text>
          <v-select
            :items="tickets"
            item-value="id"
            item-text="name"
            v-model="selectedTicketId"
            :label="$t('admin.users.selectTicketInput')"
          />
        </v-card-text>

        <v-card-actions class="actions">
          <v-btn color="primary" class="mx-2" outlined @click="cancelUpdateTicket">
            {{ $t('globals.cancel') }}
          </v-btn>

          <v-btn color="primary" @click="updateUserTicket">{{ $t('globals.save') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      content-class="v-dialog--overlay"
      v-model="dialog.avatar"
      v-if="dialog.avatar"
      width="800"
    >
      <v-card>
        <v-card-title>{{ $t('admin.users.editAvatar.title') }}</v-card-title>

        <v-card-text>
          <avatar-form
            :user="selectedUser"
            :can-update-profile="canUpdateProfile"
            :asAdmin="true"
            @upload-avatar="updateAvatar"
            @cancel="closeDialog('avatar')"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import PermissionsUtil from '@/helpers/permissions/permissions.helper';

import ProfileForm from '@/components/profile/profile-form/ProfileForm.vue';
import UploadUsersForm from '@/components/admin/upload-users-form/UploadUsersForm.vue';
import ProfileTicketForm from '@/components/profile/profile-ticket-form/ProfileTicketForm.vue';
import PasswordForm from '@/components/profile/password-form/PasswordForm.vue';
import AvatarForm from '@/components/profile/avatar-form/AvatarForm.vue';
import AppSearch from '@/components/app/app-search/AppSearch.vue';

import {
  UPDATE_USER_PASSWORD,
  USER_TICKET_MODULE,
  UPDATE_AVATAR,
  UPDATE_TICKET_PROFILE,
  GET_TICKET_PROFILE,
  UPDATE_USER,
} from '@/stores/agnostic/actions/user/agnostic-user.actions';
import { FETCH_EVENT_USERS } from '@/stores/agnostic/actions/event/agnostic-event.actions';

import {
  ADMIN_TICKET_MODULE,
  GET_ADMIN_TICKETS,
} from '@/stores/umanize-admin/actions/ticket/admin-ticket.actions';
import {
  ADMIN_USER_MODULE,
  CLEAR_USER,
  SAVE_USER,
  TOGGLE_TICKET_STATUS,
  UPDATE_USER_TICKETID,
  CLEAR_TICKET_PROFILE,
} from '@/stores/umanize-admin/actions/user/admin-user.actions';
import { ADMIN_EVENT_MODULE } from '@/stores/umanize-admin/actions/event/admin-event.actions';
import { APP_USER_MODULE } from '@/stores/umanize-app/actions/user/app-user.actions';
import BaseList from '@/components/base-list/BaseList.vue';

export default {
  name: 'UsersAdmin',
  components: {
    AvatarForm,
    PasswordForm,
    ProfileTicketForm,
    UploadUsersForm,
    BaseList,
    AppSearch,
    ProfileForm,
  },
  data: () => ({
    dialog: {
      uploadUsers: false,
      actions: false,
      profile: false,
      password: false,
      ticket: false,
      avatar: false,
    },
    selectedUser: null,
    selectedTicketId: null,
    selectedTicket: null,
    search: '',
    form: {},
  }),
  computed: {
    ...mapGetters(ADMIN_EVENT_MODULE, ['event', 'usersAreLoading', 'eventUsers']),
    ...mapGetters(ADMIN_USER_MODULE, [
      'usersIsUploading',
      'profile',
      'profileIsLoading',
      'profileIsLoaded',
      'profileError',
    ]),
    ...mapGetters(APP_USER_MODULE, ['loggedInUserRoles']),
    ...mapGetters(`${ADMIN_USER_MODULE}/${USER_TICKET_MODULE}`, {
      isSavingTicket: 'isSaving',
    }),
    hasTicketProfile() {
      return !!this.$route.params.eventId && !!this.ticketForm?.profileItems;
    },
    ...mapGetters(ADMIN_TICKET_MODULE, ['tickets', 'ticketName']),
    headers() {
      return [
        {
          sortable: false,
          value: 'avatar',
          width: '100px',
        },
        {
          text: this.$t('admin.users.headers.firstName'),
          align: 'start',
          value: 'firstName',
          width: '175px',
        },
        {
          text: this.$t('admin.users.headers.lastName'),
          value: 'lastName',
          width: '175px',
        },
        {
          text: this.$t('admin.users.headers.email'),
          value: 'email',
        },
        {
          text: this.$t('admin.users.headers.ticketType'),
          value: 'ticket',
          width: '150px',
        },
        {
          text: this.$t('admin.users.headers.active'),
          value: 'activate',
          align: 'center',
          sortable: false,
          width: '75px',
        },
        {
          value: 'actions',
          sortable: false,
          align: 'center',
          width: '75px',
        },
      ];
    },
    isLoadingOrUploading() {
      return this.usersAreLoading || this.usersIsUploading;
    },
    canUpdateProfile() {
      return PermissionsUtil.isAuthorized(
        ['permission.users.canUpdateProfile'],
        this.loggedInUserRoles,
        this.$route.params.eventId,
      );
    },
    canUpdatePassword() {
      return PermissionsUtil.isAuthorized(
        ['permission.users.canUpdatePassword'],
        this.loggedInUserRoles,
        this.$route.params.eventId,
      );
    },
    canUpdateTickets() {
      return PermissionsUtil.isAuthorized(
        ['permission.users.canUpdateTickets'],
        this.loggedInUserRoles,
        this.$route.params.eventId,
      );
    },
    canUpdate() {
      return this.canUpdateProfile || this.canUpdatePassword || this.canUpdateTickets;
    },
    canImport() {
      return PermissionsUtil.isAuthorized(
        ['permission.users.canImport'],
        this.loggedInUserRoles,
        this.$route.params.eventId,
      );
    },
    ticketForm() {
      return this.tickets.filter((t) => t.id === this.selectedUser.ticket.ticketId)[0]
        .ticketUserProfile;
    },
  },
  methods: {
    ...mapActions(ADMIN_USER_MODULE, [
      SAVE_USER,
      UPDATE_USER_PASSWORD,
      UPDATE_AVATAR,
      CLEAR_USER,
      UPDATE_TICKET_PROFILE,
      GET_TICKET_PROFILE,
      CLEAR_TICKET_PROFILE,
      UPDATE_USER,
    ]),
    ...mapActions(`${ADMIN_USER_MODULE}/${USER_TICKET_MODULE}`, [
      TOGGLE_TICKET_STATUS,
      UPDATE_USER_TICKETID,
    ]),
    ...mapActions(ADMIN_EVENT_MODULE, [FETCH_EVENT_USERS]),
    ...mapActions(ADMIN_TICKET_MODULE, [GET_ADMIN_TICKETS]),
    onSearchChange(search) {
      this.search = search;
    },
    cancel() {
      this.csvValidationDialog = false;
      this.file = null;
    },
    addUser() {
      this.$router.push({ name: 'AddUserAdmin' });
    },
    async getProfileTicket() {
      if (this.ticketForm.profileItems) {
        await this[GET_TICKET_PROFILE]({
          user: {
            ticketId: this.ticketForm.ticketId,
            ticketUserProfileId: this.ticketForm.id,
            userId: this.selectedUser.id,
          },
        });
      }
    },
    async updateProfile(profileForm, isValid) {
      await this[UPDATE_TICKET_PROFILE]({
        user: {
          ...profileForm,
          completed: isValid,
          userId: this.selectedUser.id,
        },
      });

      await this.getUsers();
      this[CLEAR_USER]();
      this[CLEAR_TICKET_PROFILE]();
      this.closeDialog('profile');
    },
    async updateUser(profileForm) {
      await this[UPDATE_USER]({ user: profileForm });
      await this.getUsers();
      this[CLEAR_USER]();
      this.closeDialog('profile');
    },
    async toggleStatus(user) {
      await this[TOGGLE_TICKET_STATUS]({
        eventId: this.$route.params.eventId,
        userId: user.id,
        ticket: user.ticket,
      });
      await this.getUsers();
    },
    openDialog(name, item = null) {
      // eslint-disable-next-line no-return-assign
      Object.keys(this.dialog).forEach((d) => (this.dialog[d] = false));

      if (item) {
        this.selectedUser = {
          ...item,
        };
      }

      this.dialog[name] = true;
    },
    closeDialog(name) {
      this.selectedUser = null;
      this.dialog[name] = false;
    },
    async updatePassword(passwordForm) {
      await this[UPDATE_USER_PASSWORD]({ user: this.selectedUser, password: passwordForm });
      this.closeDialog('password');
    },
    async updateUserTicket() {
      await this[UPDATE_USER_TICKETID]({
        eventId: this.$route.params.eventId,
        userId: this.selectedUser.id,
        ticketId: this.selectedTicketId,
      });
      this.selectedTicketId = null;

      await this.getUsers();
      this.closeDialog('ticket');
    },
    async updateAvatar(file) {
      await this[UPDATE_AVATAR]({
        user: this.selectedUser,
        file,
      });

      await this.getUsers();
      this.closeDialog('avatar');
    },
    async getUsers() {
      const { eventId } = this.$route.params;
      await this[FETCH_EVENT_USERS]({ id: eventId });
    },
    cancelProfileUpdate() {
      this[CLEAR_TICKET_PROFILE]();
      this.closeDialog('profile');
      this[CLEAR_USER]();
    },
    cancelUpdateTicket() {
      this.closeDialog('ticket');
      this.selectedTicketId = null;
    },
  },
  async mounted() {
    const { eventId } = this.$route.params;
    await this.getUsers();
    await this[GET_ADMIN_TICKETS](eventId);
  },
};
</script>

<style lang="scss" scoped>
@import '../../../styles/core/variables';
@import '../../../styles/core/mixins';

@include admin-layout();

#users {
  padding: 50px;
  min-height: calc(100vh - 6rem);
}

.male {
  color: #2b97d1;
}

.female {
  color: #d42d65;
}

.other {
  color: grey;
}

.user-list {
  &__title {
    margin-bottom: 10px;
  }

  &__actions {
    display: flex;
    align-items: center;

    & > *:not(:last-child) {
      margin-right: 10px;
    }
  }
}

.form {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap !important;
}

.v-card__text {
  display: flex;
  flex-wrap: wrap;

  & > * {
    margin: 0 10px 10px 0;
  }
}

.btn-icon {
  margin-left: 5px;
}

.container-img {
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    margin: 0;
    font-size: $x-small-font-size;
  }

  &__img {
    width: 100%;
    height: 100%;
  }
}

::v-deep .v-data-table__mobile-row:last-child {
  .v-data-table__mobile-row__header {
    display: none;
  }

  .v-data-table__mobile-row__cell {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;

    .users-actions {
      align-items: center;
      justify-content: center;
    }
  }
}

@include breakpoint(medium) {
  .container-img {
    width: 100px;
    height: 65px;
    border-radius: 10px 0 0 10px;
    border-right: 1px solid var(--v-gray-base);

    img {
      object-fit: cover;
      border-radius: 10px 0 0 10px;
    }
  }
}
</style>
