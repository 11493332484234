<template>
  <v-container id="group-chat">
    <h2 class="group-chat__title" data-test-id="title">
      {{ $t('admin.groupChat.title') }}
    </h2>

    <div class="group-chat__actions">
      <v-btn
        data-test-id="btn-create"
        large
        class="actions__create"
        color="primary"
        @click="openDialog('add')"
        :disabled="!canCreateGroupChat"
      >
        {{ $t('globals.add') }}
        <v-icon right>mdi-plus</v-icon>
      </v-btn>
    </div>

    <base-list
      class="mt-4"
      backgroundColor="var(--v-adminBackground-base)"
      :headers="headers"
      :headerProps="{ sortIcon: null }"
      :items="groupChats(event.id)"
      :itemsPerPage="10"
      :loading="!isLoaded"
      :footerProps="{
        itemsPerPageText: $t('globals.datatable.itemPerPage'),
        itemsPerPageAllText: $t('globals.datatable.itemPerPageAll'),
        pageText: $t('globals.datatable.of'),
      }"
      :noDataText="$t('globals.datatable.noData')"
      :noResultsText="$t('globals.datatable.noResult')"
    >
      <template class="centered" v-slot:[`item.name`]="{ item }">
        {{ item.name }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <admin-table-actions
          data-test-id="btn-edit"
          :user="currentLoggedInUser"
          :can-edit="canUpdateGroupChat"
          @edit-item="editGroupChatUsers(item)"
        />
      </template>
    </base-list>

    <v-dialog
      content-class="v-dialog--overlay"
      v-model="dialog.add"
      v-if="dialog.add"
      width="800"
      @click:outside="closeDialog('add')"
      data-test-id="modal-add"
    >
      <v-card>
        <v-card-title>
          {{ event.name }} -
          {{ $t('admin.groupChat.editModal.title.create') }}
        </v-card-title>

        <v-card-text>
          <add-group-chat-admin
            :users="eventUsers"
            :tickets="tickets"
            :selected-chat="selectedGroupChat"
            :can-create="canCreateGroupChat"
            @add="addGroupChat"
            @cancel="closeDialog('add')"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog.edit"
      v-if="dialog.edit"
      width="800"
      @click:outside="closeDialog('edit')"
      data-test-id="modal-edit"
    >
      <v-card>
        <v-card-title>
          {{ event.name }} -
          {{ $t('admin.groupChat.editModal.title.edit') }}
        </v-card-title>

        <v-card-text>
          <add-group-chat-admin
            :users="eventUsers"
            :tickets="tickets"
            :selected-chat="selectedGroupChat"
            :can-update="canUpdateGroupChat"
            :is-type-add="false"
            @edit="editGroupChat"
            @cancel="closeDialog('edit')"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import PermissionsUtil from '@/helpers/permissions/permissions.helper';

import {
  ADMIN_TICKET_MODULE,
  GET_ADMIN_TICKETS,
} from '@/stores/umanize-admin/actions/ticket/admin-ticket.actions';

import { ADMIN_EVENT_MODULE } from '@/stores/umanize-admin/actions/event/admin-event.actions';
import { APP_USER_MODULE } from '@/stores/umanize-app/actions/user/app-user.actions';
import {
  APP_CHATS_MODULE,
  GET_EVENT_CHATS,
  CREATE_CHAT,
  UPDATE_CHAT,
} from '@/stores/umanize-app/actions/chats/chats.actions';
import {
  APP_EVENT_MODULE,
  FETCH_EVENT_USERS,
} from '@/stores/umanize-app/actions/event/app-event.actions';

import BaseList from '@/components/base-list/BaseList.vue';
import AdminTableActions from '@/components/admin/admin-table-actions/AdminTableActions.vue';
import AddGroupChatAdmin from './add/AddGroupChatAdmin.vue';

export default {
  name: 'GroupChatAdmin',
  components: {
    BaseList,
    AdminTableActions,
    AddGroupChatAdmin,
  },
  data() {
    return {
      dialog: {
        edit: false,
        add: false,
      },
      modalTitle: 'edit',
      selectedGroupChatId: null,
    };
  },
  computed: {
    ...mapGetters(ADMIN_EVENT_MODULE, ['event']),
    ...mapGetters(APP_EVENT_MODULE, ['eventUsers']),
    ...mapGetters(APP_USER_MODULE, ['currentLoggedInUser', 'loggedInUserRoles']),
    ...mapGetters(APP_CHATS_MODULE, ['isLoaded', 'groupChats', 'chatsDisplay']),
    ...mapGetters(ADMIN_TICKET_MODULE, ['tickets']),
    headers() {
      return [
        {
          text: this.$t('admin.groupChat.headers.name'),
          sortable: false,
          value: 'name',
        },
        {
          value: 'actions',
          align: 'center',
          width: '75px',
        },
      ];
    },
    canUpdateGroupChat() {
      return PermissionsUtil.isAuthorized(
        ['permission.conferences.canUpdate'],
        this.loggedInUserRoles,
        this.event.id,
      );
    },
    canCreateGroupChat() {
      return PermissionsUtil.isAuthorized(
        ['permission.conferences.canCreate'],
        this.loggedInUserRoles,
        this.event.id,
      );
    },
    selectedGroupChat() {
      return this.groupChats(this.event.id).find((gc) => gc.id === this.selectedGroupChatId);
    },
  },
  methods: {
    ...mapActions(APP_EVENT_MODULE, [FETCH_EVENT_USERS]),
    ...mapActions(APP_CHATS_MODULE, [GET_EVENT_CHATS, CREATE_CHAT, UPDATE_CHAT]),
    ...mapActions(ADMIN_TICKET_MODULE, [GET_ADMIN_TICKETS]),
    async addGroupChat(groupChat) {
      const payload = {
        userIds: groupChat.userIds,
        adminIds: groupChat.adminIds,
        name: groupChat.name,
        eventId: this.event.id,
      };

      await this[CREATE_CHAT](payload);
      this.closeDialog('add');
    },
    async editGroupChat(groupChat) {
      const payload = {
        chat: {
          userIds: groupChat.userIds,
          adminIds: groupChat.adminIds,
          name: groupChat.name,
          eventId: this.event.id,
        },
        chatId: this.selectedGroupChatId,
      };

      await this[UPDATE_CHAT](payload);
      this.closeDialog('edit');
    },
    editGroupChatUsers(item) {
      this.selectedGroupChatId = item.id;
      this.openDialog('edit');
    },
    openDialog(type) {
      this.dialog[type] = true;
    },
    closeDialog(type) {
      this.selectedGroupChatId = null;
      this.dialog[type] = false;
    },
  },
  async mounted() {
    await this[FETCH_EVENT_USERS]({ id: this.event.id });
    await this[GET_ADMIN_TICKETS](this.event.id);
    await this[GET_EVENT_CHATS](this.event.id);
  },
};
</script>

<style lang="scss" scoped>
@import '../../../styles/core/variables';
@import '../../../styles/core/mixins';

@include admin-layout();

#group-chat {
  padding: 50px;
  min-height: calc(100vh - 6rem);
}

.group-chat {
  &__title {
    margin-bottom: 10px;
  }

  &__name {
    display: flex;
    align-items: center;
  }
}

.name {
  &__field {
    margin: 0;
    padding: 0;
  }

  &__edit-btn {
    margin-left: 10px;
  }
}
</style>
