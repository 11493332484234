<template>
  <v-container id="conferences">
    <h2 class="conferences__title">{{ event.name }} - {{ $t('conference.list.title') }}</h2>

    <div class="conferences__actions">
      <v-btn
        large
        depressed
        class="actions__create"
        color="primary"
        @click="addConference()"
        :disabled="!canCreate"
      >
        {{ $t('conference.list.add') }}
        <v-icon>mdi-plus</v-icon>
      </v-btn>

      <AppSearch class="actions__search" @change="onSearchChange" />
    </div>

    <conferences-table
      :conferences="conferences"
      :conferences-with-partners="conferenceIdsWithPartners"
      :conferences-are-loading="conferencesAreLoading"
      :search="search"
      :user="currentLoggedInUser"
      :can-edit="canEdit"
      :can-delete="canDelete"
      @edit-conference="editConference"
      @delete-conference="deleteConference"
    />

    <dialog-confirmation
      :visible="showConfirmation"
      :title="$t('conference.delete.deleteConfirmationDialog.title')"
      :content="$t('conference.delete.deleteConfirmationDialog.content')"
      @cancel="confirmationDialogCancel"
      @confirm="confirmationDialogConfirm"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import ConferencesTable from '@/components/admin/conferences-table/ConferencesTable.vue';
import PermissionsUtil from '@/helpers/permissions/permissions.helper';
import DialogConfirmation from '@/views/DialogConfirmation.vue';
import AppSearch from '@/components/app/app-search/AppSearch.vue';

import {
  ADD_CONFERENCE,
  ADMIN_CONFERENCE_MODULE,
  DELETE_CONFERENCE,
  UPDATE_CONFERENCE,
} from '@/stores/umanize-admin/actions/conference/admin-conference.actions';
import { ADMIN_EVENT_MODULE } from '@/stores/umanize-admin/actions/event/admin-event.actions';
import { GET_CONFERENCES_FOR_EVENT } from '@/stores/agnostic/actions/conference/agnostic-conference.actions';
import { APP_USER_MODULE } from '@/stores/umanize-app/actions/user/app-user.actions';
import {
  GET_PARTNERS,
  PARTNER_MODULE,
} from '@/stores/umanize-admin/actions/partners/partners.actions';

export default {
  name: 'ConferencesAdmin',
  components: {
    ConferencesTable,
    DialogConfirmation,
    AppSearch,
  },
  data: () => ({
    search: '',
    showConfirmation: false,
    conferenceToDelete: null,
    conferenceIdsWithPartners: [],
  }),
  computed: {
    ...mapGetters(ADMIN_CONFERENCE_MODULE, ['conferences', 'conferencesAreLoading']),
    ...mapGetters(ADMIN_EVENT_MODULE, ['event']),
    ...mapGetters(APP_USER_MODULE, ['currentLoggedInUser', 'loggedInUserRoles']),
    ...mapGetters(PARTNER_MODULE, ['partners']),
    canEdit() {
      return PermissionsUtil.isAuthorized(
        ['permission.conferences.canUpdatePartial'],
        this.loggedInUserRoles,
        this.$route.params.eventId,
      );
    },
    canCreate() {
      return PermissionsUtil.isAuthorized(
        ['permission.conferences.canCreate'],
        this.loggedInUserRoles,
        this.$route.params.eventId,
      );
    },
    canDelete() {
      return PermissionsUtil.isAuthorized(
        ['permission.conferences.canDelete'],
        this.loggedInUserRoles,
        this.$route.params.eventId,
      );
    },
  },
  methods: {
    ...mapActions(ADMIN_CONFERENCE_MODULE, [
      UPDATE_CONFERENCE,
      ADD_CONFERENCE,
      GET_CONFERENCES_FOR_EVENT,
      DELETE_CONFERENCE,
    ]),
    ...mapActions(PARTNER_MODULE, [GET_PARTNERS]),
    onSearchChange(search) {
      this.search = search;
    },
    editConference(item) {
      const { eventId } = this.$route.params;
      this.$router.push({
        name: 'EditConferenceAdmin',
        params: {
          eventId,
          conferenceId: item.id,
        },
      });
    },
    addConference() {
      const { eventId } = this.$route.params;
      this.$router.push({
        name: 'AddConferenceAdmin',
        params: {
          eventId,
        },
      });
    },
    deleteConference(conference) {
      this.showConfirmation = true;
      this.conferenceToDelete = { ...conference };
    },
    getConferences() {
      const { eventId } = this.$route.params;
      this[GET_CONFERENCES_FOR_EVENT](eventId);
      this.conferences.forEach((conference) => this.retrievePartners(conference));
    },
    async retrievePartners(conference) {
      const { eventId } = this.$route.params;
      await this[GET_PARTNERS]({
        eventId,
        conferenceId: conference.id,
      });
      if (this.partners.length) {
        this.conferenceIdsWithPartners.push(conference.id);
      }
    },
    confirmationDialogCancel() {
      this.showConfirmation = false;
      this.conferenceToDelete = null;
    },
    async confirmationDialogConfirm() {
      await this[DELETE_CONFERENCE](this.conferenceToDelete);
      this.getConferences();
      this.conferenceToDelete = null;
      this.showConfirmation = false;
    },
  },
  mounted() {
    this.getConferences();
  },
};
</script>

<style lang="scss" scoped>
@import '../../../styles/core/variables';
@import '../../../styles/core/mixins';

@include admin-layout();

#conferences {
  padding: 50px;
  min-height: calc(100vh - 6rem);
}

.conferences {
  &__title {
    margin-bottom: 10px;
  }

  &__actions {
    display: flex;
    align-items: center;

    & > *:not(:last-child) {
      margin-right: 10px;
    }
  }
}
</style>
