<template>
  <v-container id="role">
    <v-form :disabled="!canUpdateRoles">
      <h1 class="role__title">
        {{ canUpdateRoles ? $t('admin.roles.editTitle') : $t('admin.roles.viewTitle') }}
      </h1>

      <v-card class="role__content">
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="roleForm.name"
                :disabled="isEditing"
                :label="$t('admin.roles.name')"
                :persistent-placeholder="true"
                placeholder="-"
              />
            </v-col>
          </v-row>

          <v-row v-for="rightCategory in rightCategories" :key="rightCategory">
            <v-col cols="12">
              <h2>{{ $t(`admin.roles.${rightCategory}.title`) }}</h2>
            </v-col>

            <v-col
              cols="12"
              sm="6"
              xl="4"
              v-for="rightItem in rightCategoryItems(rightCategory)"
              :key="rightItem"
            >
              <v-checkbox
                v-model="roleForm.permissions[rightCategory][rightItem]"
                :label="$t(`admin.roles.${rightCategory}.${rightItem}`)"
                hide-details="auto"
              />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-row>
            <v-col class="text-right">
              <v-btn class="mr-4" color="primary" outlined @click="goBackToList">
                {{ $t('globals.cancel') }}
              </v-btn>

              <v-btn color="primary" type="submit" :disabled="!canUpdateRoles" @click="save">
                {{ $t('globals.save') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';
import { mapActions, mapGetters } from 'vuex';

import { Role } from '@/models';
import PermissionsUtil from '@/helpers/permissions/permissions.helper';

import {
  CLEAR_ROLE,
  GET_ROLE,
  ROLES_MODULE,
  SAVE_ROLE,
} from '@/stores/umanize-admin/actions/roles/roles.actions';
import { APP_USER_MODULE } from '@/stores/umanize-app/actions/user/app-user.actions';

export default {
  name: 'EditRoleAdmin',
  data: () => ({
    defaultRole: new Role(),
    roleForm: new Role(),
  }),
  computed: {
    ...mapGetters(ROLES_MODULE, ['role']),
    ...mapGetters(APP_USER_MODULE, ['loggedInUserRoles']),
    isEditing() {
      return this.$route.path.includes('edit');
    },
    rightCategories() {
      return this.roleForm.permissions && Object.keys(this.roleForm.permissions);
    },
    canUpdateRoles() {
      return PermissionsUtil.isAuthorized(['permission.roles.canUpdate'], this.loggedInUserRoles);
    },
  },
  methods: {
    ...mapActions(ROLES_MODULE, [GET_ROLE, SAVE_ROLE, CLEAR_ROLE]),
    initForm() {
      this.roleForm = {
        ...this.defaultRole,
        ...cloneDeep(this.role),
      };
    },
    goBackToList() {
      this.$router.push({ name: 'RolesAdmin' });
    },
    rightCategoryItems(category) {
      return this.roleForm.permissions && Object.keys(this.roleForm.permissions[category]);
    },
    async save() {
      await this[SAVE_ROLE](this.roleForm);
      this.goBackToList();
    },
  },
  async mounted() {
    const { roleId } = this.$route.params;
    if (roleId) {
      await this[GET_ROLE](roleId);
    }
    await this.initForm();
  },
  beforeDestroy() {
    this[CLEAR_ROLE]();
  },
};
</script>

<style lang="scss" scoped>
@import '../../../../styles/core/variables';
@import '../../../../styles/core/mixins';

@include admin-layout();

#role {
  background-color: var(--v-adminBackground-base);
  padding: 50px 25px;
}

.role {
  &__title {
    font-size: $xx-large-font-size;
    font-weight: $regular;
    margin-bottom: 10px;
  }

  &__content {
    @include admin-card;

    margin: 0;
    padding: 20px 40px;
  }
}
</style>
