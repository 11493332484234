<template>
  <v-container id="event" @submit.prevent @submit="onSave">
    <v-form v-model="valid" :disabled="!canUpdateEventsPartially" ref="form">
      <div class="d-flex justify-space-between">
        <h1 v-if="isEditing" class="event__title">
          {{ eventForm.name }} - {{ $t('event.edit.title.heading') }}
        </h1>

        <h1 v-else>{{ $t('event.edit.title.addHeading') }}</h1>

        <v-btn
          color="primary"
          class="event__custom-ui"
          @click="goToCustomUi"
          :disabled="!canCreateCustomUi"
          dense
          outlined
          depressed
        >
          {{ $t('admin.custom-ui.CTA') }}
        </v-btn>
      </div>

      <v-container class="event__content">
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="eventForm.name"
              placeholder="-"
              :persistent-placeholder="true"
              :rules="nameRule"
              :label="$t('event.edit.name')"
              hide-details="auto"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="6">
            <DateTimePicker
              v-model="startDate"
              :label="$t('event.edit.startDate')"
              hide-details="auto"
            >
              <template v-slot:actions="{ parent }">
                <v-btn color="grey darken-1" text @click="parent.display = false">
                  {{ $t('globals.cancel') }}
                </v-btn>

                <v-btn color="green darken-1" text @click="parent.okHandler">
                  {{ $t('globals.ok') }}
                </v-btn>
              </template>

              <template v-slot:dateIcon>
                <v-icon>mdi-calendar</v-icon>
              </template>

              <template v-slot:timeIcon>
                <v-icon>mdi-clock</v-icon>
              </template>
            </DateTimePicker>
          </v-col>

          <v-col cols="12" sm="6">
            <DateTimePicker
              v-model="endDate"
              hide-details="auto"
              :label="$t('event.edit.endDate')"
              :textFieldProps="{ rules: endDateRules }"
              :datePickerProps="{ allowedDates: allowedDates }"
            >
              <template v-slot:actions="{ parent }">
                <v-btn color="grey darken-1" text @click="parent.display = false">
                  {{ $t('globals.cancel') }}
                </v-btn>

                <v-btn color="green darken-1" text @click="parent.okHandler">
                  {{ $t('globals.ok') }}
                </v-btn>
              </template>

              <template v-slot:dateIcon>
                <v-icon>mdi-calendar</v-icon>
              </template>

              <template v-slot:timeIcon>
                <v-icon>mdi-clock</v-icon>
              </template>
            </DateTimePicker>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6">
            <DateTimePicker
              v-model="submitKioskDeadline"
              :label="$t('event.edit.submitKioskDeadline')"
            >
              <template v-slot:actions="{ parent }">
                <v-btn color="grey darken-1" text @click="parent.display = false">
                  {{ $t('globals.cancel') }}
                </v-btn>

                <v-btn color="green darken-1" text @click="parent.okHandler">
                  {{ $t('globals.ok') }}
                </v-btn>
              </template>

              <template v-slot:dateIcon>
                <v-icon>mdi-calendar</v-icon>
              </template>

              <template v-slot:timeIcon>
                <v-icon>mdi-clock</v-icon>
              </template>
            </DateTimePicker>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="6">
            <v-select
              v-model="eventForm.lang"
              :items="languages"
              item-value="id"
              item-text="label"
              :label="$t('event.edit.language')"
              :disabled="!canUpdateEventsPartially"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-autocomplete
              v-model="eventForm.timezone"
              :items="timezones"
              item-value="id"
              item-text="label"
              :label="$t('event.edit.timezone')"
              :disabled="!canUpdateEventsPartially"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <p class="options__title">{{ $t('event.edit.title.options') }}</p>
          </v-col>

          <v-col cols="12" md="6" lg="4">
            <v-checkbox
              v-model="eventForm.options.conferences"
              :label="$t('event.edit.options.hasConferences')"
              :disabled="!canUpdateEvents"
              hide-details
            />
          </v-col>

          <v-col cols="12" md="6" lg="4">
            <v-checkbox
              v-model="eventForm.options.recommendedActivities"
              :label="$t('event.edit.options.recommended-activities')"
              :disabled="!canUpdateEvents"
              hide-details
            />
          </v-col>

          <v-col cols="12" md="6" lg="4">
            <v-checkbox
              v-model="eventForm.options.smartMatching"
              :label="$t('event.edit.options.smart-matching')"
              :disabled="!canUpdateEvents"
              hide-details
            />
          </v-col>

          <v-col cols="12" md="6" lg="4">
            <v-checkbox
              v-model="eventForm.options.participantList"
              :label="$t('event.edit.options.participant-list')"
              :disabled="!canUpdateEvents"
              hide-details
            />
          </v-col>

          <v-col cols="12" md="6" lg="4">
            <v-checkbox
              v-model="eventForm.options.generalAssembly"
              :label="$t('event.edit.options.general-assembly')"
              :disabled="!canUpdateEvents"
              hide-details
            />
          </v-col>

          <v-col cols="12" md="6" lg="4">
            <v-checkbox
              v-model="eventForm.options.appointments"
              :label="$t('event.edit.options.hasAppointments')"
              :disabled="!canUpdateEvents"
              hide-details
            />
          </v-col>

          <v-col cols="12" md="6" lg="4">
            <v-checkbox
              v-model="eventForm.options.contentLibrary"
              :label="$t('event.edit.options.content-library.value')"
              :disabled="!canUpdateEvents"
              hide-details
            />
          </v-col>

          <v-col cols="12" md="6" lg="4">
            <v-checkbox
              v-model="eventForm.options.discussionGroups"
              :label="$t('event.edit.options.discussion-groups.value')"
              :disabled="!canUpdateEvents"
              hide-details
            />
          </v-col>

          <v-col cols="12" md="6" lg="4">
            <v-checkbox
              v-model="eventForm.options.exposition"
              :label="$t('event.edit.options.exposition')"
              :disabled="!canUpdateEvents"
              hide-details
            />
          </v-col>
        </v-row>

        <v-divider />

        <v-row>
          <v-col cols="12" md="12" lg="6">
            <p class="screen__title">{{ $t('event.edit.lobby-background') }}</p>
            <v-img class="img-container" height="140" width="300" :src="eventForm.lobbyBackground">
              <p v-if="!eventForm.lobbyBackground">
                {{ $t('event.edit.noBackground') }}
              </p>
            </v-img>

            <div class="screen__actions mt-3">
              <v-btn
                class="mr-2"
                color="primary"
                @click="openPictureDialog('lobbyBackground')"
                v-if="canUpdateEventsPartially"
              >
                {{ $t('globals.upload') }}
                <v-icon>mdi-tray-arrow-down</v-icon>
              </v-btn>

              <v-btn
                color="error"
                @click="removePicture('lobbyBackground')"
                v-if="canUpdateEventsPartially && !!eventForm.lobbyBackground"
              >
                {{ $t('globals.reset') }}
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>
          </v-col>

          <v-col cols="12" md="12" lg="6">
            <p class="screen__title">{{ $t('event.edit.options.content-library.background') }}</p>
            <v-img
              class="img-container"
              height="140"
              width="300"
              :src="eventForm.options.contentLibraryOptions.backgroundImageUrl"
            >
              <p v-if="!eventForm.options.contentLibraryOptions.backgroundImageUrl">
                {{ $t('event.edit.noBackground') }}
              </p>
            </v-img>

            <div class="screen__actions mt-3">
              <v-btn
                class="mr-2"
                color="primary"
                :disabled="!eventForm.options.contentLibrary"
                v-if="canUpdateEventsPartially"
                @click="openPictureDialog('options.contentLibraryOptions.backgroundImageUrl')"
              >
                {{ $t('globals.upload') }}
                <v-icon>mdi-tray-arrow-down</v-icon>
              </v-btn>

              <v-btn
                color="error"
                :disabled="!eventForm.options.contentLibraryOptions.backgroundImageUrl"
                v-if="
                  canUpdateEventsPartially &&
                  !!eventForm.options.contentLibraryOptions.backgroundImageUrl
                "
                @click="removePicture('options.contentLibraryOptions.backgroundImageUrl')"
              >
                {{ $t('globals.reset') }}
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>
          </v-col>

          <v-col cols="12" md="12" lg="6">
            <p class="screen__title">{{ $t('event.edit.options.discussion-groups.background') }}</p>
            <v-img
              class="img-container"
              height="140"
              width="300"
              :src="eventForm.options.discussionGroupsOptions.backgroundImageUrl"
            >
              <p v-if="!eventForm.options.discussionGroupsOptions.backgroundImageUrl">
                {{ $t('event.edit.noBackground') }}
              </p>
            </v-img>

            <div class="screen__actions mt-3">
              <v-btn
                class="mr-2"
                color="primary"
                :disabled="!eventForm.options.discussionGroups"
                v-if="canUpdateEventsPartially"
                @click="openPictureDialog('options.discussionGroupsOptions.backgroundImageUrl')"
              >
                {{ $t('globals.upload') }}
                <v-icon>mdi-tray-arrow-down</v-icon>
              </v-btn>
              <v-btn
                color="error"
                :disabled="!eventForm.options.discussionGroupsOptions.backgroundImageUrl"
                v-if="
                  canUpdateEventsPartially &&
                  !!eventForm.options.discussionGroupsOptions.backgroundImageUrl
                "
                @click="removePicture('options.discussionGroupsOptions.backgroundImageUrl')"
              >
                {{ $t('globals.reset') }}
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>
          </v-col>

          <v-col cols="12" md="12" lg="6">
            <p class="screen__title">
              {{ $t('event.edit.options.showrooms-options.loading-background') }}
            </p>
            <v-img
              class="img-container"
              height="140"
              width="300"
              :src="eventForm.options.showroomOptions.loadingBackground"
            >
              <p v-if="!eventForm.options.showroomOptions.loadingBackground">
                {{ $t('event.edit.noBackground') }}
              </p>
            </v-img>

            <div class="screen__actions mt-3">
              <v-btn
                class="mr-2"
                color="primary"
                :disabled="!eventForm.options.exposition || !canUpdateEventsPartially"
                v-if="canUpdateEventsPartially"
                @click="openPictureDialog('options.showroomOptions.loadingBackground')"
              >
                {{ $t('globals.upload') }}
                <v-icon>mdi-tray-arrow-down</v-icon>
              </v-btn>

              <v-btn
                color="error"
                :disabled="!eventForm.options.exposition || !canUpdateEventsPartially"
                v-if="
                  canUpdateEventsPartially && !!eventForm.options.showroomOptions.loadingBackground
                "
                @click="removePicture('options.showroomOptions.loadingBackground')"
              >
                {{ $t('globals.reset') }}
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>

        <v-divider />

        <v-row>
          <v-col cols="12" md="6" lg="4">
            <p class="logo__title">
              {{ $t('event.edit.main-partner-logo') }}
              <info-tooltip>{{ $t('event.edit.main-partner-logo-tooltip') }}</info-tooltip>
            </p>
            <v-img
              class="img-container"
              max-height="150"
              max-width="150"
              :contain="true"
              :src="eventForm.mainPartnerLogo"
            >
              <v-btn
                class="actions__delete"
                color="error"
                @click="removePicture('mainPartnerLogo')"
                v-if="canUpdateEventsPartially && !!eventForm.mainPartnerLogo"
                icon
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>

              <p v-else>{{ $t('event.edit.noLogo') }}</p>
            </v-img>

            <v-btn
              class="mr-2 mt-3"
              color="primary"
              @click="openPictureDialog('mainPartnerLogo')"
              v-if="canUpdateEventsPartially"
            >
              {{ $t('globals.upload') }}
              <v-icon>mdi-tray-arrow-down</v-icon>
            </v-btn>
          </v-col>

          <v-col cols="12" md="6" lg="4">
            <p class="logo__title">
              {{ $t('event.edit.email-logo') }}
              <info-tooltip>{{ $t('event.edit.email-logo-tooltip') }}</info-tooltip>
            </p>

            <v-img
              class="img-container"
              max-height="150"
              max-width="150"
              :contain="true"
              :src="eventForm.coverImage"
            >
              <v-btn
                class="actions__delete"
                color="error"
                @click="removePicture('coverImage')"
                v-if="canUpdateEventsPartially && !!eventForm.coverImage"
                icon
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>

              <p v-else>{{ $t('event.edit.noLogo') }}</p>
            </v-img>

            <v-btn
              class="mr-2 mt-3"
              color="primary"
              @click="openPictureDialog('coverImage')"
              v-if="canUpdateEventsPartially"
            >
              {{ $t('globals.upload') }}
              <v-icon>mdi-tray-arrow-down</v-icon>
            </v-btn>
          </v-col>

          <v-col cols="12" md="6" lg="4">
            <p class="logo__title">
              {{ $t('event.edit.web-logo') }}
              <info-tooltip>{{ $t('event.edit.web-logo-tooltip') }}</info-tooltip>
            </p>

            <v-img
              class="img-container"
              max-height="150"
              max-width="150"
              :contain="true"
              :src="eventForm.logoWeb"
            >
              <v-btn
                class="actions__delete"
                color="error"
                @click="removePicture('logoWeb')"
                v-if="canUpdateEventsPartially && !!eventForm.logoWeb"
                icon
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>

              <p v-else>{{ $t('event.edit.noLogo') }}</p>
            </v-img>

            <v-btn
              class="mr-2 mt-3"
              color="primary"
              @click="openPictureDialog('logoWeb')"
              v-if="canUpdateEventsPartially"
            >
              {{ $t('globals.upload') }}
              <v-icon>mdi-tray-arrow-down</v-icon>
            </v-btn>
          </v-col>

          <v-col cols="12" md="6" lg="4">
            <p class="logo__title">
              {{ $t('event.edit.mobile-logo') }}
              <info-tooltip>{{ $t('event.edit.mobile-logo-tooltip') }}</info-tooltip>
            </p>

            <v-img
              class="img-container"
              max-height="150"
              max-width="150"
              :contain="true"
              :src="eventForm.logoMobile"
            >
              <v-btn
                class="actions__delete"
                color="error"
                @click="removePicture('logoMobile')"
                v-if="canUpdateEventsPartially && !!eventForm.logoMobile"
                icon
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>

              <p v-else>{{ $t('event.edit.noLogo') }}</p>
            </v-img>

            <v-btn
              class="mr-2 mt-3"
              color="primary"
              @click="openPictureDialog('logoMobile')"
              v-if="canUpdateEventsPartially"
            >
              {{ $t('globals.upload') }}
              <v-icon>mdi-tray-arrow-down</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-divider />

        <v-row>
          <v-col cols="12">
            <h2 class="title">{{ $t('event.edit.title.optional') }}</h2>
          </v-col>

          <v-col cols="12" sm="12">
            <v-checkbox
              v-model="eventForm.public"
              :label="$t('event.edit.public')"
              :disabled="!canUpdateEvents"
              hide-details
            />
          </v-col>

          <v-col cols="12" sm="12">
            <v-combobox
              :disabled="!canCreateEvents"
              :elevation="0"
              v-model="eventForm.tags"
              :items="eventTags"
              chips
              clearable
              :label="$t('admin.tags.title')"
              multiple
            >
              <template v-slot:selection="{ attrs, disabled, item, select, selected }">
                <v-chip
                  class="tag"
                  v-bind="attrs"
                  :input-value="selected"
                  :disabled="disabled"
                  close
                  @click="select"
                  @click:close="removeTag(item)"
                >
                  <strong>{{ item }}</strong>
                </v-chip>
              </template>
            </v-combobox>
          </v-col>

          <v-col cols="12" sm="12">
            <v-textarea
              v-model="eventForm.description"
              placeholder="-"
              :persistent-placeholder="true"
              :label="$t('event.edit.description')"
              :rows="2"
              auto-grow
              no-resize
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" lg="4">
            <v-text-field
              v-model="eventForm.options.showroomOptions.vimeoVideoURL"
              placeholder="-"
              :persistent-placeholder="true"
              :disabled="!eventForm.options.exposition || !canUpdateEvents"
              :label="$t('event.edit.options.showrooms-options.vimeo')"
            />
          </v-col>

          <v-col cols="12" lg="4">
            <v-text-field
              v-model="eventForm.options.showroomOptions.unityLoaderURL"
              placeholder="-"
              :persistent-placeholder="true"
              :disabled="!eventForm.options.exposition || !canUpdateEvents"
              :label="$t('event.edit.options.showrooms-options.unity-loader')"
            />
          </v-col>

          <v-col cols="12" lg="4">
            <v-text-field
              v-model="eventForm.options.showroomOptions.unityContainerURL"
              placeholder="-"
              :persistent-placeholder="true"
              :disabled="!eventForm.options.exposition || !canUpdateEvents"
              :label="$t('event.edit.options.showrooms-options.unity-container')"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="12">
            <p class="promotional-video__title">
              {{ $t('event.edit.promotional-video-title') }}
              <info-tooltip>{{ $t('event.edit.promotional-video-title-tooltip') }}</info-tooltip>
            </p>
            <v-text-field
              v-model="eventForm.promotionalVideoTitle"
              placeholder="-"
              :persistent-placeholder="true"
              :rules="promotionalVideoTitleRule"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="12">
            <p class="promotional-video__title">
              {{ $t('event.edit.promotional-video') }}
              <info-tooltip>{{ $t('event.edit.promotional-video-tooltip') }}</info-tooltip>
            </p>
            <v-text-field
              v-model="eventForm.promotionalVideo"
              placeholder="-"
              :persistent-placeholder="true"
              :rules="vimeoFormatRule"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="12">
            <base-input-multiple
              :label="$t('event.edit.cover-images.label')"
              :placeholder="$t('event.edit.cover-images.placeholder')"
              :values="eventForm.coverImages"
              :has-internal-add="false"
              :disabled="!canUpdateEventsPartially"
              @on-change="onMultipleChange('coverImages', $event)"
              @add="openDialog('coverImages')"
            />
          </v-col>

          <v-col cols="12" sm="12">
            <image-description-input-multiple
              :values="this.eventForm.options.eventLobbyOptions.imageOptions"
              :disabled="!canUpdateEventsPartially"
              @on-change="onMultipleChange('lobbyOptionsImages', $event)"
              @add="openDialog('lobbyOptionsImages')"
              @update="(index) => openDialog('lobbyOptionsImages', index)"
              @set-is-valid-lobby-images="setIsValidLobbyImages"
            />
          </v-col>

          <v-col cols="12" sm="12">
            <external-links
              :values="eventForm.externalLinks"
              @on-change="onMultipleChange('externalLinks', $event)"
            />
          </v-col>

          <v-col cols="12" sm="12">
            <p class="exhibitors__title">
              {{ $t('event.edit.exhibitorsStatus.title') }}
              <info-tooltip>{{ $t('event.edit.exhibitorsStatus.tooltip') }}</info-tooltip>
            </p>
            <v-switch
              v-model="eventForm.exhibitorsStatus"
              color="primary"
              inset
              true-value="active"
              false-value="inactive"
              :disabled="!canUpdateEventsPartially"
              :label="
                eventForm.exhibitorsStatus === 'active'
                  ? $t('event.edit.exhibitorsStatus.active')
                  : $t('event.edit.exhibitorsStatus.inactive')
              "
            />
          </v-col>

          <v-spacer />

          <v-col class="text-right" v-if="canUpdateEventsPartially">
            <v-btn class="mx-2" color="primary" outlined @click="onReset">
              {{ $t('globals.reset') }}
            </v-btn>

            <v-btn
              class="mx-2"
              color="primary"
              :loading="eventIsLoading || eventIsSaving"
              :disabled="eventIsLoading || !valid || !isValidLobbyImages"
              type="submit"
            >
              {{ $t('globals.save') }}
            </v-btn>
          </v-col>
        </v-row>

        <upload-pictures :dialog="dialog" @save="uploadPictures" @close="closeDialog" />
        <upload-pictures
          :dialog="pictureDialog"
          :is-multiple="false"
          :height="pictureDialogHeight"
          :width="pictureDialogWidth"
          @save="uploadPicture"
          @close="closePictureDialog"
        />
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';
import set from 'lodash.set';
import { DateTime } from 'luxon';
import { mapActions, mapGetters } from 'vuex';

import { AppEvent } from '@/models';

import VALIDATORS from '@/helpers/forms/validators.helper';
import DateUtil from '@/helpers/date/date.helper';
import PermissionsUtil from '@/helpers/permissions/permissions.helper';

import InfoTooltip from '@/components/info-tooltip/InfoTooltip.vue';
import BaseInputMultiple from '@/components/base-input-multiple/BaseInputMultiple.vue';
import UploadPictures from '@/components/admin/upload-pictures/UploadPictures.vue';
import ExternalLinks from '@/components/admin/external-links/ExternalLinks.vue';
import DateTimePicker from '@/components/datetime-picker/DateTimePicker.vue';

import {
  SHARED_FILE_MODULE,
  UPLOAD_FILE,
} from '@/stores/shared/actions/file-upload/file-upload.actions';
import {
  ADMIN_EVENT_MODULE,
  SAVE_EVENT,
} from '@/stores/umanize-admin/actions/event/admin-event.actions';
import { APP_USER_MODULE } from '@/stores/umanize-app/actions/user/app-user.actions';
import { ADMIN_TAG_MODULE, GET_TAGS } from '@/stores/umanize-admin/actions/tag/admin-tag.actions';

import ImageDescriptionInputMultiple from './image-description-input-multiple/ImageDescriptionInputMultiple.vue';

export default {
  name: 'EventAdmin',
  components: {
    BaseInputMultiple,
    ExternalLinks,
    InfoTooltip,
    UploadPictures,
    DateTimePicker,
    ImageDescriptionInputMultiple,
  },
  data: () => ({
    valid: false,
    dialog: false,
    pictureDialogType: false,
    indexImageUrl: null,
    isValidLobbyImages: true,
    pictureDialog: false,
    defaultEvent: new AppEvent(),
    eventForm: new AppEvent(),
    startDate: null,
    endDate: null,
    submitKioskDeadline: null,
    nameRule: VALIDATORS.EVENTS.NAME,
    promotionalVideoTitleRule: VALIDATORS.EVENTS.PROMOTIONAL_VIDEO_TITLE,
    vimeoFormatRule: [VALIDATORS.VIDEO.TYPE.IS_VIMEO],
    timezones: DateUtil.getTimeZoneList(),
    pictureDialogConfig: {
      mainPartnerLogo: {
        height: 200,
        width: 250,
      },
      coverImage: {
        height: 480,
        width: 768,
      },
      logoWeb: {
        height: 200,
        width: 250,
      },
      logoMobile: {
        height: 200,
        width: 250,
      },
      lobbyBackground: {
        height: 1080,
        width: 1920,
      },
      'options.discussionGroupsOptions.backgroundImageUrl': {
        height: 1080,
        width: 1920,
      },
      'options.contentLibraryOptions.backgroundImageUrl': {
        height: 1080,
        width: 1920,
      },
      'options.showroomOptions.loadingBackground': {
        height: 1080,
        width: 1920,
      },
    },
    pictureDialogHeight: 0,
    pictureDialogWidth: 0,
  }),
  computed: {
    ...mapGetters(ADMIN_EVENT_MODULE, ['event', 'eventIsLoading', 'eventIsSaving']),
    ...mapGetters(SHARED_FILE_MODULE, ['file']),
    ...mapGetters(APP_USER_MODULE, ['loggedInUserRoles']),
    ...mapGetters(ADMIN_TAG_MODULE, ['tagsByType']),
    languages() {
      return [
        {
          id: 'fr',
          label: this.$t('globals.language.french'),
        },
        {
          id: 'en',
          label: this.$t('globals.language.english'),
        },
      ];
    },

    eventTags() {
      return this.tagsByType('event').map((tag) => tag.name);
    },
    endDateRules() {
      if (this.endDate && this.startDate) {
        return [
          ...VALIDATORS.REQUIRED.DATE,
          VALIDATORS.DATE.GREATER_THAN(
            DateTime.fromJSDate(this.startDate),
            this.$t('event.edit.startDate'),
          ),
        ];
      }
      return VALIDATORS.REQUIRED.DATE;
    },
    isEditing() {
      return !!this.$route.params.eventId;
    },
    canCreateEvents() {
      return PermissionsUtil.isAuthorized(
        ['permission.events.canCreate'],
        this.loggedInUserRoles,
        this.$route.params.eventId,
      );
    },
    canUpdateEvents() {
      return PermissionsUtil.isAuthorized(
        ['permission.events.canUpdate'],
        this.loggedInUserRoles,
        this.$route.params.eventId,
      );
    },
    canUpdateEventsPartially() {
      return (
        this.canUpdateEvents ||
        PermissionsUtil.isAuthorized(
          ['permission.events.canUpdatePartial'],
          this.loggedInUserRoles,
          this.$route.params.eventId,
        )
      );
    },
    canCreateCustomUi() {
      return PermissionsUtil.isAuthorized(
        ['permission.customUi.canCreate'],
        this.loggedInUserRoles,
        this.$route.params.eventId,
      );
    },
  },
  methods: {
    ...mapActions(ADMIN_EVENT_MODULE, [SAVE_EVENT]),
    ...mapActions(SHARED_FILE_MODULE, [UPLOAD_FILE]),
    ...mapActions(ADMIN_TAG_MODULE, [GET_TAGS]),
    initForm() {
      // use the current selected event if is editing an event, else create a new event
      const event = this.isEditing ? { ...this.event } : new AppEvent();
      this.eventForm = {
        ...this.defaultEvent,
        ...cloneDeep(event),
        lang: 'fr',
        timezone: 'America/Montreal',
        tags: event.tags ? event.tags.split(',') : [],
      };

      this.startDate = this.eventForm.startDate
        ? DateTime.fromISO(this.eventForm.startDate).toJSDate()
        : DateTime.local().toJSDate();
      this.endDate = this.eventForm.endDate
        ? DateTime.fromISO(this.eventForm.endDate).toJSDate()
        : null;
      this.submitKioskDeadline = this.eventForm.options.eventKioskOptions?.submitKioskDeadline
        ? DateTime.fromISO(this.eventForm.options.eventKioskOptions?.submitKioskDeadline).toJSDate()
        : null;
    },
    onMultipleChange(key, values) {
      this.eventForm[key] = values;
    },
    async onSave() {
      if (!this.$refs.form.validate()) return;
      const savedEvent = {
        ...this.eventForm,
        startDate: DateTime.fromJSDate(this.startDate),
        endDate: DateTime.fromJSDate(this.endDate),
        options: {
          ...this.eventForm.options,
          eventKioskOptions: {
            ...this.eventForm.options.eventKioskOptions,
            submitKioskDeadline: DateTime.fromJSDate(this.submitKioskDeadline),
          },
        },
        tags: this.eventForm.tags.join(','),
      };

      await this[SAVE_EVENT](savedEvent);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    onReset() {
      this.initForm();
    },
    eventLobbyImages() {
      return this.eventForm.options?.eventLobbyOptions;
    },
    setIsValidLobbyImages(isValidLobbyImages) {
      this.isValidLobbyImages = isValidLobbyImages;
    },
    openPictureDialog(type) {
      this.pictureDialogHeight = this.pictureDialogConfig[type].height;
      this.pictureDialogWidth = this.pictureDialogConfig[type].width;
      this.dialogType = type;
      this.pictureDialog = true;
    },
    closePictureDialog() {
      this.dialogType = null;
      this.pictureDialog = false;
    },
    async uploadPicture(picture) {
      await this[UPLOAD_FILE](picture[0]);
      set(this.eventForm, this.dialogType, this.file.url);
      this.closePictureDialog();
    },
    removePicture(prop) {
      set(this.eventForm, prop, null);
    },
    openDialog(type, index = null) {
      this.indexImageUrl = index;
      this.dialog = true;
      this.dialogType = type;
    },
    closeDialog() {
      this.dialog = false;
      this.dialogType = null;
    },
    async uploadPictures(pictures) {
      // eslint-disable-next-line no-restricted-syntax
      for await (const picture of pictures) {
        await this[UPLOAD_FILE](picture);

        if (this.dialogType === 'coverImages') {
          this.eventForm[this.dialogType] = [
            ...(this.eventForm[this.dialogType] || []),
            this.file.url,
          ];
        } else if (this.dialogType === 'lobbyOptionsImages') {
          const isEditing = this.indexImageUrl !== null;

          if (isEditing) {
            this.eventForm.options.eventLobbyOptions.imageOptions[
              this.indexImageUrl
            ].imageUrl = this.file.url;
          } else {
            this.eventForm.options.eventLobbyOptions.imageOptions = [
              ...(this.eventForm.options.eventLobbyOptions.imageOptions || []),
              { imageUrl: this.file.url, description: '' },
            ];
          }
        }
      }
      this.closeDialog();
    },
    allowedDates(val) {
      return val >= DateTime.fromJSDate(this.startDate).toISODate();
    },
    goToCustomUi() {
      this.$router.push({
        name: 'CustomUIAdmin',
        params: {
          eventId: this.event.id,
        },
      });
    },
    removeTag(item) {
      this.eventForm.tags = [...this.eventForm.tags.filter((tag) => tag !== item)];
    },
  },
  async mounted() {
    if (this.$route.params.eventId) {
      await this[GET_TAGS]({ eventId: this.$route.params.eventId });
    }
    this.initForm();
  },
  watch: {
    event() {
      this.initForm();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../styles/core/variables';
@import '../../../styles/core/mixins';

@include admin-layout();

hr {
  margin: 20px 0;
}

#event {
  background-color: var(--v-adminBackground-base);
  padding: 50px;
}

.event {
  &__title {
    font-size: $x-large-font-size;
    font-weight: $regular;
    margin-bottom: 10px;
  }

  &__custom-ui {
    margin-bottom: 10px;
  }

  &__content {
    @include admin-card;

    margin: 0;
    padding: 20px 40px;
  }
}

.screen {
  &__actions {
    display: flex;
  }
}

.actions {
  &__delete {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.options {
  &__title {
    margin: 0;
  }
}

.screen,
.logo,
.exhibitors {
  &__title {
    margin-bottom: 10px;
  }
}

.options,
.screen,
.logo,
.promotional-video,
.exhibitors {
  &__title {
    font-weight: $semi-bold;
  }
}

.promotional-video {
  &__title {
    margin-bottom: 0;
  }
}

.img-container {
  height: 150px;
  width: 150px;
  border: 1px solid var(--v-gray-base);

  p {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 100%;
  }
}

.tag {
  margin-right: 5px;
  margin-bottom: 5px;
}
</style>
