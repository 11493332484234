var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"conferences-rooms"}},[_c('h2',{staticClass:"conferences-rooms__title"},[_vm._v(" "+_vm._s(_vm.event.name)+" - "+_vm._s(_vm.$t('conferenceRoom.list.title'))+" ")]),_c('div',{staticClass:"conferences-rooms__actions"},[_c('v-btn',{staticClass:"actions__create",attrs:{"large":"","depressed":"","disabled":!_vm.canCreate,"color":"primary","data-test-id":"btn-create"},on:{"click":function($event){return _vm.openDialog('edit')}}},[_vm._v(" "+_vm._s(_vm.$t('globals.add'))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-plus")])],1),_c('AppSearch',{staticClass:"actions__search",on:{"change":_vm.onSearchChange}})],1),_c('base-list',{staticClass:"mt-4",attrs:{"backgroundColor":"var(--v-adminBackground-base)","headers":_vm.headers,"items":_vm.conferenceRooms,"search":_vm.search,"loading":_vm.conferenceRoomsAreLoading,"footerProps":{
      itemsPerPageText: _vm.$t('globals.datatable.itemPerPage'),
      itemsPerPageAllText: _vm.$t('globals.datatable.itemPerPageAll'),
      pageText: _vm.$t('globals.datatable.of'),
    },"noDataText":_vm.$t('globals.datatable.noData'),"noResultsText":_vm.$t('globals.datatable.noResult')},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.public",fn:function(ref){
    var item = ref.item;
return [(!item.public)?_c('v-icon',{staticClass:"icon icon__restricted"},[_vm._v(" mdi-lock-outline ")]):_c('v-icon',{staticClass:"icon"},[_vm._v("mdi-lock-open-outline")])]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('admin-table-actions',{attrs:{"user":_vm.currentLoggedInUser,"can-edit":_vm.canUpdate,"can-delete":_vm.canDelete,"is-disabled":_vm.hasConference(item),"disabled-tooltip":_vm.$t('conferenceRoom.delete.hasContent')},on:{"edit-item":function($event){return _vm.openDialog('edit', item)},"delete-item":function($event){return _vm.openDialog('delete', item)}}})]}}],null,true)}),(_vm.dialog.edit)?_c('v-dialog',{attrs:{"content-class":"v-dialog--overlay","width":"800","data-test-id":"edit-dialog"},on:{"click:outside":function($event){return _vm.closeDialog('edit')}},model:{value:(_vm.dialog.edit),callback:function ($$v) {_vm.$set(_vm.dialog, "edit", $$v)},expression:"dialog.edit"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.event.name)+" - "+_vm._s(_vm.$t('conferenceRoom.editModal.title')))]),_c('v-card-text',[_c('edit-room-admin',{attrs:{"eventId":_vm.eventId,"conferenceRoomId":_vm.selectedConfRoomId},on:{"closeDialog":function($event){return _vm.closeDialog('edit')}}})],1)],1)],1):_vm._e(),_c('dialog-confirmation',{attrs:{"v-if":_vm.dialog.delete,"visible":_vm.dialog.delete,"title":_vm.$t('conferenceRoom.delete.deleteConfirmationDialog.title'),"content":_vm.$t('conferenceRoom.delete.deleteConfirmationDialog.content'),"data-test-id":"delete-dialog"},on:{"cancel":function($event){return _vm.closeDialog('delete')},"confirm":_vm.confirmationDialogConfirm}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }