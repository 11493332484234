<template>
  <v-container id="matching">
    <h2 class="matching__title">{{ event.name }} - {{ $t('admin.matching.name') }}</h2>

    <div class="matching__actions">
      <v-btn
        large
        depressed
        class="actions__create"
        color="primary"
        @click="addQuestion()"
        :disabled="!canCreate"
      >
        {{ $t('globals.add') }}
        <v-icon right>mdi-plus</v-icon>
      </v-btn>

      <AppSearch class="actions__search" @change="onSearchChange" />
    </div>

    <base-list
      backgroundColor="var(--v-adminBackground-base)"
      :loading="questionsAreLoading"
      :headers="headers"
      :headerProps="{ sortIcon: null }"
      :search="search"
      :items="matchingQuestions"
      :itemsPerPage="10"
      :footerProps="{
        itemsPerPageText: $t('globals.datatable.itemPerPage'),
        itemsPerPageAllText: $t('globals.datatable.itemPerPageAll'),
        pageText: $t('globals.datatable.of'),
      }"
      :noDataText="$t('globals.datatable.noData')"
      :noResultsText="$t('globals.datatable.noResult')"
    >
      <template v-slot:[`item.priority`]="{ item }">
        <span>{{ item.priority }}</span>
      </template>

      <template v-slot:[`item.exhibitor`]="{ item }">
        <span>{{ item.questions.exhibitor }}</span>
      </template>

      <template v-slot:[`item.visitor`]="{ item }">
        <span>{{ item.questions.visitor }}</span>
      </template>

      <template v-slot:[`item.answers`]="{ item }">
        <span>{{ item.answers.length }}</span>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <admin-table-actions
          :user="currentLoggedInUser"
          :canDelete="canDeletePartial"
          @edit-item="editQuestion(item)"
          @generate-certificate="openGenerateCertsDialog(item)"
          :is-disabled="!canDelete(item)"
          :disabled-tooltip="$t('admin.matching.question.delete.hasUserAnswers')"
          :can-edit="canUpdate"
          :has-certificate="canCreate"
        />
      </template>
    </base-list>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import AdminTableActions from '@/components/admin/admin-table-actions/AdminTableActions.vue';
import BaseList from '@/components/base-list/BaseList.vue';
import AppSearch from '@/components/app/app-search/AppSearch.vue';
import PermissionsUtil from '@/helpers/permissions/permissions.helper';

import { ADMIN_EVENT_MODULE } from '@/stores/umanize-admin/actions/event/admin-event.actions';
import {
  ADMIN_MATCHING_MODULE,
  DELETE_MATCHING_QUESTION,
} from '@/stores/umanize-admin/actions/matching/admin-matching.actions';
import { GET_MATCHING_QUESTIONS } from '@/stores/agnostic/actions/matching/matching.actions';
import { APP_USER_MODULE } from '@/stores/umanize-app/actions/user/app-user.actions';

export default {
  name: 'MatchingAdmin',
  components: {
    AdminTableActions,
    BaseList,
    AppSearch,
  },
  data: () => ({
    search: '',
  }),
  computed: {
    ...mapGetters(ADMIN_MATCHING_MODULE, ['matchingQuestions', 'questionsAreLoading']),
    ...mapGetters(ADMIN_EVENT_MODULE, ['event']),
    ...mapGetters(APP_USER_MODULE, ['currentLoggedInUser', 'loggedInUserRoles']),
    headers() {
      return [
        {
          text: this.$t('admin.matching.headers.exhibitor'),
          value: 'exhibitor',
          sortable: false,
        },
        {
          text: this.$t('admin.matching.headers.visitor'),
          value: 'visitor',
          sortable: false,
        },
        {
          text: this.$t('admin.matching.headers.answers'),
          value: 'answers',
          sortable: false,
        },
        {
          text: this.$t('admin.matching.headers.priority'),
          value: 'priority',
          sortable: false,
        },
        {
          value: 'actions',
          width: '100px',
        },
      ];
    },
    eventId() {
      return this.$route.params.eventId;
    },
    canUpdate() {
      return PermissionsUtil.isAuthorized(
        ['permission.smartMatchings.canUpdate'],
        this.loggedInUserRoles,
        this.eventId,
      );
    },
    canCreate() {
      return PermissionsUtil.isAuthorized(
        ['permission.smartMatchings.canCreate'],
        this.loggedInUserRoles,
        this.eventId,
      );
    },
    canDeletePartial() {
      return PermissionsUtil.isAuthorized(
        ['permission.smartMatchings.canDeletePartial'],
        this.loggedInUserRoles,
        this.eventId,
      );
    },
  },
  methods: {
    ...mapActions(ADMIN_MATCHING_MODULE, [GET_MATCHING_QUESTIONS, DELETE_MATCHING_QUESTION]),
    onSearchChange(search) {
      this.search = search;
    },
    editQuestion(item) {
      this.$router.push({
        name: 'EditMatchingAdmin',
        params: {
          eventId: this.eventId,
          questionId: item.id,
        },
      });
    },
    addQuestion() {
      this.$router.push({
        name: 'AddMatchingAdmin',
        params: {
          eventId: this.eventId,
        },
      });
    },
    async deleteQuestion(item) {
      await this[DELETE_MATCHING_QUESTION]({
        eventId: this.eventId,
        questionId: item.id,
      });
      this.getQuestions();
    },
    canDelete(question) {
      return (
        PermissionsUtil.isAuthorized(
          ['permission.smartMatchings.canDelete'],
          this.loggedInUserRoles,
          this.eventId,
        ) || !question.answers.some((answer) => answer.hasUserAnswers)
      );
    },
    getQuestions() {
      this[GET_MATCHING_QUESTIONS](this.eventId);
    },
  },
  mounted() {
    this.getQuestions();
  },
};
</script>

<style lang="scss" scoped>
@import '../../../styles/core/variables';
@import '../../../styles/core/mixins';

@include admin-layout();

#matching {
  padding: 50px;
  min-height: calc(100vh - 6rem);
}

.matching {
  &__title {
    margin-bottom: 10px;
  }

  &__actions {
    display: flex;
    align-items: center;

    & > *:not(:last-child) {
      margin-right: 10px;
    }
  }
}
.icon {
  color: var(--v-primary-base);

  &__restricted {
    color: var(--v-error-base);
  }
}
</style>
