<template>
  <v-sheet light>
    <v-row>
      <v-col cols="12">
        <div>{{ $t('kioskBuilder.edit.kiosk-builder.sidePanelPicture') }}</div>
        <v-text-field v-model="options.sidePanelPicture" v-show="false" :rules="[rules.url]" />

        <img
          class="mt-5"
          v-if="options.sidePanelPicture"
          :src="options.sidePanelPicture"
          alt="sidePanelPicture"
        />

        <div class="d-flex align-center">
          <v-btn
            color="primary"
            depressed
            :disabled="isUnityLoadingOrCantUpdate"
            @click="openPictureDialog('sidePanelPicture')"
            data-test-id="upload-side-panel"
          >
            {{ $t('globals.upload') }}
            <v-icon>mdi-upload</v-icon>
          </v-btn>

          <div class="mt-3 ml-5 d-flex flex-column picture-details">
            <span>{{ $t('kioskBuilder.edit.kiosk-builder.pictureFormat') }}</span>
            <span>{{ $t('kioskBuilder.edit.kiosk-builder.pictureSize') }} 355 x 820 px</span>
            <span> {{ $t('kioskBuilder.edit.kiosk-builder.pictureResolution') }}</span>
          </div>
        </div>
      </v-col>

      <v-col cols="12" class="mt-3">
        <div>{{ $t('kioskBuilder.edit.kiosk-builder.topPanelPicture') }}</div>
        <v-text-field v-model="options.topPanelPicture" v-show="false" :rules="[rules.url]" />

        <img
          class="mt-5"
          v-if="options.topPanelPicture"
          :src="options.topPanelPicture"
          alt="topPanelPicture"
        />

        <div class="d-flex align-center">
          <v-btn
            max-height="44px"
            color="primary"
            depressed
            :disabled="isUnityLoadingOrCantUpdate"
            @click="openPictureDialog('topPanelPicture')"
            data-test-id="upload-top-panel"
          >
            {{ $t('globals.upload') }}
            <v-icon>mdi-upload</v-icon>
          </v-btn>

          <div class="mt-3 ml-5 d-flex flex-column picture-details">
            <span>{{ $t('kioskBuilder.edit.kiosk-builder.pictureFormat') }}</span>
            <span>{{ $t('kioskBuilder.edit.kiosk-builder.pictureSize') }} 825 x 160 px</span>
            <span> {{ $t('kioskBuilder.edit.kiosk-builder.pictureResolution') }}</span>
          </div>
        </div>
      </v-col>

      <v-col cols="12" class="mt-3">
        <div>{{ $t('kioskBuilder.edit.kiosk-builder.deskPanelPicture') }}</div>
        <v-text-field v-model="options.deskPanelPicture" v-show="false" :rules="[rules.url]" />

        <img
          class="mt-5"
          v-if="options.deskPanelPicture"
          :src="options.deskPanelPicture"
          alt="deskPanelPicture"
        />

        <div class="d-flex align-center">
          <v-btn
            color="primary"
            depressed
            :disabled="isUnityLoadingOrCantUpdate"
            @click="openPictureDialog('deskPanelPicture')"
            data-test-id="upload-desk-panel"
          >
            {{ $t('globals.upload') }}
            <v-icon>mdi-upload</v-icon>
          </v-btn>

          <div class="mt-3 ml-5 d-flex flex-column picture-details">
            <span>{{ $t('kioskBuilder.edit.kiosk-builder.pictureFormat') }}</span>
            <span>{{ $t('kioskBuilder.edit.kiosk-builder.pictureSize') }} 860 x 360 px</span>
            <span> {{ $t('kioskBuilder.edit.kiosk-builder.pictureResolution') }}</span>
          </div>
        </div>
      </v-col>
    </v-row>

    <upload-pictures
      :dialog="pictureDialog"
      :is-multiple="false"
      :height="pictureDialogHeight"
      :width="pictureDialogWidth"
      @save="save"
      @close="closePictureDialog"
    />
  </v-sheet>
</template>

<script>
import UploadPictures from '@/components/admin/upload-pictures/UploadPictures.vue';
import VALIDATORS from '@/helpers/forms/validators.helper';

export default {
  name: 'KioskPanelsStep',
  components: {
    UploadPictures,
  },
  props: {
    kioskOptions: {
      type: Object,
      required: true,
    },
    isUnityLoading: {
      type: Boolean,
      required: true,
      default: true,
    },
  },
  data: () => ({
    rules: {
      url: VALIDATORS.URL.FORMAT,
    },
    pictureDialog: false,
    pictureDialogHeight: 0,
    pictureDialogWidth: 0,
    pictureDialogConfig: {
      sidePanelPicture: {
        height: 860,
        width: 360,
      },
      topPanelPicture: {
        height: 160,
        width: 825,
      },
      deskPanelPicture: {
        height: 355,
        width: 820,
      },
    },
    pictureDialogType: null,
  }),
  computed: {
    canUpdate() {
      return !this.kioskOptions?.isSubmitted;
    },
    isUnityLoadingOrCantUpdate() {
      return !this.canUpdate || this.isUnityLoading;
    },
    options: {
      get() {
        return this.kioskOptions;
      },
    },
  },
  methods: {
    openPictureDialog(pictureType) {
      this.pictureDialogHeight = this.pictureDialogConfig[pictureType].height;
      this.pictureDialogWidth = this.pictureDialogConfig[pictureType].width;
      this.pictureDialog = true;
      this.pictureDialogType = pictureType;
    },
    closePictureDialog() {
      this.pictureDialogType = null;
      this.pictureDialog = false;
    },
    save(picture) {
      this.$emit('upload-picture', {
        picture,
        pictureDialogType: this.pictureDialogType,
      });
      this.closePictureDialog();
    },
  },
};
</script>

<style lang="scss" scoped>
img {
  max-height: 200px;
  max-width: 80%;
  border: 1px solid var(--v-gray-base);
}

.picture-details {
  color: var(--v-gray-base);
}
</style>
