<template>
  <div>
    <v-data-table
      data-test-id="users-list"
      :headers="headers"
      :items="users"
      :footer-props="{
        itemsPerPageText: $t('globals.datatable.itemPerPage'),
        itemsPerPageAllText: $t('globals.datatable.itemPerPageAll'),
      }"
      :no-data-text="$t('globals.datatable.noData')"
      :no-results-text="$t('globals.datatable.noResult')"
    >
      <template v-slot:[`item.name`]="{ item }">
        {{ item.text }}
      </template>

      <template v-slot:[`item.isAdmin`]="{ item }">
        <v-simple-checkbox
          :value="item.isAdmin"
          @click="onChangeAdmins(item)"
          data-test-id="user-checkbox"
        />
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: 'GroupChatUsersList',
  props: {
    users: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('admin.groupChat.headers.name'),
          sortable: false,
          value: 'name',
        },
        {
          text: this.$t('admin.groupChat.headers.admin'),
          value: 'isAdmin',
          align: 'center',
          width: '150px',
        },
      ];
    },
  },
  methods: {
    onChangeAdmins(user) {
      this.$emit('change-role', user);
    },
  },
};
</script>
