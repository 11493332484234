<template>
  <div>
    <div class="external-links__label">
      {{ $t('event.edit.external-links.label') }}
    </div>

    <v-row
      class="d-flex"
      v-for="(link, idx) in form"
      :key="`externalLink-${idx}`"
      data-test-id="row-content"
    >
      <v-col cols="12" sm="6">
        <v-text-field
          v-model="link.name"
          :placeholder="$t('admin.externalLinks.name')"
          readonly
          disabled
        />
      </v-col>

      <v-col cols="12" sm="5">
        <v-text-field
          v-model="link.url"
          :placeholder="$t('admin.externalLinks.url')"
          readonly
          disabled
        />
      </v-col>

      <v-col cols="12" sm="1">
        <v-btn icon color="primary" @click="removeLine(idx)" data-test-id="delete">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row class="d-flex" data-test-id="row-add">
      <v-col cols="12" sm="6">
        <v-text-field v-model="name" :placeholder="$t('admin.externalLinks.name')" />
      </v-col>

      <v-col cols="12" sm="5">
        <v-text-field v-model="url" :placeholder="$t('admin.externalLinks.url')" />
      </v-col>

      <v-col cols="12" sm="1">
        <v-btn icon data-test-id="add" color="secondary" @click="addLine()" :disabled="isDisabled">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'ExternalLinks',
  props: {
    values: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      form: [],
      name: null,
      url: null,
    };
  },
  computed: {
    isDisabled() {
      return !this.name || !this.url;
    },
  },
  methods: {
    emitChanges() {
      this.$emit(
        'on-change',
        this.form
          .map((line) => ({ name: line?.name?.trim(), url: line?.url?.trim() }))
          .filter((a) => !!a),
      );
    },
    addLine() {
      if (!!this.name && !!this.url) {
        this.form.push({ name: this.name, url: this.url });
        this.name = null;
        this.url = null;
        this.emitChanges();
      }
    },
    removeLine(index) {
      this.form.splice(index, 1);
      this.emitChanges();
    },
  },
  mounted() {
    this.form = this.values || [];
  },
  watch: {
    values(value) {
      this.form = value || [];
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/core/variables';

.external-links {
  &__label {
    width: 100%;

    font-weight: $semi-bold;
  }
}
</style>
