var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"matching"}},[_c('h2',{staticClass:"matching__title"},[_vm._v(_vm._s(_vm.event.name)+" - "+_vm._s(_vm.$t('admin.matching.name')))]),_c('div',{staticClass:"matching__actions"},[_c('v-btn',{staticClass:"actions__create",attrs:{"large":"","depressed":"","color":"primary","disabled":!_vm.canCreate},on:{"click":function($event){return _vm.addQuestion()}}},[_vm._v(" "+_vm._s(_vm.$t('globals.add'))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-plus")])],1),_c('AppSearch',{staticClass:"actions__search",on:{"change":_vm.onSearchChange}})],1),_c('base-list',{attrs:{"backgroundColor":"var(--v-adminBackground-base)","loading":_vm.questionsAreLoading,"headers":_vm.headers,"headerProps":{ sortIcon: null },"search":_vm.search,"items":_vm.matchingQuestions,"itemsPerPage":10,"footerProps":{
      itemsPerPageText: _vm.$t('globals.datatable.itemPerPage'),
      itemsPerPageAllText: _vm.$t('globals.datatable.itemPerPageAll'),
      pageText: _vm.$t('globals.datatable.of'),
    },"noDataText":_vm.$t('globals.datatable.noData'),"noResultsText":_vm.$t('globals.datatable.noResult')},scopedSlots:_vm._u([{key:"item.priority",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.priority))])]}},{key:"item.exhibitor",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.questions.exhibitor))])]}},{key:"item.visitor",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.questions.visitor))])]}},{key:"item.answers",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.answers.length))])]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('admin-table-actions',{attrs:{"user":_vm.currentLoggedInUser,"canDelete":_vm.canDeletePartial,"is-disabled":!_vm.canDelete(item),"disabled-tooltip":_vm.$t('admin.matching.question.delete.hasUserAnswers'),"can-edit":_vm.canUpdate,"has-certificate":_vm.canCreate},on:{"edit-item":function($event){return _vm.editQuestion(item)},"generate-certificate":function($event){return _vm.openGenerateCertsDialog(item)}}})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }