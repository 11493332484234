<template>
  <div class="image-description-input-multiple">
    <div class="image-description-input-multiple__label">
      {{ $t('event.edit.lobby-images.label') }}
    </div>

    <div v-for="(item, index) in form" :key="index" class="d-flex mt-4 mb-4 mb-10">
      <div class="d-flex flex-column flex-lg-row image-description-input-multiple__description">
        <div class="d-flex flex-column align-start mr-4">
          <v-img
            class="image-description-input-multiple__image mb-4"
            max-height="150"
            max-width="150"
            contain
            :src="item.imageUrl"
          >
            <p v-if="!item.imageUrl">{{ $t('event.edit.noCover') }}</p>
          </v-img>

          <v-btn
            class="image-description-input-multiple__image-upload"
            color="primary"
            @click="update(index)"
            v-if="!disabled"
          >
            {{ $t('globals.upload') }}
            <v-icon>mdi-tray-arrow-down</v-icon>
          </v-btn>
        </div>

        <div class="image-description-input-multiple__editor">
          <vue-editor
            v-model="form[index].description"
            :editorToolbar="customToolbar"
            :disabled="disabled"
            :placeholder="$t('event.edit.lobby-images.description')"
          >
          </vue-editor>
          <span class="error--text" v-if="hasMessageError(form[index].description, index)">
            {{ getMessageError(form[index].description) }}
          </span>
        </div>
      </div>
      <v-btn :disabled="disabled" icon @click.native="removeLine(index)">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>

    <v-btn :disabled="disabled" icon color="primary" @click.native="addLine()">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { VueEditor } from 'vue2-editor';

const MAX_DESCRIPTION_LENGTH = 100;

export default {
  name: 'ImageDescriptionInputMultiple',
  components: {
    VueEditor,
  },
  props: {
    values: {
      type: Array,
      required: false,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      form: this.values || [],
      isValid: this.isValidLobbyImages,
      validForm: this.values.map(() => false) || [],
      customToolbar: [
        [{ font: [] }],
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ['bold', 'italic', 'underline', 'strike'],
        ['link'],
        [{ color: [] }, { background: [] }],
        [{ size: ['small', false, 'large', 'huge'] }],
      ],
    };
  },
  computed: {},
  methods: {
    getTextLength(str) {
      return str?.replace(/<\/?[^>]+(>|$)/g, '').length;
    },
    hasMessageError(str, index) {
      const error = this.getTextLength(str) > MAX_DESCRIPTION_LENGTH;
      this.setValidForm(index, error);
      return error;
    },
    setValidForm(index, hasError) {
      this.validForm[index] = hasError;

      this.$emit('set-is-valid-lobby-images', !this.validForm.some((v) => v));
    },
    getMessageError() {
      return this.$t('event.edit.lobby-images.description-max-length', {
        count: MAX_DESCRIPTION_LENGTH,
      }).toString();
    },
    update(index) {
      this.$emit('update', index);
    },
    addLine() {
      this.$emit('add');
    },
    removeLine(index) {
      this.form.splice(index, 1);
    },
  },
  watch: {
    values() {
      this.form = this.values;
    },
    isValidLobbyImages() {
      this.isValid = this.isValidLobbyImages;
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/core/variables';

.image-description-input-multiple {
  &__label {
    width: 100%;

    font-weight: $semi-bold;
  }

  &__image-container {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
  }

  &__description {
    width: 100%;
  }

  &__image {
    border: 1px solid grey;
  }

  &__image-upload {
    width: 100%;
  }

  &__input {
    width: 100%;
  }

  &__editor {
    width: 100%;
    margin-right: 10px;
    min-height: auto;

    word-break: break-word;
  }
}
</style>
